import * as Sentry from "@sentry/react";

if (["production", "pre-production"].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT || "local")) {
  Sentry.init({
    dsn: "https://28869853dae232b6e96ebe65436dfe7f@o1076203.ingest.sentry.io/4506344113045504",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracePropagationTargets: ["localhost", /\.datascientest\.com\//],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
