import React from "react";
import { Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useSession } from "../../contexts/SessionContext";
import { ProjectBlockProps } from "../../models/Project";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../services/UtilityService";
import ExternalLink from "../DstIcons/ExternalLink";

const ProjectBlock = ({
  id,
  type,
  title,
  description,
  facilitator,
  startDate,
  endDate,
  githubLink,
  mock = false,
}: ProjectBlockProps) => {
  const { language } = useSession();
  const navigate = useNavigate();

  if (mock)
    return (
      <Placeholder animation="glow">
        <div className="d-flex">
          <div className="col-xl-4 col-md-6 col-sm-12">
            <div className="mb-4 shadow-sm p-3 d-flex flex-column justify-content-between w-100">
              <div>
                <Placeholder as="div" xs={5} />
                <Placeholder as="h3" xs={12} />
              </div>
              <Placeholder as="div" xs={12} />
              <Placeholder as="p" xs={12} />
              <Placeholder as="p" xs={5} />
              <Placeholder as="p" xs={5} />
            </div>
          </div>
        </div>
      </Placeholder>
    );

  return (
    <div
      className="mb-4 shadow-sm p-3 beige-background d-flex flex-column justify-content-between w-100 clickable"
      onClick={(event: any) => completeNavigate(event, navigate, `${getLegacyAdminHostname()}/group_projects/${id}`)}
    >
      <div>
        <div className="smoke-gray-font s-regular">{type}</div>
        <h3 className="charcoal-gray-font">{title}</h3>
      </div>
      <p className="dark-gray-font xs-regular">{description}</p>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="yellow-font s-regular mb-0">
            {translate(language, "COMPONENTS.PROJECT_BLOCK.FACILITATED").replace("{{overseer}}", `${facilitator}`)}
          </p>
          <p className="charcoal-gray-font s-regular">
            {formatDateHumanly(language, startDate)} - {formatDateHumanly(language, endDate)}
          </p>
        </div>
        {githubLink && (
          <a href={githubLink} target="_blank" rel="noopener noreferrer">
            <ExternalLink />
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectBlock;
