const Edit = ({ color = "var(--color-blue)", classes = "me-2" }: { color?: string; classes?: string }) => {
  return (
    <svg className={classes} height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1439_3453)">
        <path
          d="M7.7915 2.8335H2.83317C2.45745 2.8335 2.09711 2.98275 1.83144 3.24843C1.56576 3.5141 1.4165 3.87444 1.4165 4.25016V14.1668C1.4165 14.5426 1.56576 14.9029 1.83144 15.1686C2.09711 15.4342 2.45745 15.5835 2.83317 15.5835H12.7498C13.1256 15.5835 13.4859 15.4342 13.7516 15.1686C14.0172 14.9029 14.1665 14.5426 14.1665 14.1668V9.2085"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.104 1.77067C13.3858 1.48888 13.768 1.33057 14.1665 1.33057C14.565 1.33057 14.9472 1.48888 15.229 1.77067C15.5108 2.05246 15.6691 2.43465 15.6691 2.83317C15.6691 3.23168 15.5108 3.61388 15.229 3.89567L8.49984 10.6248L5.6665 11.3332L6.37484 8.49984L13.104 1.77067Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Edit;
