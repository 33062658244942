import React, { useEffect, useState } from "react";
import { Form, InputGroup, Placeholder, Spinner } from "react-bootstrap";

import { ListerSearchProps } from "./ListerSearch.d";

/**
 * `ListerSearch` is designed to seamlessly integrate with the Lister component, providing a versatile and responsive search solution for any page.
 * It features an input field that captures and updates the search query in real-time, making it a strong and dynamic tool for filtering lists.
 * When in mock mode, it displays a placeholder animation instead of the search input.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.searchQuery - The current search query string.
 * @param {Function} props.setSearchQuery - Function to update the search query state.
 * @param {boolean} props.mock - Determines if the component should display the placeholder animation (true) or the actual search input (false).
 *
 * @example
 * // Simple example:
 * const filteredExample = useMemo(
 *   () => DATA.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchQuery.toLowerCase())),
 *   [searchQuery]
 * );
 * return (
 *   <>
 *     <ListerSearch setSearchQuery={setSearchQuery} />
 *     <Lister items={filteredExample} columns={COLUMNS} />
 *   </>
 * );
 *
 * @example
 * // Usage example:
 *  import React, { useState } from "react";
 *  import Lister from "../../components/Lister/Lister";
 *  import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
 *
 *  const mockData = [
 *    { id: 1, first_name: "John", last_name: "Doe", email: "john.doe@example.com" },
 *    { id: 2, first_name: "Jane", last_name: "Doe", email: "jane.doe@example.com" },
 *    { id: 3, first_name: "Jim", last_name: "Beam", email: "jim.beam@example.com" },
 *    { id: 4, first_name: "Jack", last_name: "Daniels", email: "jack.daniels@example.com" },
 *  ];
 *
 *  const columns = [
 *    { header: "ID", key: "id" },
 *    { header: "First Name", key: "first_name" },
 *    { header: "Last Name", key: "last_name" },
 *    { header: "Email", key: "email" },
 *  ];
 *
 *  const Example = () => {
 *    const [searchQuery, setSearchQuery] = useState("");
 *    const [loading, setLoading] = useState(false);
 *
 *    const filteredExample = useMemo(
 *      () => mockData.filter((user) => Object.values(user).join(" ").toLowerCase().includes(searchQuery.toLowerCase())),
 *      [searchQuery]
 *    );
 *
 *    return (
 *      <div className="m-5">
 *        <ListerSearch setSearchQuery={setSearchQuery} mock={loading} />
 *        <Lister
 *          items={filteredExample}
 *          columns={columns}
 *          additionalButton={(item) => (
 *            <span className="clickable xs-regular" onClick={() => alert(`User ID: ${item.id}`)}>
 *              <i className="dst-icon-arrow-narrow-right" />
 *            </span>
 *          )}
 *          showHeader
 *          pagination={true}
 *          mock={loading}
 *          itemsPerPage={10}
 *        />
 *      </div>
 *    );
 *  };
 *
 *  export default Example;
 */

const ListerSearch = ({ setSearchQuery, mock, showSearchIcon, timeDelay }: ListerSearchProps) => {
  const [localInput, setLocalInput] = useState("");
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (timeDelay) {
      setSearching(true);
      const delayDebounceFn = setTimeout(() => {
        setSearchQuery(localInput);
        setSearching(false);
      }, timeDelay);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchQuery(localInput);
    }
  }, [localInput, setSearchQuery, timeDelay]);

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <InputGroup>
        {mock ? (
          <Placeholder animation="glow">
            <Placeholder.Button style={{ width: "200px", height: "38px" }} />
          </Placeholder>
        ) : (
          <React.Fragment>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={localInput}
              onChange={(e) => setLocalInput(e.target.value)}
            />
            {showSearchIcon && (
              <InputGroup.Text>
                {searching ? <Spinner size="sm" animation="grow" /> : <i className="dst-icon-search-sm" />}
              </InputGroup.Text>
            )}
          </React.Fragment>
        )}
      </InputGroup>
    </Form>
  );
};

export default ListerSearch;
