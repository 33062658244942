import { Note } from "../../../../models/Note";
import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const createNote = (
  userId: number,
  authorEmail: string,
  language: string,
  setUserData: Function,
  setActiveNote: Function,
  setIsEditing: Function
) => {
  ApiService.post(`users/${userId}/notes`, {
    author_email: authorEmail,
    value: "",
  })
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      setActiveNote(resp.data.notes?.sort((a: Note, b: Note) => b.dt_created - a.dt_created)?.[0]);
      setIsEditing(true);
    })
    .catch((error) => {
      if (error.status === 400) {
        alert(translate(language, "COMPONENTS.USER.ERROR_NOTE_TOO_BIG"));
        console.error(error);
      } else {
        alert(translate(language, "COMPONENTS.USER.ERROR"));
        console.error(error);
      }
    });
};

export const deleteNote = (userId: number, noteId: string, setUserData: Function, callback?: Function) => {
  ApiService.delete(`users/${userId}/notes/${noteId}`)
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback();
    })
    .catch((err) => {
      alert("An error occurred on deleting note, more details in console. Contact Developer Team");
      console.error(err);
    });
};

export const updateNote = (
  userId: number,
  authorEmail: string,
  language: string,
  note: Note,
  setUserData: Function,
  callback?: Function
) => {
  const body = {
    author_email: authorEmail,
  } as any;

  if (note.value) {
    body.value = note.value;
  }
  if (note.title) {
    body.title = note.title;
  }

  ApiService.put(`users/${userId}/notes/${note.id}`, body)
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback(resp.data.notes.find((new_note: Note) => new_note.id === note.id));
    })
    .catch((error) => {
      if (error.status === 400) {
        alert(translate(language, "COMPONENTS.USER.ERROR_NOTE_TOO_BIG"));
        console.error(error);
      } else {
        alert(translate(language, "COMPONENTS.USER.ERROR"));
        console.error(error);
      }
    });
};

export const togglePinNote = (userId: number, note: Note, setUserData: Function, callback?: Function) => {
  (!note.is_pinned
    ? ApiService.delete(`users/${userId}/notes/${note.id}/pin`)
    : ApiService.post(`users/${userId}/notes/${note.id}/pin`, {})
  )
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback(resp.data.notes.find((new_note: Note) => new_note.id === note.id));
    })
    .catch((err) => {
      alert("An error occurred on pinning/unpinning note, more details in console. Contact Developer Team");
      console.error(err);
    });
};
