import { useState } from "react";
import React from "react";
import { Dropdown, Form, Placeholder, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import DstButton from "../DstButton/DstButton";
import CheckIcon from "../DstIcons/CheckIcon";
import CrossIcon from "../DstIcons/CrossIcon";
import ThreeDots from "../DstIcons/ThreeDots";
import Popup from "../Popup/Popup";
import { openCertifInNewTab, regenerateDocument } from "./CertifBlock.function";
import "./CertifBlock.scss";

const CertifBlock = ({
  attempts = 0,
  backgroundColor = "var(--color-white)",
  borderStyle = "1px solid light-gray-background",
  certifDate,
  documentId,
  filename,
  mainText,
  mock,
  refreshUserCertifications,
  remarkTextColor = "rgba(var(--color-black-rgba), 0.4)",
  remarkMainText = "Time spent",
  remarkText,
  textColor = "var(--color-black)",
  titleColor = "rgba(var(--color-black-rgba), 0.4)",
  topLeftTitle,
  userFullName,
}: {
  attempts?: number;
  backgroundColor?: string;
  borderStyle?: string;
  certifDate?: string;
  documentId?: number;
  filename?: string;
  mainText?: string;
  mock?: boolean;
  refreshUserCertifications?: Function;
  remarkTextColor?: string;
  remarkMainText?: string;
  remarkText?: string;
  textColor?: string;
  titleColor?: string;
  topLeftTitle?: string;
  userFullName?: string;
}) => {
  const { language } = useSession();
  const [regenerating, setRegenerating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [certificationUserFullName, setCertificationUserFullName] = useState(userFullName);
  const [certificationTitle, setCertificationTitle] = useState(mainText);

  return mock ? (
    <Placeholder animation="glow">
      <Card className="certification-card my-0">
        <Card.Body className="p-2">
          <Card.Title className="my-0 align-items-center" as="section">
            <Placeholder xs={3} />
            <ThreeDots classes="mt-2 d-inline-block float-end" height="0.2em" color={titleColor} />
          </Card.Title>
          <Card.Text className="s-regular position-relative w-100" style={{ color: textColor }} as="section">
            <Placeholder xs={12} />
            <Placeholder xs={12} />
            <Placeholder className="xs-bold" xs={12} />
          </Card.Text>
        </Card.Body>
      </Card>
    </Placeholder>
  ) : (
    <React.Fragment>
      <Card className="certification-card my-0" style={{ backgroundColor, border: borderStyle }}>
        <Card.Body className="p-2">
          <Card.Title className="xs-regular my-0 align-items-center" style={{ color: titleColor }} as="section">
            <div className="d-flex justify-content-between">
              <p className="xs-regular lh-1 my-1">{topLeftTitle}</p>
              <Dropdown>
                <Dropdown.Toggle as="div" variant="secondary" className="clickable">
                  <ThreeDots classes="" height="0.3em" color={titleColor} orientation="vertical" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="s-regular">
                  {filename && (
                    <Dropdown.Item onClick={() => openCertifInNewTab(filename)}>
                      {translate(language, "COMPONENTS.CERTIF_BLOCK.ACTIONS.DISPLAY")}{" "}
                      <i className="dst-icon-link-external-02"></i>
                    </Dropdown.Item>
                  )}
                  {documentId && (
                    <React.Fragment>
                      <Dropdown.Item onClick={() => regenerateDocument(language, documentId, setRegenerating)}>
                        {translate(language, "COMPONENTS.CERTIF_BLOCK.ACTIONS.REGENERATE")}{" "}
                        <i className="dst-icon-refresh-cw-02"></i>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => setShowPopup(true)}>
                        {translate(language, "COMPONENTS.CERTIF_BLOCK.ACTIONS.EDIT")}{" "}
                        <i className="dst-icon-edit-04"></i>
                      </Dropdown.Item>
                    </React.Fragment>
                  )}
                  {!(filename || documentId) && (
                    <Dropdown.ItemText className="text-nowrap">
                      {translate(language, "COMPONENTS.CERTIF_BLOCK.ACTIONS.NO_ACTION")}
                    </Dropdown.ItemText>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Title>
          <Card.Text className="s-regular" style={{ color: textColor }} as="section">
            <p className="mb-0 scrollable max-h-4em">{mainText}</p>
            <p className="mb-0 xs-regular charcoal-gray-font">
              {certifDate && formatDateHumanly(language, certifDate)}
            </p>
            {remarkMainText && remarkText && (
              <p className="d-inline xs-regular" style={{ color: remarkTextColor }}>
                {remarkMainText}: <span className="xs-bold">{remarkText}</span>
              </p>
            )}
          </Card.Text>
        </Card.Body>
        {attempts > 0 && (
          <Card.Footer className="align-items-center d-flex xs-regular bg-white my-0 mx-1 py-0 px-2">
            <span>{translate(language, "PAGES.USER.CERTIFICATION.ATTEMPTS")}:</span>
            <span>
              {[...Array(attempts - 1)].map((_, index) => (
                <CrossIcon key={index} classes="ms-1" />
              ))}
              <CheckIcon classes="ms-1" />
            </span>
          </Card.Footer>
        )}
        {regenerating && (
          <div className="regenerating-overlay w-100 h-100 position-absolute d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">{translate(language, "COMPONENTS.CERTIF_BLOCK.LOADING")}</span>
            </Spinner>
          </div>
        )}
      </Card>
      <Popup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        modalTitle={translate(language, "COMPONENTS.CERTIF_BLOCK.POPUP.TITLE")}
        scrollable={true}
      >
        <div className="m-regular">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              regenerateDocument(
                language,
                documentId!,
                setRegenerating,
                certificationUserFullName,
                certificationTitle,
                () => {
                  setShowPopup(false);
                  refreshUserCertifications?.();
                }
              );
            }}
          >
            <Form.Group className="mb-2">
              <Form.Label>
                {translate(language, "COMPONENTS.CERTIF_BLOCK.POPUP.CERTIFICATION_USER_FULLNAME_LABEL")}
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={certificationUserFullName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCertificationUserFullName(event.target.value)
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>{translate(language, "COMPONENTS.CERTIF_BLOCK.POPUP.CERTIFICATION_TITLE_LABEL")}</Form.Label>
              <Form.Control
                type="text"
                defaultValue={certificationTitle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCertificationTitle(event.target.value)}
              />
            </Form.Group>
            <DstButton
              type="submit"
              value={translate(language, "COMPONENTS.CERTIF_BLOCK.POPUP.SUBMIT")}
              btnClass="mt-3 mt-md-5 mx-auto d-block"
            />
          </Form>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default CertifBlock;
