import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const openCertifInNewTab = (filename: string) => {
  window.open(`${process.env.REACT_APP_FILE_URL}/certification/${filename}`, "_blank");
};

export const regenerateDocument = (
  language: string,
  documentId: number,
  setRegenerating: Function,
  userFullName?: string,
  certificationTitle?: string,
  callback?: Function
) => {
  setRegenerating(true);
  let payload: any = {};

  if (userFullName) {
    payload.full_name = userFullName;
  }
  if (certificationTitle) {
    payload.certification_name = certificationTitle;
  }
  ApiService.post(`documents/${documentId}/regenerate`, payload)
    .then((_) => {
      alert(translate(language, "COMPONENTS.CERTIF_BLOCK.REGENERATION_SUCCEED"));
      callback?.();
    })
    .catch((err) => {
      console.error("An error occurred during document regeneration", err);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => setRegenerating(false));
};
