import { Col, Dropdown, DropdownButton, Placeholder, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { isPast } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../services/UtilityService";
import DstButton from "../DstButton/DstButton";
import Clock from "../DstIcons/Clock";
import { getAttendanceIconClass, getFontClass, openRecordLink, renderAttendance } from "./MeetingBlock.function";
import "./MeetingBlock.scss";

const MeetingBlock = ({
  start_time,
  end_time,
  name,
  meeting_type,
  mock,
  language,
  link,
  record_link,
  attended,
  meeting_id,
}: any) => {
  const navigate = useNavigate();

  if (mock)
    return (
      <Placeholder
        animation="glow"
        className="row ms-0 meeting-block d-flex border mb-3 px-1 py-2 p-xl-3 rounded align-items-center"
      >
        <Col xl={1} lg={2} md={6} className="border-end text-center">
          <Placeholder xs={12} as="div" className="meeting-month s-regular"></Placeholder>
          <Placeholder xs={12} as="div" className="meeting-day h1"></Placeholder>
        </Col>
        <Col lg={3} md={6}>
          <Placeholder xs={12} as="div" className="meeting-time m-bold"></Placeholder>
          <Placeholder xs={12} as="div" className="meeting-attendees"></Placeholder>
        </Col>
        <Col xl={6} lg={5} md={9}>
          <Placeholder xs={12} as="div" className="meeting-type smoke-gray-font s-bold"></Placeholder>
          <Placeholder xs={12} as="div" className="meeting-title m-regular"></Placeholder>
        </Col>
        <Col lg={2} md={3} className="text-end">
          <DstButton mock btnClass="my-2" btnWidth="71px" />
        </Col>
      </Placeholder>
    );
  const isMeetingPast = isPast(end_time);

  let monthParams: Intl.DateTimeFormatOptions = { month: "long" };
  if (new Date().getFullYear() !== start_time.getFullYear()) {
    monthParams["year"] = "numeric";
  }

  return (
    <Row className="ms-0 meeting-block d-flex border mb-3 px-1 py-2 p-xl-3 rounded align-items-center w-100">
      <Col xl={1} lg={2} md={6} className={`border-end text-center ${getFontClass(isMeetingPast, attended)}`}>
        <div className="meeting-month text-capitalize s-regular">
          {start_time.toLocaleString(language, monthParams)}
        </div>
        <div className="meeting-day h1">{start_time.getDate()}</div>
      </Col>
      <Col lg={3} md={6}>
        <div className="meeting-time m-bold">
          <Clock classes="me-1" color="var(--color-charcoal-gray)" />
          {`${start_time.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })} - ${end_time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}
        </div>
        {isMeetingPast && (
          <div className="meeting-attendance">
            <div className="d-flex mt-1 align-items-center">
              <i className={getAttendanceIconClass(attended)} />
              <span className="ms-1 s-bold">{renderAttendance(isMeetingPast, attended, language)}</span>
            </div>
          </div>
        )}
      </Col>
      <Col xl={6} lg={5} md={9}>
        <div className="meeting-type smoke-gray-font s-bold">{meeting_type}:</div>
        <div className="meeting-title m-regular">{name}</div>
      </Col>
      <Col lg={2} md={3} className="text-end">
        <DropdownButton
          className="my-2 dropdown-meeting"
          title={translate(language, "PAGES.USER.MEETING.MORE")}
          variant="primary"
        >
          {!isMeetingPast && link && (
            <Dropdown.Item className="s-bold" onClick={() => navigator.clipboard.writeText(link)}>
              {translate(language, "PAGES.USER.MEETING.BUTTON.COPY_LINK")}
            </Dropdown.Item>
          )}
          {isMeetingPast && record_link && (
            <Dropdown.Item className="s-bold" onClick={() => openRecordLink(record_link, language)}>
              {translate(language, "PAGES.USER.MEETING.BUTTON.VIEW_RECORD")}
            </Dropdown.Item>
          )}
          {((!isMeetingPast && !link) || (isMeetingPast && !record_link)) && (
            <Dropdown.Item disabled className="text-muted s-bold">
              {translate(language, "PAGES.USER.MEETING.BUTTON.NO_LINK")}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className="s-bold d-flex align-items-center"
            onClick={(event) => completeNavigate(event, navigate, `${getLegacyAdminHostname()}/meetings/${meeting_id}`)}
          >
            <span className="me-1">{translate(language, "PAGES.USER.MEETING.BUTTON.GO_TO_MEETING")}</span>
            <i className="dst-icon-link-external-02"></i>
          </Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
};

export default MeetingBlock;
