import * as Sentry from "@sentry/react";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import Content from "../components/Content/Content";
import DstHeader from "../components/DstHeader/DstHeader";
import NavbarLeft from "../components/NavbarLeft/NavbarLeft";
import { useSession } from "../contexts/SessionContext";
import provideLeftNavbarItems from "./NavbarService";

export const ProtectedRoute = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { logged, email, language } = useSession();
  const location = useLocation();

  // User is not authenticated
  if (!logged) {
    return <Navigate to={`/login?next=${location.pathname}`} replace />;
  }

  Sentry.setUser({
    email: email,
  });

  return (
    <React.Fragment>
      <DstHeader language={language} />
      <NavbarLeft menu={provideLeftNavbarItems(location.pathname)} />
      <Content>{children}</Content>
    </React.Fragment>
  );
};

export const AuthenticatedComponent = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { logged, email } = useSession();

  // User is not authenticated
  if (!logged) {
    return <React.Fragment></React.Fragment>;
  }

  Sentry.setUser({
    email: email,
  });

  return <React.Fragment>{children}</React.Fragment>;
};
