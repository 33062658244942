import data from "./languages";

const _fetchWord = (obj: any, key: any): any => {
  if (!obj?.[key[0]]) return "";
  if (key.length === 1) return obj[key[0]];
  return _fetchWord(obj[key[0]], key.slice(1));
};

const translate = (language: string, key: string): string => {
  if (key.includes(".")) {
    const path = key.split(".");
    return _fetchWord((data as any)[language], path) || _fetchWord((data as any)["en-US"], path);
  }
  return (data as any)[language][key] || (data as any)["en-US"][key];
};

export const translateDate = (language: string, date: string) => {
  const tmp = new Date(date);

  return tmp.toLocaleDateString(language);
};

export default translate;
