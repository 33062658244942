import ApiService from "../../../../services/ApiService";

export const normalizeString = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase()
    .replace(/[-:]+/g, "-");

export const scrollToAnchor = (anchorId: string) =>
  document.getElementById(anchorId)?.scrollIntoView({ behavior: "smooth" });

export const fetchModules = (setModules: Function) => {
  ApiService.get("modules?get_deprecated=false")
    .then((response) => {
      setModules(response.data.return.modules);
    })
    .catch((err) => {
      console.error("An error occurred while fetching modules", err);
    });
};

export const updateUserModules = (userId: number, moduleIds: any[], callback: Function) => {
  ApiService.put(`users/${userId}/modules`, { module_ids: moduleIds.map((module) => module.id) })
    .then((_response) => {
      alert("Module updated successfully");
      callback();
    })
    .catch((error) => {
      console.error("Error updating module", error);
    });
};
