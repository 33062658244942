import { GraphProps } from "./DstStatGraph.d";

const DEFAULT_COLORS = ["var(--color-blue)", "#dba02a", "#a428dd", "#56ceb2", "#b59885"];

export const prepareChartData = (data: any[]): GraphProps[] => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  return data.map((item, index) => ({
    key: item.label,
    fill: item.color || DEFAULT_COLORS[index % DEFAULT_COLORS.length],
    label: item.label,
    percentage: (item.value / totalValue) * 100,
  }));
};
