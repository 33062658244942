const Pin = ({
  color = "var(--color-gray)",
  bgColor,
  classes = "me-2",
}: {
  color?: string;
  bgColor?: string;
  classes?: string;
}) => (
  <svg className={classes} height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 12.0415V15.5832" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.54175 12.0415H13.4584V10.7948C13.4583 10.5313 13.3846 10.273 13.2457 10.049C13.1068 9.82501 12.9082 9.6442 12.6722 9.52692L11.4113 8.88942C11.1753 8.77214 10.9767 8.59134 10.8378 8.36735C10.6989 8.14336 10.6252 7.88506 10.6251 7.6215V4.24984H11.3334C11.7091 4.24984 12.0695 4.10058 12.3351 3.83491C12.6008 3.56923 12.7501 3.20889 12.7501 2.83317C12.7501 2.45745 12.6008 2.09711 12.3351 1.83144C12.0695 1.56576 11.7091 1.4165 11.3334 1.4165H5.66675C5.29102 1.4165 4.93069 1.56576 4.66501 1.83144C4.39934 2.09711 4.25008 2.45745 4.25008 2.83317C4.25008 3.20889 4.39934 3.56923 4.66501 3.83491C4.93069 4.10058 5.29102 4.24984 5.66675 4.24984H6.37508V7.6215C6.37494 7.88506 6.30128 8.14336 6.16238 8.36735C6.02349 8.59134 5.82486 8.77214 5.58883 8.88942L4.328 9.52692C4.09197 9.6442 3.89334 9.82501 3.75445 10.049C3.61555 10.273 3.54189 10.5313 3.54175 10.7948V12.0415Z"
      fill={bgColor}
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pin;
