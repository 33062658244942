import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Search from "../../../../components/DstIcons/Search";
import Lister from "../../../../components/Lister/Lister";
import Popup from "../../../../components/Popup/Popup";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { openEmail } from "./UserEmails.function";

const UserEmails = ({
  user_data,
  language,
  loaded,
  showEmailModal,
  setShowEmailModal,
  emailModalContent,
  setEmailModalContent,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
  showEmailModal: boolean;
  setShowEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  emailModalContent: string;
  setEmailModalContent: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const ref = React.useRef<any>();
  const [height, setHeight] = React.useState("0px");
  const _onLoad = () => {
    setHeight(`${ref.current.contentWindow.document.body.scrollHeight + 20}px`);
  };

  return loaded ? (
    <div className="w-100">
      <Lister
        items={user_data.user_emails.map((user_email: any) => ({
          ...user_email,
          recipients: user_email.recipients.filter((recipient: string) => recipient !== user_data.email).join(", "),
          date: user_email.date * 1000,
          status: user_email.is_read ? "read" : "not read",
        }))}
        columns={[
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.RECIPIENT"), key: "recipients" },
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.SUBJECT"), key: "subject" },
          {
            header: translate(language, "PAGES.USER.EMAIL_TABLE.DATE"),
            key: "date",
            render: (item: any) => (
              <div>
                <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
                  <span>{formatDateHumanly(language, item["date"])}</span>
                </OverlayTrigger>
              </div>
            ),
          },
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.ATTACHMENT"), key: "attachment" },
          {
            header: translate(language, "PAGES.USER.EMAIL_TABLE.STATUS"),
            key: "status",
            render: (item: any) => (
              <span className={`p-1 rounded ${item.status === "read" ? "status-green" : "status-red"}`}>
                {translate(language, `PAGES.USER.EMAIL_TABLE.${item.status === "read" ? "" : "NOT_"}READ`)}
              </span>
            ),
          },
        ]}
        additionalButton={(item: any) => (
          <span className="clickable" onClick={() => openEmail(item, setShowEmailModal, setEmailModalContent)}>
            <Search classes="search-icon" />
          </span>
        )}
        mock={!loaded}
      />
      <Popup
        show={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        modalTitle="Email content"
        size="xl"
        fullscreen="lg-down"
        scrollable={true}
      >
        <iframe
          id="email-shower"
          title="email-shower"
          ref={ref}
          onLoad={_onLoad}
          srcDoc={emailModalContent}
          width="100%"
          height={height}
          style={{
            width: "100%",
            overflow: "auto",
          }}
        ></iframe>
      </Popup>
    </div>
  ) : (
    <Lister
      columns={[
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.RECIPIENT"), key: "recipients" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.SUBJECT"), key: "subject" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.DATE"), key: "date" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.ATTACHMENT"), key: "attachment" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.STATUS"), key: "status" },
      ]}
      mock={true}
    />
  );
};

export default UserEmails;
