const Key = ({ color = "var(--color-blue)", classes = "me-2" }: { color?: string; classes?: string }) => (
  <svg className={classes} height="1em" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1439_2789)">
      <path
        d="M1.81543 9.74988V11.3749C1.81543 11.6999 2.0321 11.9166 2.3571 11.9166H4.52376V10.2915H6.14876V8.66655H7.2321L7.99043 7.90822C8.74326 8.17046 9.5628 8.16946 10.315 7.90537C11.0672 7.64129 11.7075 7.12975 12.1311 6.45445C12.5548 5.77915 12.7367 4.98006 12.6472 4.18791C12.5577 3.39576 12.2019 2.65744 11.6382 2.09373C11.0745 1.53003 10.3362 1.17432 9.54407 1.08478C8.75192 0.995247 7.95283 1.17719 7.27753 1.60086C6.60223 2.02452 6.09069 2.66481 5.82661 3.417C5.56252 4.16918 5.56152 4.98872 5.82376 5.74155L1.81543 9.74988Z"
        stroke={color}
        strokeWidth="1.14706"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.67025 4.33366C9.81982 4.33366 9.94108 4.2124 9.94108 4.06283C9.94108 3.91325 9.81982 3.79199 9.67025 3.79199C9.52067 3.79199 9.39941 3.91325 9.39941 4.06283C9.39941 4.2124 9.52067 4.33366 9.67025 4.33366Z"
        stroke={color}
        strokeWidth="1.14706"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Key;
