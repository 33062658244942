import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const fetchNote = (cohortId: string, setNotes: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/notes`)
    .then((resp) => {
      const notes = resp.data.notes || [];
      setNotes(notes);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      setNotes([]);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.FETCH"));
    });
};

export const createNote = (
  cohortId: string,
  authorEmail: string,
  value: string,
  isPinned: boolean,
  language: string,
  callback?: Function
) => {
  ApiService.post(`cohorts/${cohortId}/notes`, {
    author_email: authorEmail,
    value: value,
    is_pinned: isPinned,
  })
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.CREATE"));
    });
};

export const deleteNote = (cohortId: string, noteId: string, language: string, callback?: Function) => {
  ApiService.delete(`cohorts/${cohortId}/notes/${noteId}`, {})
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.DELETE"));
    });
};

export const updateNote = (
  cohortId: string,
  current_user: string,
  noteId: string,
  value: string,
  language: string,
  callback?: Function
) => {
  ApiService.put(`cohorts/${cohortId}/notes/${noteId}`, {
    author_email: current_user,
    value: value,
    is_pinned: false,
  })
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.UPDATE"));
    });
};

export const pinNote = (cohortId: string, noteId: string, language: string, callback?: Function) => {
  ApiService.post(`cohorts/${cohortId}/notes/${noteId}/pin`, {})
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.PIN"));
    });
};

export const unpinNote = (cohortId: string, noteId: string, language: string, callback?: Function) => {
  ApiService.delete(`cohorts/${cohortId}/notes/${noteId}/pin`, {})
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.NOTES.ERROR.UNPIN"));
    });
};
