const Plus = ({
  color = "var(--color-light-lavender)",
  bgColor,
  classes = "me-2",
}: {
  color?: string;
  bgColor?: string;
  classes?: string;
}) => {
  const fill = bgColor ? bgColor : "transparent";

  return (
    <svg className={classes} height="1em" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="11" height="11" rx="5.5" fill={fill} />
      <path d="M5.5 2.7915V9.20817" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.2915 6H8.70817" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Plus;
