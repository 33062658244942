import { useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";

const NotFound = () => {
  const navigate = useNavigate();
  const { language } = useSession();

  return (
    <div className="p-3 p-md-5">
      <h1>{translate(language, "PAGES.NOT_FOUND.TITLE")}</h1>
      <p>{translate(language, "PAGES.NOT_FOUND.CONTENT")}</p>
      <DstButton
        variant="secondary"
        value={translate(language, "PAGES.NOT_FOUND.BUTTON_TITLE")}
        clickFunction={() => navigate("/")}
      />
    </div>
  );
};

export default NotFound;
