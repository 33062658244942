import React, { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useSession } from "../../contexts/SessionContext";
import languages from "../../services/languages/index";
import { LanguageSelectorProps } from "./LanguageSelector.d";
import "./LanguageSelector.scss";

const LanguageSelector = ({ className = "", logoStyle = "", fontColor = "gray-font" }: LanguageSelectorProps) => {
  const { language, switchLanguage } = useSession();
  const [open, setOpen] = useState(false);

  const languagesArray = Object.keys(languages).map((key) => ({
    code: key,
    ...(languages as { [key: string]: any })[key],
  }));

  const currentLanguageDetails = languagesArray.find((lang) => lang.code === language);

  return (
    <NavDropdown
      className={`${className}`}
      title={
        <div className={`d-flex align-items-center ${fontColor}`}>
          <span className={`dst-icon-translate-01 ${logoStyle}`}></span>
          <span className={`dropdown-toggle-icon ${open ? "dst-icon-chevron-up" : "dst-icon-chevron-down"}`}></span>
        </div>
      }
      id="language-selector"
      onToggle={setOpen}
      menuVariant="flag-dropdown"
      show={open}
      align="start"
    >
      <NavDropdown.Item className="d-flex justify-items-center" onClick={() => setOpen(false)} active={true}>
        <span className="xxs-bold d-inline-block text-truncate" style={{ maxWidth: "15ch" }}>
          {currentLanguageDetails.LANGUAGE}
        </span>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      {languagesArray
        .filter((lang) => lang.code !== language)
        .map((lang) => (
          <NavDropdown.Item
            key={lang.code}
            onClick={() => switchLanguage(lang.code)}
            className="d-flex justify-items-center"
          >
            <span className="xxs-regular d-inline-block text-truncate" style={{ maxWidth: "15ch" }}>
              {lang.LANGUAGE}
            </span>
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
};

export default LanguageSelector;
