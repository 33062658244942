import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";

import { DstTabsProps } from "../../models/TabModel";
import "./DstTabsVertical.scss";

const DstTabsVertical = ({
  activeKey,
  bodyClass,
  bordeRight,
  classes,
  customButtonComponent,
  fullHeight,
  onSelect,
  paddingTop,
  tabsData,
  titleIcon = <i className="dst-icon-users-01 me-2" />,
  titleTabs,
  variant,
  TabsSize = { xs: 6, sm: 3, md: 3, lg: 3, xl: 2 },
}: DstTabsProps & {
  activeKey: string;
  bordeRight?: boolean;
  customButtonComponent?: React.ReactNode;
  fullHeight?: boolean;
  onSelect: (k: string | null) => void;
  titleIcon?: JSX.Element;
  titleTabs?: string;
  TabsSize?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  return (
    <div className={`dst-tabs-vertical d-block ${classes}`}>
      <Tab.Container defaultActiveKey={activeKey} activeKey={activeKey} onSelect={onSelect}>
        <Row className="mx-0">
          <Col
            xs={TabsSize.xs}
            sm={TabsSize.sm}
            md={TabsSize.md}
            lg={TabsSize.lg}
            xl={TabsSize.xl}
            className={`px-0 ${paddingTop && "pt-4"} ${fullHeight && "min-h-100vh"} ${bordeRight && "border-end"}`}
          >
            <div>
              {titleTabs && <h2 className="px-3 pb-3">{titleTabs}</h2>}
              <Nav variant={variant} className="flex-column">
                {tabsData.map((tab) => (
                  <Nav.Item key={tab.id} className="border-bottom">
                    <Nav.Link eventKey={tab.id}>
                      <div className="d-flex w-100 justify-content-between">
                        <div>
                          {titleIcon}
                          {tab.title}
                        </div>
                        <div className="align-content-center">
                          <i className="dst-icon-chevron-right" />
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
            {customButtonComponent}
          </Col>
          <Col className={bodyClass}>
            <Tab.Content>
              {tabsData.map((tab) => (
                <Tab.Pane eventKey={tab.id} key={tab.id}>
                  {tab.content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default DstTabsVertical;
