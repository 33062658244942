import ApiService from "../../services/ApiService";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";

export const extractReport = (cohortId: string, language: string, finalCallback: Function) => {
  ApiService.postDownload(`cohort/${cohortId}/report/extract`, {})
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_cohort_${cohortId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error: any) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.EXTRACT_REPORT.ERROR"));
    })
    .finally(() => finalCallback());
};

export const handlePedagogicalPathClick = (
  cohort_id: string,
  setShowPopup: Function,
  setActiveKey: Function,
  setIsPedagogicalPathLoading: Function,
  callback?: Function
) => {
  setIsPedagogicalPathLoading(true);
  ApiService.get(`cohorts/${cohort_id}/pedagogical_path`)
    .then((resp) => {
      window.open(resp.data.pedagogical_path.url, "_blank");
      callback?.();
    })
    .catch((err) => {
      setShowPopup(true);
      setActiveKey("resources");
    })
    .finally(() => setIsPedagogicalPathLoading(false));
};

export const fetchCohort = (
  cohort_id: any,
  language: string,
  setChangesCohort: (changes: any) => void,
  setCohort: (cohort: any) => void,
  setCohortLeaderText: (text: string) => void,
  setLoading: (loading: boolean) => void,
  setProgramManagerText: (text: string) => void
) => {
  ApiService.get(`cohorts/${cohort_id}`)
    .then((response) => {
      const cohortData = response.data.cohort;
      const formattedCohort = {
        ...cohortData,
        cohort_leader: cohortData.cohort_leader?.full_name,
        program_manager: cohortData.program_manager?.full_name,
        start_date: formatDateHumanly(language, cohortData.dt_start),
        end_date: formatDateHumanly(language, cohortData.dt_end),
        dt_created: formatDateHumanly(language, cohortData.dt_created),
      };
      setCohort(formattedCohort);
      setCohortLeaderText(cohortData.cohort_leader?.full_name);
      setProgramManagerText(cohortData.program_manager?.full_name);
      setChangesCohort({
        name: cohortData.name,
        dt_end: cohortData.dt_end,
        description: cohortData.description,
        need_project: cohortData.need_project,
        workspace_url: cohortData.workspace_url,
      });
    })
    .catch((error) => {
      console.error("Error fetching Cohort:", error);
      alert(translate(language, "PAGES.COHORT.ERROR.API_ERROR"));
    })
    .finally(() => {
      setLoading(false);
    });
};

export const patchCohortChanges = (
  language: string,
  cohort_id: string,
  cohort: any,
  changesCohort: any,
  setIsCohortLoading: Function,
  callBack: Function
) => {
  let payload = { ...changesCohort };

  Object.keys(payload).forEach((key) => {
    if (payload[key] === cohort[key]) {
      delete payload[key];
    } else if (key === "dt_end") payload["dt_end"] = payload["dt_end"] / 1000;
  });

  setIsCohortLoading(true);
  ApiService.patch(`cohorts/${cohort_id}`, payload)
    .then((_) => callBack())
    .catch((error) => {
      console.error("Error saving Cohort:", error);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => {
      setIsCohortLoading(false);
    });
};

export const fetchCohortLeaders = (setCohortLeaderArray: (leaders: any[]) => void, language: string) => {
  ApiService.get("cohorts/leaders")
    .then((response) => {
      setCohortLeaderArray(response.data.cohort_leaders);
    })
    .catch((error) => {
      console.error("Error fetching Cohort Leaders:", error);
      alert(translate(language, "PAGES.COHORT.ERROR.API_ERROR"));
    });
};

export const fetchProgramManagers = (setProgramManagerArray: (managers: any[]) => void, language: string) => {
  ApiService.get("cohorts/managers")
    .then((response) => {
      setProgramManagerArray(response.data.cohort_managers);
    })
    .catch((error) => {
      console.error("Error fetching Program Managers:", error);
      alert(translate(language, "PAGES.COHORT.ERROR.API_ERROR"));
    });
};
