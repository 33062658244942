class ShortcutService {
  listen(keyname: string, callback: Function, ctrl = false, shift = false): Function {
    const _child_function = (event: globalThis.KeyboardEvent) => {
      if (
        event.key.toLowerCase() === keyname.toLowerCase() &&
        (document.activeElement === document.body ||
          (!["input", "textarea"].includes(document.activeElement!.tagName.toLowerCase()) &&
            !document.activeElement?.attributes.getNamedItem("contenteditable"))) &&
        shift === event.shiftKey &&
        ctrl === (event.metaKey || event.ctrlKey)
      ) {
        callback();
        event.preventDefault();
        return;
      }
    };
    document.addEventListener("keydown", _child_function);
    return () => document.removeEventListener("keydown", _child_function);
  }
}

const shortcutService = new ShortcutService();

export default shortcutService;
