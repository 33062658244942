import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const fetchProjects = (cohortId: string, setProjects: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/projects`)
    .then((resp) => {
      setProjects(resp.data.projects || []);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      setProjects([]);
      alert(translate(language, "PAGES.COHORT.PROJECTS.ERROR.FETCH_PROJECTS"));
    });
};
