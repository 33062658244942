import { datadogRum } from "@datadog/browser-rum";

if (["production", "pre-production"].includes(process.env.REACT_APP_DATADOG_ENVIRONMENT || "local")) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
    site: "datadoghq.eu",
    service: "nexus",
    env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: process.env.REACT_APP_DATADOG_ENVIRONMENT === "pre-production" ? 100 : 20,
    sessionReplaySampleRate: process.env.REACT_APP_DATADOG_ENVIRONMENT === "pre-production" ? 100 : 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
