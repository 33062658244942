export interface ListItem {
  [key: string]: any;
}

export const getNestedProperty = (obj: ListItem, path: string, defaultValue: any = null): any => {
  return path.split(".").reduce((acc: any, part: string) => acc && acc[part], obj) || defaultValue;
};

export const completeNavigate = (event: React.MouseEvent, navigate: Function, path: string) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(path, "_blank", "noopener");
  } else if (path.slice(0, 4) === "http") {
    window.open(path);
  } else {
    navigate(path);
  }
};

export const getLegacyAdminHostname = () =>
  `https://${window.location.hostname === "nexus.datascientest.com" ? "" : "preprod."}beta.admin.datascientest.com`;

export const capitalizeWord = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

export const truncateString = (content: string, length: number = 100, sanitizeString: boolean = false): string => {
  if (sanitizeString)
    content = content.replace(/<\/p>/g, "</p> ").replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gm, "");
  return content.length > length ? content.slice(0, length - 1) + "…" : content;
};
