import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";

import Search from "../../../../components/DstIcons/Search";
import Lister from "../../../../components/Lister/Lister";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { openDocument } from "./UserDocuments.function";

const UserDocuments = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => (
  <Container fluid>
    {loaded ? (
      <Lister
        items={user_data.user_documents.map((user_document: any) => ({
          ...user_document,
          date: user_document.dt_updated || user_document.dt_created,
        }))}
        columns={[
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.NAME"), key: "name" },
          {
            header: translate(language, "PAGES.USER.DOCUMENT_TABLE.DATE"),
            key: "date",
            render: (item: any) => (
              <div>
                <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
                  <span>{formatDateHumanly(language, item["date"])}</span>
                </OverlayTrigger>
              </div>
            ),
          },
        ]}
        additionalButton={(item: any) => (
          <span className="clickable" onClick={() => openDocument(item.type, item.filename)}>
            <Search classes="search-icon" />
          </span>
        )}
        mock={!loaded}
      />
    ) : (
      <Lister
        columns={[
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.NAME"), key: "name" },
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.DATE"), key: "date" },
        ]}
        mock={true}
      />
    )}
  </Container>
);

export default UserDocuments;
