import ApiService from "../../../../services/ApiService";
import { getUTCTime } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { CohortSprintDetails } from "./CohortSprint.d";

export const fetchSprint = (cohortId: string, language: string, setSprints: Function, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/sprints`)
    .then((resp) => {
      setSprints(resp.data.sprints || []);
      callback?.();
    })
    .catch((error) => {
      setSprints([]);
      console.error(error);
      alert(translate(language, "PAGES.COHORT.SPRINT.ERROR_FETCH"));
    });
};

export const fetchModules = (setModules: Function, callback?: Function) => {
  ApiService.get("modules?get_deprecated=false")
    .then((response) => {
      setModules(response.data.return.modules);
      callback?.();
    })
    .catch((err) => {
      console.error("An error occurred while fetching modules", err);
    });
};

export const transformSprintData = (sprints: any): CohortSprintDetails[] => {
  const _mapModules = (modules: any[], userModulesInfo: any[] = []) =>
    modules.map((module: any) => {
      const userModuleInfo = userModulesInfo.find((mod: any) => mod.module_id === module.module) || {};
      return {
        id: module.module,
        isMandatory: module.is_mandatory,
        isValidated: module.dt_end,
        moduleName: userModuleInfo.module_name || module.name || module.description,
        moduleExercisesInfos: {
          totalExercises: userModuleInfo.total_exercises || 0,
          completedExercises: userModuleInfo.completed_exercises || 0,
        },
      };
    });

  const _createCohortSprint = (sprint: any, modules: any[], userModulesInfo?: any[]) => ({
    dt_end: sprint.dt_end,
    dt_start: sprint.dt_start,
    id: sprint.id,
    name: sprint.name,
    current_sprint: sprint.current_sprint,
    sprintModules: _mapModules(modules, userModulesInfo),
  });

  return sprints.flatMap((data: any) => {
    if (!data.users || data.users.length === 0) {
      return [
        {
          id: null,
          sprintId: data.sprint.id,
          lastName: null,
          firstName: null,
          email: null,
          cohortSprints: [_createCohortSprint(data.sprint, data.modules)],
        },
      ];
    }

    return data.users.map((user: any) => ({
      id: user.id,
      userId: user.id,
      sprintId: data.sprint.id,
      lastName: user.last_name,
      firstName: user.first_name,
      email: user.email,
      mandatoryExams: user.mandatory_exams,
      cohortSprints: [_createCohortSprint(data.sprint, data.modules, user.modules_info)],
    }));
  });
};

export const allMandatoryModulesValidated = (
  sprintModules: CohortSprintDetails["cohortSprints"][0]["sprintModules"]
): boolean => {
  return sprintModules.every((module) => {
    if (module.isMandatory) {
      return module.moduleExercisesInfos.completedExercises === module.moduleExercisesInfos.totalExercises;
    }
    return true;
  });
};

const _mapSprintModules = (sprint: CohortSprintDetails["cohortSprints"][0]) =>
  sprint.sprintModules.map((module) => ({
    id: module.id,
    isMandatory: module.isMandatory,
    dt_end: module.isValidated || sprint.dt_end,
    dt_start: module.dt_start || sprint.dt_start,
    moduleName: module.moduleName,
  }));

export const getSprintsAndModules = (sprintData: CohortSprintDetails[]) => {
  const result: {
    [key: string]: { id: number; isMandatory: boolean; dt_end: string; dt_start: string; moduleName: string }[];
  } = {};

  sprintData.forEach((item) => {
    item.cohortSprints.forEach((sprint) => {
      if (!result[sprint.name]) {
        result[sprint.name] = _mapSprintModules(sprint);
      } else {
        const modules = _mapSprintModules(sprint);
        const existingModules = result[sprint.name].map((module) => module.id);
        modules.forEach((module) => {
          if (!existingModules.includes(module.id)) {
            result[sprint.name].push(module);
          }
        });
      }
    });
  });

  return result;
};

export const handleStateChange = (index: number, newValue: string, stateArray: string[], setStateArray: Function) => {
  setStateArray((prevArray: string[]) => {
    const newStateArray = [...prevArray];
    newStateArray[index] = newValue;
    return newStateArray;
  });
};

export const handleOptionalChange = (
  sprintIndex: number,
  lessonIndex: number,
  optionalStatus: any[],
  setOptionalStatus: Function
) => {
  const newOptionalStatus = [...optionalStatus];
  newOptionalStatus[sprintIndex][lessonIndex].isOptional = !newOptionalStatus[sprintIndex][lessonIndex].isOptional;
  setOptionalStatus(newOptionalStatus);
};

export const saveSprints = (
  cohortId: string,
  index: number,
  inputValues: string[],
  sprintNames: string[],
  startDates: string[],
  endDates: string[],
  optionalStatus: any[],
  initialSprintsAndLessons: any,
  setSprintNames: Function,
  language: string,
  callback?: Function
) => {
  const newSprintNames = [...sprintNames];
  const newSprintName = inputValues[index];

  newSprintNames[index] = newSprintName;
  setSprintNames(newSprintNames);

  const updatedSprintsAndLessons = { ...initialSprintsAndLessons };

  sprintNames.forEach((_, sprintIndex) => {
    const oldSprintName = sprintNames[sprintIndex];
    const newSprintName = inputValues[sprintIndex];

    const filteredModules = optionalStatus[sprintIndex].map((module: any) => ({
      id: module.id,
      moduleName: module.name,
      isMandatory: !module.isOptional,
      dt_start: startDates[sprintIndex],
      dt_end: endDates[sprintIndex],
      sprintModules: [],
    }));

    updatedSprintsAndLessons[newSprintName] = filteredModules;

    updatedSprintsAndLessons[newSprintName][0] = {
      ...updatedSprintsAndLessons[newSprintName][0],
      dt_start: startDates[sprintIndex],
      dt_end: endDates[sprintIndex],
    };

    if (oldSprintName !== newSprintName) {
      delete updatedSprintsAndLessons[oldSprintName];
    }
  });

  const allSprintsData = sprintNames.map((_, sprintIndex) => {
    return {
      name: inputValues[sprintIndex],
      start: ~~(getUTCTime(startDates[sprintIndex]) / 1000),
      end: ~~(getUTCTime(endDates[sprintIndex]) / 1000),
      modules: updatedSprintsAndLessons[inputValues[sprintIndex]].map((module: any) => {
        return {
          id: module.id,
          is_mandatory: module.isMandatory,
        };
      }),
    };
  });

  ApiService.put(`cohorts/${cohortId}/sprints`, { sprints: allSprintsData })
    .then(() => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.SPRINT.SAVE_ERROR"));
    });
};
