import React from "react";
import { Modal } from "react-bootstrap";

import DstButton from "../DstButton/DstButton";
import { DstConfirmProps } from "./DstConfirm.d";

/**
 * `DstConfirm` displays a responsive confirm component.
 *  It supports a function to confirm the alert.
 * @component
 * 
 * @param {string} buttonClass - Class name for the button.
 * @param {string} buttonSize - Size of the button.
 * @param {string} buttonVariant - Variant of the button.
 * @param {string} confirmTextButton - Text of the confirm button.
 * @param {string} icon - Icon of the alert.
 * @param {string} iconBackgroundBorderRadius - Border radius of the background of the icon.
 * @param {string} iconBackgroundColor - Background color of the icon.
 * @param {string} iconBackgroundHeight - Height of the background of the icon.
 * @param {string} iconBackgroundWidth - Width of the background of the icon.
 * @param {string} iconColor - Color of the icon.
 * @param {string} iconSize - Size of the icon.
 * @param {string} messageExplain - Message to explain the alert.
 * @param {string} messageConfirm - Message to confirm the alert.
 * @param {function} onHide - Function to hide the alert.
 * @param {function} onConfirm - Function to confirm the alert.
 * @param {boolean} show - Show the alert.
 * @param {string} title - Title of the alert.
 * @param {string} textPosition - Position of the text.
 * 

 *
 * @example
 * const [showModal, setShowModal] = useState(false);
 * const handleShow = () => setShowModal(true);
 * const handleClose = () => setShowModal(false);
 * 
 * <button onClick={handleShow}>Show Alert Modal</button>
 * <DstConfirm
 *  show={showModal}
 *  onHide={handleClose}
 *  onConfirm={handleClose}
 *  title="Are you sure?"
 *  messageExplain="Once you delete this file it will be gone forever."
 *  messageConfirm="Are you sure you want to delete it?"
 *  confirmTextButton="Delete"
 * />
 * 
 */

const DstConfirm = ({
  buttonClass,
  buttonVariant = "danger",
  buttonSize = "lg",
  confirmTextButton,
  icon = "dst-icon-trash-01",
  iconBackgroundBorderRadius = "50px",
  iconBackgroundColor = "var(--color-light-pink)",
  iconBackgroundHeight = "100px",
  iconBackgroundWidth = "100px",
  iconColor = "red",
  iconSize = "70px",
  messageConfirm,
  messageExplain,
  onHide,
  onConfirm,
  show,
  title,
  textPosition = "text-center",
}: DstConfirmProps) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton />
      <Modal.Title
        className={`${textPosition} text-break d-flex flex-column justify-content-center align-content-center px-3 px-md-4`}
      >
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: iconBackgroundColor,
              borderRadius: iconBackgroundBorderRadius,
              minWidth: iconBackgroundWidth,
              minHeight: iconBackgroundHeight,
            }}
          >
            <span className={icon} style={{ fontSize: iconSize, color: iconColor }} />
          </div>
        </div>
        {title}
      </Modal.Title>
      <Modal.Body
        className={`${textPosition} text-break d-flex flex-column justify-content-center align-content-center px-3 px-md-4`}
      >
        <div>{messageExplain}</div>
        <div>{messageConfirm}</div>
        <div className="text-center my-5">
          <DstButton
            btnClass={buttonClass}
            variant={buttonVariant}
            clickFunction={onConfirm}
            value={confirmTextButton}
            btnSize={buttonSize}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DstConfirm;
