import { Placeholder } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import CheckIcon from "../DstIcons/CheckIcon";
import CrossIcon from "../DstIcons/CrossIcon";
import "./CertifBlockOverview.scss";

const CertifBlockOverview = ({
  attempts = 0,
  backgroundColor = "var(--color-white)",
  borderStyle = "1px solid light-gray-background",
  certifDate,
  mainText,
  mock,
  remarkTextColor = "rgba(var(--color-black-rgba), 0.4)",
  remarkMainText = "Time spent",
  remarkText,
  textColor = "var(--color-black)",
  titleColor = "rgba(var(--color-black-rgba), 0.4)",
  topLeftTitle,
}: {
  attempts?: number;
  backgroundColor?: string;
  borderStyle?: string;
  certifDate?: string;
  mainText?: string;
  mock?: boolean;
  remarkTextColor?: string;
  remarkMainText?: string;
  remarkText?: string;
  textColor?: string;
  titleColor?: string;
  topLeftTitle?: string;
}) => {
  const { language } = useSession();

  return mock ? (
    <Placeholder animation="glow">
      <Card className="certification-overview my-2">
        <Card.Body className="p-2">
          <Card.Title className="xs-regular my-1 align-items-center" as="section">
            <Placeholder className="xxs-regular my-0 lh-1" as="p" xs={12} />
            <Placeholder className="xs-bold" as="p" xs={12} />
          </Card.Title>
          <Card.Text className="m-bold position-relative w-100" as="section">
            <Placeholder className="mb-0" as="p" xs={12} />
            <Placeholder className="xs-regular" xs={6} />
          </Card.Text>
        </Card.Body>
        <Card.Footer className="align-items-center d-flex xs-regular bg-white my-1 mx-1 py-0 px-2">
          <Placeholder as="p" xs={6} />
        </Card.Footer>
      </Card>
    </Placeholder>
  ) : (
    <Card className="certification-overview my-2" style={{ backgroundColor, border: borderStyle }}>
      <Card.Body className="p-2">
        <Card.Title className="xs-regular my-1 align-items-center" style={{ color: titleColor }} as="section">
          <p className="xxs-regular my-0 lh-1">{topLeftTitle}</p>
          {remarkMainText && remarkText && (
            <p className="d-inline xs-regular" style={{ color: remarkTextColor }}>
              {remarkMainText}: <span className="xs-bold">{remarkText}</span>
            </p>
          )}
        </Card.Title>
        <Card.Text className="m-bold" style={{ color: textColor }} as="section">
          <p className="mb-0">{mainText}</p>
          <p className="mb-0 xs-regular charcoal-gray-font">{certifDate && formatDateHumanly(language, certifDate)}</p>
        </Card.Text>
      </Card.Body>
      {attempts > 0 && (
        <Card.Footer className="align-items-center d-flex xs-regular bg-white my-1 mx-1 py-0 px-2">
          <span>{translate(language, "PAGES.USER.CERTIFICATION.ATTEMPTS")}:</span>
          <span>
            {[...Array(attempts - 1)].map((_, index) => (
              <CrossIcon key={index} classes="ms-1" />
            ))}
            <CheckIcon classes="ms-1" />
          </span>
        </Card.Footer>
      )}
    </Card>
  );
};

export default CertifBlockOverview;
