import React from "react";
import { Col, Dropdown, DropdownButton, Form, Placeholder, Row } from "react-bootstrap";

import DstButton from "../../../components/DstButton/DstButton";
import MultiFilterDropdown from "../../../components/MultiFilterDropdown/MultiFilterDropdown";
import translate from "../../../services/Translate";
import { LoadedPermissionsData } from "../Permissions.d";
import {
  closeRolePopup,
  duplicateRole,
  handleDeleteRole,
  handleSelectAllPermissions,
  handleUnselectAllPermissions,
  openRolePopup,
  openRoutePopup,
  updateRole,
} from "./../Permissions.functions";
import { rolePopup } from "./../components/Popups";
import "./Tabs.scss";

const METHODS = ["GET", "POST", "PUT", "PATCH", "DELETE"];

const ALL_RIGHTS_KEY = "*_/**";

const _handlePermissionSelect = (
  roleId: string,
  route: string,
  method: string,
  rolePermissions: { [roleId: string]: string[] },
  setRolePermissions: Function
) => {
  let currentPermissions = rolePermissions[roleId] || [];
  const permissionKey = `${method}_${route}`;

  if (
    (permissionKey !== ALL_RIGHTS_KEY && currentPermissions.includes(ALL_RIGHTS_KEY)) ||
    permissionKey === ALL_RIGHTS_KEY
  ) {
    currentPermissions = [];
  }
  setRolePermissions({
    ...rolePermissions,
    [roleId]: currentPermissions.includes(permissionKey)
      ? currentPermissions.filter((permission) => permission !== permissionKey)
      : [...currentPermissions, permissionKey],
  });
};

export const buildTabs = (
  language: string,
  roles: Array<any>,
  tags: Array<any>,
  permissions: { [route: string]: any },
  roleUsers: { [roleId: string]: number[] },
  loaded: LoadedPermissionsData,
  selectedTags: any[],
  setSelectedTags: Function,
  selectedMethods: string[],
  setSelectedMethods: Function,
  displayPage: number,
  setDisplayPage: Function,
  rolePermissions: { [roleId: string]: string[] },
  setRolePermissions: Function,
  activeTab: string,
  showPopups: { [key: string]: number | string } | null,
  setShowPopups: Function,
  fetchData: Function
) => {
  const filteredPermissions = Object.entries(permissions).filter(([route, data]) => {
    const tagsMatch = selectedTags.every((tag) =>
      data
        .map((dataMethod: any) => dataMethod?.documentation?.tags)
        .flat()
        .includes(tag)
    );
    const methodsMatch =
      selectedMethods.length === 0 ||
      selectedMethods.some((method) => data.map((dataMethod: any) => dataMethod.method).includes(method));
    return tagsMatch && methodsMatch;
  });

  return roles.length > 0
    ? roles.map((role: any) => ({
        id: role.id,
        title: role.name,
        content: (
          <div className="permissions-tab">
            <Row className="permissions-panel">
              <Col xl={9} className="pt-4 h-100">
                <Row className="permissions-panel-header">
                  <Col>
                    <h2>
                      {role.name}
                      {translate(language, "PAGES.PERMISSIONS.TABS.TITLE")}
                    </h2>
                    <div className="d-flex gap-2">
                      <MultiFilterDropdown
                        language={language}
                        tags={tags}
                        onSelectTags={(selectedItems: Array<any>) => {
                          setSelectedTags(selectedItems);
                        }}
                        mock={!loaded.tags}
                        initialStateText={translate(language, "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INITIAL_TEXT")}
                        inputTitle={translate(language, "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.TITLE")}
                        inputLabel={translate(language, "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INPUT_LABEL")}
                        inputPlaceholder={translate(
                          language,
                          "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INPUT_PLACEHOLDER"
                        )}
                        showSearchBar
                      />
                      <MultiFilterDropdown
                        tags={METHODS}
                        onSelectTags={(selectedItems: (string | number)[]) =>
                          setSelectedMethods(selectedItems as string[])
                        }
                        mock={!loaded.permissions}
                        inputTitle={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.SELECT_METHODS")}
                        language={language}
                      />
                    </div>
                  </Col>
                  <Col className="text-end">
                    <div>
                      <DstButton
                        backgroundColor="var(--color-off-white)"
                        color="var(--color-black)"
                        variant="light"
                        value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.SAVE_ROLE")}
                        btnImageBefore={<i className="dst-icon-save-01 me-2" />}
                        clickFunction={() => updateRole(language, role.id, rolePermissions[activeTab], fetchData)}
                      />
                      <DstButton
                        backgroundColor="var(--color-off-white)"
                        color="var(--color-black)"
                        variant="light"
                        value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.DUPLICATE_ROLE")}
                        btnImageBefore={<i className="dst-icon-copy-07 me-2" />}
                        btnClass="ms-2"
                        clickFunction={() => duplicateRole(language, role, fetchData)}
                      />
                      <DstButton
                        backgroundColor="var(--color-off-white)"
                        color="var(--color-black)"
                        variant="light"
                        value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.RIGHTS_SUMMARY")}
                        btnImageBefore={<i className="dst-icon-file-02 me-2" />}
                        btnClass="ms-2"
                        clickFunction={() => openRolePopup(setShowPopups, activeTab)}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <DropdownButton
                        id="dropdown-check-method"
                        title={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.CHOOSE_ALL")}
                        variant="light"
                        className="s-bold mt-2 ms-2"
                      >
                        {METHODS.map((method) => (
                          <Dropdown.Item
                            key={method}
                            onClick={() =>
                              handleSelectAllPermissions(
                                method,
                                rolePermissions,
                                activeTab,
                                permissions,
                                selectedTags,
                                setRolePermissions
                              )
                            }
                          >
                            {method}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <DropdownButton
                        id="dropdown-check-method"
                        title={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.UNCHOOSE_ALL")}
                        variant="light"
                        className="s-bold mt-2 ms-2"
                      >
                        {METHODS.map((method) => (
                          <Dropdown.Item
                            key={method}
                            onClick={() =>
                              handleUnselectAllPermissions(
                                method,
                                rolePermissions,
                                activeTab,
                                permissions,
                                selectedTags,
                                setRolePermissions
                              )
                            }
                          >
                            {method}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                  </Col>
                </Row>
                {loaded.permissions ? (
                  <div className="permissions-list overflow-auto">
                    <div className="d-flex my-3 justify-content-between">
                      <div className="d-flex">
                        <DstButton
                          variant="outline-primary"
                          value=""
                          btnImageAfter={<i className="dst-icon-info-circle" />}
                          btnClass="d-flex fw-bolder me-3 px-2"
                          clickFunction={() => openRoutePopup(setShowPopups, "/**")}
                        />
                        <div className="red-bright-font tinted-red-background rounded border px-2">{"/**"}</div>
                      </div>
                      <Form className="d-flex">
                        <Form.Group controlId={`formBasicCheckbox_god_mode`} className="d-flex">
                          <Form.Check
                            type="checkbox"
                            label="All"
                            id={`role-${role.id}-checkbox-god-mode`}
                            className="w-fit me-3 align-self-center checkbox-style"
                            onChange={() =>
                              _handlePermissionSelect(role.id, "/**", "*", rolePermissions, setRolePermissions)
                            }
                            checked={rolePermissions[activeTab]?.includes(ALL_RIGHTS_KEY)}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                    {filteredPermissions.map(([route, data]) => (
                      <div key={route} className="d-flex my-3 justify-content-between">
                        <div className="d-flex">
                          <DstButton
                            value=""
                            variant="outline-primary"
                            btnImageAfter={<i className="dst-icon-info-circle" />}
                            btnClass="d-flex fw-bolder me-3 px-2"
                            clickFunction={() => openRoutePopup(setShowPopups, route)}
                          />
                          <div className="red-bright-font tinted-red-background rounded border px-2">{route}</div>
                        </div>
                        <Form className="d-flex">
                          <Form.Group controlId={`formBasicCheckbox_${route}`} className="d-flex">
                            {METHODS.map((method: any) => (
                              <Form.Check
                                key={`${route}_${method}`}
                                type="checkbox"
                                label={method}
                                id={`role-${role.id}-${method}-checkbox-${route}`}
                                className="w-fit me-3 align-self-center checkbox-style"
                                disabled={!data.map((elem: any) => elem.method).includes(method)}
                                onChange={() =>
                                  _handlePermissionSelect(role.id, route, method, rolePermissions, setRolePermissions)
                                }
                                checked={rolePermissions[activeTab]?.includes(`${method}_${route}`)}
                              />
                            ))}
                          </Form.Group>
                        </Form>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Placeholder animation="glow">
                    {[...Array(10)].map((_, index: number) => (
                      <Placeholder key={`mock-permission-line-${index}`} className="mt-3" xs={12} role="mockup" />
                    ))}
                  </Placeholder>
                )}
              </Col>
              <Col className="pt-4 border-start min-h-100vh">
                <h2>{translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.TITLE")}</h2>
                <div className="span m-bold">
                  {translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.LIST_TITLE")}
                </div>
                {loaded.rolesUsers?.[role.id] ? (
                  <ul className="list-unstyled ps-0">
                    {roleUsers[role.id]?.length > 0 ? (
                      roleUsers[role.id].map((userId) => (
                        <li
                          key={userId}
                          className="megium-gray-background list-style-none w-fit border my-2 px-2 rounded"
                        >
                          {userId}
                        </li>
                      ))
                    ) : (
                      <p className="my-2 smoke-gray-font m-regular">
                        {translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.NO_USER")}
                      </p>
                    )}
                  </ul>
                ) : (
                  <Placeholder as="div" animation="glow" role="mockup">
                    <Placeholder xs={10} role="mockup" />
                    <Placeholder xs={10} role="mockup" />
                    <Placeholder xs={10} role="mockup" />
                  </Placeholder>
                )}
                {loaded.rolesUsers?.[role.id] && displayPage < 5 && (
                  <div>
                    <DstButton
                      value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.LOAD_MORE")}
                      clickFunction={() => setDisplayPage((displayPage: number) => displayPage + 1)}
                      variant="secondary"
                      btnClass="mt-2 s-regular"
                    />
                  </div>
                )}

                <DstButton
                  backgroundColor="var(--color-medium-gray)"
                  color="var(--color-black)"
                  variant="light"
                  value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.ADD_NEW_USER")}
                  btnClass="mt-3"
                  btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
                  clickFunction={() => alert(translate(language, "ERROR.DISABLED_FEATURE"))}
                />
                <div>
                  <DstButton
                    value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.DELETE_ROLE")}
                    variant="danger"
                    btnClass="mt-3"
                    btnImageBefore={<i className="dst-icon-trash-01 me-2" />}
                    clickFunction={() => handleDeleteRole(language, role.id, fetchData)}
                  />
                </div>
                {rolePopup(language, showPopups, () => closeRolePopup(setShowPopups), role)}
              </Col>
            </Row>
          </div>
        ),
      }))
    : [];
};
