const Users = ({
  color = "var(--color-silver-gray)",
  classes = "me-2",
  height = "1em",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => (
  <svg className={classes} height={height} viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 7.0625C12.9942 7.0625 14.3142 7.42 15.3867 7.8875C16.3767 8.3275 17 9.3175 17 10.39V10.9583C17 11.4625 16.5875 11.875 16.0833 11.875H6.91667C6.4125 11.875 6 11.4625 6 10.9583V10.3992C6 9.3175 6.62333 8.3275 7.61333 7.89667C8.68583 7.42 10.0058 7.0625 11.5 7.0625ZM4.16667 7.29167C5.175 7.29167 6 6.46667 6 5.45833C6 4.45 5.175 3.625 4.16667 3.625C3.15833 3.625 2.33333 4.45 2.33333 5.45833C2.33333 6.46667 3.15833 7.29167 4.16667 7.29167ZM5.2025 8.3C4.86333 8.245 4.52417 8.20833 4.16667 8.20833C3.25917 8.20833 2.3975 8.40083 1.61833 8.74C1.28624 8.88195 1.00318 9.11837 0.80435 9.41988C0.60552 9.72138 0.499685 10.0747 0.500001 10.4358V10.9583C0.500001 11.4625 0.912501 11.875 1.41667 11.875H4.625V10.3992C4.625 9.63833 4.83583 8.92333 5.2025 8.3ZM18.8333 7.29167C19.8417 7.29167 20.6667 6.46667 20.6667 5.45833C20.6667 4.45 19.8417 3.625 18.8333 3.625C17.825 3.625 17 4.45 17 5.45833C17 6.46667 17.825 7.29167 18.8333 7.29167ZM22.5 10.4358C22.5 9.69333 22.06 9.03333 21.3817 8.74C20.5779 8.38927 19.7103 8.20827 18.8333 8.20833C18.4758 8.20833 18.1367 8.245 17.7975 8.3C18.1642 8.92333 18.375 9.63833 18.375 10.3992V11.875H21.5833C22.0875 11.875 22.5 11.4625 22.5 10.9583V10.4358ZM11.5 0.875C13.0217 0.875 14.25 2.10333 14.25 3.625C14.25 5.14667 13.0217 6.375 11.5 6.375C9.97833 6.375 8.75 5.14667 8.75 3.625C8.75 2.10333 9.97833 0.875 11.5 0.875Z"
      fill={color}
    />
  </svg>
);

export default Users;
