import React, { useEffect, useState } from "react";
import { FormControl, FormSelect, OverlayTrigger, Placeholder, Tab, Tabs, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Evaluation } from "../../models/Evaluation";
import { PermissionComponent } from "../../services/PermissionGuard";
import ShortcutService from "../../services/ShortcutService";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../services/UtilityService";
import DstButton from "../DstButton/DstButton";
import Chevron from "../DstIcons/Chevron";
import Edit from "../DstIcons/Edit";
import Key from "../DstIcons/Key";
import LogIn from "../DstIcons/LogIn";
import LogOut from "../DstIcons/LogOut";
import Hubspot from "../DstIcons/hubspot";
import MultiSelectDropdown from "../MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../Popup/Popup";
import { UserBlockProps } from "./UserBlock.d";
import {
  fetchEvaluations,
  fetchProgressReport,
  handleSaveEvaluations,
  inviteUserToEvaluation,
  refreshUserEvaluation,
} from "./UserBlock.function";
import "./UserBlock.scss";

const _renderEvaluationsModal = (
  buttonClicked: boolean,
  setButtonClicked: Function,
  showEvaluationsModal: boolean,
  toggleEvaluationsModal: Function,
  evaluations: Evaluation[],
  user: any,
  setSelectedEvaluations: Function,
  selectedEvaluations: Evaluation[],
  setShowEvaluationsModal: Function,
  setEvaluations: Function,
  refreshUserCertifications: Function,
  language: string
) => {
  const modalSelectedTitle =
    selectedEvaluations.length > 0
      ? selectedEvaluations.length === 1
        ? `${selectedEvaluations[0].name}`
        : selectedEvaluations.length === 2
        ? `${selectedEvaluations[0].name} & ${translate(language, "PAGES.USER.USER_BLOCK.OTHER")}`
        : `${selectedEvaluations[0].name} & ${selectedEvaluations.length - 1} ${translate(
            language,
            "PAGES.USER.USER_BLOCK.OTHERS"
          )}`
      : translate(language, "PAGES.USER.USER_BLOCK.SELECT_EVAL");

  return (
    <Modal
      show={showEvaluationsModal}
      onHide={() => {
        toggleEvaluationsModal();
        setSelectedEvaluations([]);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{translate(language, "PAGES.USER.USER_BLOCK.EVALUATIONS")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MultiSelectDropdown
          badgeItemProperty="description"
          displayItem={(evaluation: any) => (
            <span>
              {evaluation.description} <span className="dark-gray-font">({evaluation.s3_name})</span>
            </span>
          )}
          label={modalSelectedTitle}
          toggleClass="text-truncate w-100"
          onConfirm={setSelectedEvaluations as (selectedItems: any[]) => void}
          items={evaluations}
          language={language}
        />
      </Modal.Body>
      <Modal.Footer>
        <DstButton
          variant="primary"
          value={translate(language, "PAGES.USER.USER_BLOCK.SAVE")}
          clickFunction={() => {
            handleSaveEvaluations(
              user,
              selectedEvaluations,
              setButtonClicked,
              evaluations,
              setEvaluations,
              setShowEvaluationsModal,
              setSelectedEvaluations,
              refreshUserEvaluation,
              refreshUserCertifications,
              language
            );
          }}
          disabled={!selectedEvaluations.length || buttonClicked}
        />
      </Modal.Footer>
    </Modal>
  );
};

const _renderInviteEvaluationModal = (
  buttonClicked: boolean,
  setButtonClicked: Function,
  showInviteEvaluationsModal: boolean,
  toggleInviteEvaluationsModal: Function,
  evaluations: Evaluation[],
  user: any,
  setSelectedEvaluations: Function,
  selectedEvaluations: Evaluation[],
  setShowInviteEvaluationsModal: Function,
  language: string,
  scheduledDate: string,
  setScheduledDate: Function
) => {
  const modalSelectedTitle =
    selectedEvaluations.length > 0
      ? selectedEvaluations.length === 1
        ? `${selectedEvaluations[0].name}`
        : selectedEvaluations.length === 2
        ? `${selectedEvaluations[0].name} & ${translate(language, "PAGES.USER.USER_BLOCK.OTHER")}`
        : `${selectedEvaluations[0].name} & ${selectedEvaluations.length - 1} ${translate(
            language,
            "PAGES.USER.USER_BLOCK.OTHERS"
          )}`
      : translate(language, "PAGES.USER.USER_BLOCK.INVITE_EVAL.SELECT_EVAL");

  return (
    <Modal
      show={showInviteEvaluationsModal}
      onHide={() => {
        toggleInviteEvaluationsModal();
        setSelectedEvaluations([]);
        setScheduledDate("");
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{translate(language, "PAGES.USER.USER_BLOCK.INVITE_EVAL.MODAL.TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MultiSelectDropdown
          badgeItemProperty="description"
          displayItem={(evaluation: any) => (
            <span>
              {evaluation.description} <span className="dark-gray-font">({evaluation.s3_name})</span>
            </span>
          )}
          label={modalSelectedTitle}
          toggleClass="text-truncate w-100 m-regular"
          onConfirm={setSelectedEvaluations as (selectedItems: any[]) => void}
          items={evaluations}
          language={language}
          isSingleSelect
          borderToggle
        />
        <div className="mt-3 m-regular">
          <label htmlFor="scheduledDate">
            {translate(language, "PAGES.USER.USER_BLOCK.INVITE_EVAL.SCHEDULE_DATE")}
          </label>
          <input
            type="datetime-local"
            id="scheduledDate"
            className="form-control"
            value={scheduledDate}
            onChange={(e) => setScheduledDate(e.target.value)}
            min={new Date().toISOString().slice(0, 16)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <DstButton
          variant="primary"
          value={translate(language, "PAGES.USER.USER_BLOCK.INVITE_EVAL.MODAL.CONFIRM")}
          clickFunction={() => {
            setButtonClicked(true);
            selectedEvaluations.forEach((evaluation) => {
              inviteUserToEvaluation(user.id, evaluation.id, language, scheduledDate || undefined);
            });
            setSelectedEvaluations([]);
            setShowInviteEvaluationsModal(false);
            setButtonClicked(false);
          }}
          disabled={!selectedEvaluations.length || buttonClicked}
        />
      </Modal.Footer>
    </Modal>
  );
};

const UserBlock = ({
  edit,
  editFunction,
  language,
  loginAsFunction,
  logoutFunction,
  mock,
  refreshUserCertifications,
  user,
}: UserBlockProps) => {
  const navigate = useNavigate();
  const [showCopyUserId, setShowCopyUserId] = useState(false);
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [showEvaluationsModal, setShowEvaluationsModal] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportParams, setReportParams] = useState<{
    cursusName?: string;
    cohortId?: number;
  }>({});
  const [showPopup, setShowPopup] = useState(false);
  const toggleEvaluationsModal = () => setShowEvaluationsModal(!showEvaluationsModal);
  const [selectedEvaluations, setSelectedEvaluations] = useState<Evaluation[]>([]);
  const [loadedCertif, setLoadedCertif] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showInviteEvaluationModal, setShowInviteEvaluationModal] = useState(false);
  const toggleInviteEvaluationModal = () => setShowInviteEvaluationModal(!showInviteEvaluationModal);
  const [scheduledDate, setScheduledDate] = useState<string>("");

  const copyUserIdToClipboardAndShowTooltip = () => {
    navigator.clipboard.writeText(user.id).then(() => {
      setShowCopyUserId(true);
      setTimeout(() => setShowCopyUserId(false), 3000);
    });
  };

  useEffect(() => {
    const listen_destructor = ShortcutService.listen("l", () => loginAsFunction?.(), false, true);

    return () => {
      listen_destructor();
    };
  }, [loginAsFunction]);

  useEffect(() => {
    if (user.user_certifs) {
      fetchEvaluations(user.user_certifs, setEvaluations, setLoadedCertif);
    }
  }, [user.user_certifs]);

  const userIcons = user?.user_flags?.map((flag: any, index: number) => (
    <OverlayTrigger
      key={`overlay-trigger-${index}`}
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-${flag.name.toLowerCase().replace(/\s+/g, "-")}`}>
          {flag?.cohort ? (
            <span>
              {flag?.name}
              <br />
              {flag.cohort.name} (#{flag.cohort.id})
            </span>
          ) : (
            flag?.name
          )}
        </Tooltip>
      }
    >
      <span className={`dst-icon-${flag.icon_name} mx-2`} aria-label={flag.description}></span>
    </OverlayTrigger>
  ));

  const transactions = user.transactions ? JSON.parse(user.transactions) : [];

  return (
    <div className="user-profile-container white-background border rounded position-relative">
      <div className="semi-circle position-absolute"></div>
      <div className="p-3 user-profile position-relative z-1">
        <PermissionComponent neededPermission={[{ methods: ["PATCH"], route: "/users/*" }]}>
          <div className="s-regular text-end blue-font clickable" onClick={editFunction}>
            <div style={{ visibility: edit && !mock ? "visible" : "hidden" }}>
              <Edit classes="me-1" />
              <u>{translate(language, "PAGES.USER.USER_BLOCK.EDIT")}</u>
            </div>
          </div>
        </PermissionComponent>
        <div className="user-profile-header text-center mt-4">
          <div className="d-flex justify-content-center">
            <div className="position-relative w-fit">
              {!mock ? (
                <img
                  src={user.image_url || "/assets/images/user.jpg"}
                  alt="user mock"
                  className="rounded-circle object-fit-cover"
                  height="60px"
                  width="60px"
                />
              ) : (
                <Placeholder animation="glow">
                  <Placeholder as="img" height={60} width={60} style={{ borderRadius: 60 }} />
                </Placeholder>
              )}
              {!mock ? (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="start-end-date-tooltip">
                      {formatDateHumanly(language, user.dt_start)} - {formatDateHumanly(language, user.dt_end)}
                    </Tooltip>
                  }
                >
                  <span
                    className={`position-absolute bottom-0 start-100 translate-middle-x badge border border-light rounded-circle ${
                      user.is_active ? "aqua" : "red"
                    }-background p-2`}
                  >
                    <span className="visually-hidden">
                      {translate(language, `PAGES.USER.USER_BLOCK.${user.is_active ? "ACTIVE" : "INACTIVE"}_STATUS`)}
                    </span>
                  </span>
                </OverlayTrigger>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
          {!mock ? (
            <React.Fragment>
              <h2 className="mb-0 mt-3">
                {user.first_name} {user.last_name} 
                <ReactCountryFlag
                  svg
                  countryCode={user.language.split("-")[1]}
                  title={user.language}
                  className="pb-1"
                />
              </h2>
              <p className="s-regular silver-gray-font">{user.email}</p>
              <div className="h2 position-relative d-flex justify-content-center align-items-center mb-4">
                <hr className="line-icons my-3" />
                <div
                  className="white-background position-absolute d-flex flex-wrap justify-content-center align-content-center blue-font"
                  style={{ width: "200px" }}
                >
                  {userIcons}
                  {user.has_extra_time && (
                    <OverlayTrigger
                      key={`overlay-trigger-clock`}
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-extra-time`} aria-label="Extra time">
                          {translate(language, "PAGES.USER.USER_BLOCK.EXTRA_TIME")}
                        </Tooltip>
                      }
                    >
                      <span className="dst-icon-clock-stopwatch mx-2" aria-hidden="true"></span>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <Placeholder animation="glow">
              <div className="mb-0 mt-3">
                <Placeholder as="h2" xs={12} />
                <Placeholder as="p" xs={12} />
              </div>
            </Placeholder>
          )}
        </div>
        {!mock ? (
          <React.Fragment>
            <Tabs defaultActiveKey="home" id="user-tab" className="mb-3" justify>
              <Tab eventKey="home" title={translate(language, "PAGES.USER.USER_BLOCK.COHORT")}>
                <div className="user-cohorts">
                  {user.user_cohorts?.length ? (
                    user.user_cohorts?.map((user_cohort: any, index: number) => (
                      <Link
                        key={`cohort-${index}`}
                        to={`/cohort/${user_cohort.id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <div className="d-flex mb-3">
                          <div className={`user-cohort-${index % 2 ? "violet" : "orange"} ps-2`}>
                            <div className="s-regular">{user_cohort.name}</div>
                            <div className="xxs-regular">
                              {user_cohort.cohort_leader.first_name} {user_cohort.cohort_leader.last_name}
                              {user_cohort.program_manager &&
                                ` - ${user_cohort.program_manager.first_name} ${user_cohort.program_manager.last_name}`}
                            </div>
                          </div>
                          <div className="align-self-center ms-4">
                            <Chevron />
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p className="xs-regular gray-font text-center">
                      {translate(language, "PAGES.USER.USER_BLOCK.NO_COHORT")}
                    </p>
                  )}
                </div>
              </Tab>
              <Tab eventKey="profile" title={translate(language, "PAGES.USER.USER_BLOCK.PROJECT")}>
                <div className="user-projects">
                  {user.user_projects?.length ? (
                    user.user_projects?.map((user_project: any, index: number) => (
                      <div
                        className="clickable d-flex mb-3"
                        key={`project-${index}`}
                        onClick={(event: any) =>
                          completeNavigate(
                            event,
                            navigate,
                            `${getLegacyAdminHostname()}/group_projects/${user_project.id}`
                          )
                        }
                      >
                        <div className="user-project-blue ps-2">
                          <div className="s-regular">{user_project.name}</div>
                          <div className="xxs-regular">
                            {user_project.description.length >= 120
                              ? `${user_project.description
                                  .slice(0, 120)
                                  .split(" ")
                                  .reverse()
                                  .slice(1)
                                  .reverse()
                                  .join(" ")}…`
                              : user_project.description}
                          </div>
                        </div>
                        <div className="align-self-center ms-4">
                          <Chevron />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="xs-regular gray-font text-center">
                      {translate(language, "PAGES.USER.USER_BLOCK.NO_PROJECT")}
                    </p>
                  )}
                </div>
              </Tab>
            </Tabs>
            <div className="s-bold mt-4 mb-2">{translate(language, "PAGES.USER.USER_BLOCK.ACTION")}</div>
            <div className="d-flex flex-wrap row-gap-2">
              <OverlayTrigger
                show={showCopyUserId}
                placement="top"
                overlay={
                  <Tooltip id="tooltip-copied">
                    {translate(language, "PAGES.USER.USER_BLOCK.COPY_ID")}{" "}
                    <span className="dst-icon-check-done-02"></span>
                  </Tooltip>
                }
              >
                <div>
                  <DstButton
                    btnClass="me-2"
                    variant="secondary"
                    value={user.id}
                    btnImageBefore={<Key />}
                    btnSize="sm"
                    clickFunction={copyUserIdToClipboardAndShowTooltip}
                  />
                </div>
              </OverlayTrigger>
              <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/users/*/certifications" }]}>
                {loadedCertif ? (
                  <React.Fragment>
                    <DstButton
                      variant="secondary"
                      value={translate(language, "PAGES.USER.USER_BLOCK.GIVE_CERTIF")}
                      btnImageBefore={<span className="dst-icon-trophy-01 pe-2 align-text-bottom" />}
                      btnSize="sm"
                      btnClass="me-2"
                      clickFunction={toggleEvaluationsModal}
                    />
                    {_renderEvaluationsModal(
                      buttonClicked,
                      setButtonClicked,
                      showEvaluationsModal,
                      toggleEvaluationsModal,
                      evaluations,
                      user,
                      setSelectedEvaluations,
                      selectedEvaluations,
                      setShowEvaluationsModal,
                      setEvaluations,
                      refreshUserCertifications,
                      language
                    )}
                  </React.Fragment>
                ) : (
                  <DstButton mock btnClass="me-2" btnWidth="70px" />
                )}
              </PermissionComponent>
              {loadedCertif ? (
                <React.Fragment>
                  <DstButton
                    variant="secondary"
                    value={translate(language, "PAGES.USER.USER_BLOCK.INVITE_EVAL.BUTTON")}
                    btnImageBefore={<span className="dst-icon-pen-tool-plus pe-2 align-text-bottom" />}
                    btnSize="sm"
                    btnClass="me-2"
                    clickFunction={toggleInviteEvaluationModal}
                  />
                  {_renderInviteEvaluationModal(
                    buttonClicked,
                    setButtonClicked,
                    showInviteEvaluationModal,
                    toggleInviteEvaluationModal,
                    evaluations,
                    user,
                    setSelectedEvaluations,
                    selectedEvaluations,
                    setShowInviteEvaluationModal,
                    language,
                    scheduledDate,
                    setScheduledDate
                  )}
                </React.Fragment>
              ) : (
                <DstButton mock btnClass="me-2" btnWidth="140px" />
              )}
              {!!user.user_cohorts.length && (
                <React.Fragment>
                  <DstButton
                    variant="secondary"
                    value={translate(language, "PAGES.USER.USER_BLOCK.DOWNLOAD_REPORT")}
                    btnImageBefore={<span className="dst-icon-file-download-02 pe-2 align-text-bottom" />}
                    btnSize="sm"
                    btnClass="me-2"
                    clickFunction={() => setShowReportModal(true)}
                  />
                  <Popup
                    show={showReportModal}
                    onClose={() => setShowReportModal(false)}
                    modalTitle={translate(language, "PAGES.USER.USER_BLOCK.REPORT.POPUP_TITLE")}
                  >
                    <FormSelect
                      className="mb-2"
                      onChange={(e) => setReportParams((params) => ({ ...params, cohortId: +e.target.value }))}
                    >
                      <option selected value="">
                        {translate(language, "PAGES.USER.USER_BLOCK.REPORT.CHOOSE_COHORT")}
                      </option>
                      {user.user_cohorts?.map((cohort: any, index: number) => (
                        <option key={`report-cohort-choice-${index}`} value={cohort.id}>
                          {cohort.name}
                        </option>
                      ))}
                    </FormSelect>
                    <FormControl
                      type="text"
                      placeholder={translate(language, "PAGES.USER.USER_BLOCK.REPORT.CURSUS_NAME")}
                      className="mb-3"
                      onChange={(e) => setReportParams((params) => ({ ...params, cursusName: e.target.value }))}
                    />
                    <DstButton
                      value={translate(language, "PAGES.USER.USER_BLOCK.REPORT.SUBMIT")}
                      btnClass="mx-auto d-block"
                      loading={loadingReport}
                      disabled={!(reportParams?.cursusName && reportParams?.cohortId && !loadingReport)}
                      clickFunction={() =>
                        fetchProgressReport(language, user.id, reportParams, setLoadingReport, () => {
                          setReportParams({});
                          setShowReportModal(false);
                        })
                      }
                    />
                  </Popup>
                </React.Fragment>
              )}
              {transactions.length > 0 && (
                <React.Fragment>
                  {transactions.length === 1 ? (
                    <a
                      href={`${process.env.REACT_APP_HUBSPOT_DEAL_URL}/${transactions[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Hubspot height="28px" classes="ms-3 clickable" />
                    </a>
                  ) : (
                    <span onClick={() => setShowPopup(true)}>
                      <Hubspot height="28px" classes="ms-3 clickable" />
                    </span>
                  )}
                  <Popup
                    show={showPopup}
                    onClose={() => setShowPopup(false)}
                    modalTitle={translate(language, "PAGES.USER.USER_BLOCK.HUBSPOT_TRANSACTIONS")}
                    scrollable={true}
                  >
                    <div className="text-center">
                      {transactions.map((transactionId: any, index: number) => (
                        <DstButton
                          key={`transaction-${index}`}
                          btnClass="mx-2"
                          backgroundColor="var(--color-pumpkin)"
                          color="var(--color-white)"
                          variant="light"
                          value={transactionId}
                          btnImageAfter={<span className="dst-icon-link-external-01 ms-2" />}
                          clickFunction={() => {
                            window.open(`${process.env.REACT_APP_HUBSPOT_DEAL_URL}/${transactionId}`, "_blank");
                          }}
                        />
                      ))}
                    </div>
                  </Popup>
                </React.Fragment>
              )}
            </div>
            <hr className="my-4" />
            <DstButton
              clickFunction={() => (loginAsFunction ? loginAsFunction() : null)}
              btnClass="me-3"
              value={translate(language, "PAGES.USER.USER_BLOCK.LOG_IN_AS")}
              btnImageBefore={<LogIn />}
            />
            <DstButton
              clickFunction={() => (logoutFunction ? logoutFunction() : null)}
              value={translate(language, "PAGES.USER.USER_BLOCK.LOG_OUT_USER")}
              btnImageBefore={<LogOut />}
              variant="danger"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Placeholder animation="glow">
              <div className="my-3">
                <Placeholder as="div" xs={4} />
              </div>
            </Placeholder>
            <div className="d-flex flex-wrap row-gap-2">
              <DstButton mock btnClass="me-3" btnWidth="66px" />
              <DstButton mock btnClass="me-3" btnWidth="102px" />
              <DstButton mock btnClass="me-3" btnWidth="120px" />
            </div>

            <hr className="my-4" />
            <DstButton mock btnClass="me-3" btnWidth="92px" />
            <DstButton mock btnWidth="112px" />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default UserBlock;
