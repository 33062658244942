import { Badge } from "react-bootstrap";

import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const fetchUsers = (cohortId: string, setCohortUsers: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/users`)
    .then((resp) => {
      setCohortUsers(resp.data.users);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      setCohortUsers([]);
      alert(translate(language, "PAGES.COHORT.USERS.ERROR.FETCH"));
    });
};

export const getUserActive = (user: any, language: string) => {
  const now = Date.now(),
    start = new Date(user.dt_start).getTime(),
    end = new Date(user.dt_end).getTime(),
    isActive = now > start && now < end;
  return (
    <Badge bg={isActive ? "success" : "danger"}>
      {translate(language, `PAGES.COHORT.USERS.IS_ACTIVE.${isActive ? "ACTIVE" : "INACTIVE"}`)}
    </Badge>
  );
};

export const fetchUsersLogtime = (
  cohortId: string,
  cohortUsers: any[],
  setCohortUsers: Function,
  language: string,
  setLoadingLogtime: Function,
  setFilteredData: Function
) => {
  setLoadingLogtime(true);
  ApiService.get(`cohorts/${cohortId}/users/logtime/weekly`)
    .then((resp) => {
      cohortUsers = cohortUsers.map((user: any) => ({
        ...user,
        total_time: resp.data.users_weekly_logtime[String(user.id)],
      }));
      setCohortUsers(cohortUsers);
      setFilteredData(cohortUsers);
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.USERS.ERROR.FETCH_LOGTIME"));
    })
    .finally(() => setLoadingLogtime(false));
};

export const styleUserRow = (user: any): string => {
  if (user.flags.includes("VIP")) {
    return "table-primary";
  } else if (user.flags.includes("Complicated")) {
    return "table-warning";
  }
  return "";
};

export const updateUsers = (users: number[], cohort_id: string, language: string, callback?: Function) => {
  ApiService.put(`cohorts/${cohort_id}/users`, { user_ids: users })
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.USERS.ERROR.UPDATE_USERS"));
    });
};
