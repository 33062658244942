import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";

import DstButton from "../../../../../components/DstButton/DstButton";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { CohortPartnerDiplomaPopupProperties } from "./CohortPartnerDiplomaPopup.d";
import { generatePartnerDiploma } from "./CohortPartnerDiplomaPopup.function";

const CohortPartnerDiplomaPopup = ({
  language,
  cohortId,
  userIds,
  openPartnerDiplomaModal,
  setOpenPartnerDiplomaModal,
}: CohortPartnerDiplomaPopupProperties) => {
  const [isSending, setIsSending] = useState(false);
  const [courseName, setCourseName] = useState<string>("");

  return (
    <Popup
      show={openPartnerDiplomaModal}
      onClose={() => setOpenPartnerDiplomaModal(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.TITLE")}
      scrollable
    >
      <Form id="modal-generate-partner-diploma">
        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.COURSE_NAME_LABEL")}</Form.Label>
          <Form.Control
            className={`border-secondary ${!courseName ? "text-muted" : ""}`}
            type="text"
            placeholder={translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.COURSE_NAME_PLACEHOLDER")}
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
          />
        </Form.Group>
      </Form>
      <Modal.Footer className="pt-5 border-top-0">
        <DstButton
          variant="secondary"
          value={translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.CANCEL_BUTTON")}
          clickFunction={() => {
            setOpenPartnerDiplomaModal(false);
            setCourseName("");
          }}
        />
        <DstButton
          value={translate(language, "PAGES.COHORT.USERS.MODALS.PARTNER_DIPLOMAS.CONFIRM_BUTTON")}
          clickFunction={() =>
            generatePartnerDiploma(language, cohortId, courseName, userIds, setOpenPartnerDiplomaModal, setIsSending)
          }
          loading={isSending}
          disabled={!courseName.trim().length}
        />
      </Modal.Footer>
    </Popup>
  );
};

export default CohortPartnerDiplomaPopup;
