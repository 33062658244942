import { MultiSelectLiveDropdownPropsSelectItems } from "../../components/MultiSelectLiveDropdown/MultiSelectLiveDropdown.d";
import { TemplateMessage } from "../../models/TemplateMessage";
import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { truncateString } from "../../services/UtilityService";
import { AliasItem, ImageObject, Receiver, RecipientType } from "./Messages.d";
import { cheatsheetItems } from "./components/MessagesCheatsheet";

export const RECIPIENT_PAGING_SIZE = 20;

export const messagesRecipientTypes: Array<RecipientType> = [
  { id: 1, name: "B2B", incompatible: [2] },
  { id: 2, name: "B2C", incompatible: [1] },
  { id: 3, name: "French", incompatible: [4] },
  { id: 4, name: "English", incompatible: [3] },
  { id: 5, name: "Active", incompatible: [6] },
  { id: 6, name: "Inactive", incompatible: [5] },
];

export const resumeReceivers = (list: Array<MultiSelectLiveDropdownPropsSelectItems>): string => {
  if (!list.length) return "";
  const categories = list.map((elem) => elem.category);
  let count: any = {};
  categories.forEach((category) => (count[category] = (count[category] ?? 0) + 1));
  return Object.keys(count)
    .map((key) => `${count[key]} ${key}`)
    .join(" & ");
};

export const summarizeReceivers = (list: Array<Receiver>): string => {
  if (!list.length) return "";
  return list.map((item: any) => `${item.name} users`).join(" & ");
};

export const _listReceivers = (language: string, message: any): string => {
  let cohort_receivers = "",
    user_receivers = "";

  if (message.identified_cohorts && message.identified_cohorts.length > 0) {
    cohort_receivers = message.identified_cohorts.map((cohort: any) => cohort.cohort_name).join(", ");
  }
  const receivers = message.receivers;

  if (receivers.length > 2) {
    user_receivers = `${receivers
      .slice(0, 2)
      .map((receiver: any) => receiver.full_name)
      .join(", ")} & ${receivers.length - 2} ${translate(language, "PAGES.MESSAGES.USERS_TAB_TITLE")}`;
  } else {
    user_receivers = receivers.map((receiver: any) => receiver.full_name).join(", ");
  }
  return `${translate(language, "PAGES.MESSAGES.TO_LABEL")} ${cohort_receivers} ${user_receivers}`;
};

export const listItemMessages = (language: string, messages: Array<any>) =>
  messages.map((message) => ({
    author_name: `${translate(language, "PAGES.MESSAGES.FROM_LABEL")} ${message.author.full_name}`,
    name: _listReceivers(language, message),
    content: truncateString(message.content, 100, true),
    fullContent: message.content,
    date: message.dt_created,
    id: message.id,
  }));

export const sendMessage = (
  items: Array<MultiSelectLiveDropdownPropsSelectItems>,
  itemsAlias: Array<AliasItem>,
  images: Array<ImageObject> = [],
  message: string,
  language: string,
  setActiveKey: Function,
  setMessage: Function,
  setMessages: Function,
  setTemplates: Function,
  setLoaded: Function,
  setSelectedItems: Function,
  setSelectedItemsAlias: Function,
  callback: Function
) => {
  const formData = new FormData();
  const cohorts = items?.filter((elem) => elem.category === "cohorts")?.map((elem) => elem.value.id) || [];
  const users = items?.filter((elem) => elem.category === "users")?.map((elem) => elem.value.id) || [];
  const alias = itemsAlias.map((item) => item.name);
  cohorts.forEach((item) => formData.append("cohorts", item));
  users.forEach((item) => formData.append("users", item));
  alias.forEach((item) => formData.append("alias", item));
  images.forEach((item) => {
    if (message.includes(item.element)) {
      formData.append("images", item.file);
      message = message.replace(item.element, `{IMG(${item.file.name})}`);
    }
  });
  formData.append("message", message);
  ApiService.post("messages", formData, {
    "Content-Type": "multipart/form-data",
  })
    .then(() => {
      alert(translate(language, "PAGES.MESSAGES.SEND_RESULT.SUCCESS"));
      setMessage("");
      setSelectedItems([]);
      setSelectedItemsAlias([]);
      setLoaded((loaded: any) => ({ ...loaded, messages: false }));
      loadMessagesAndTemplates(setMessages, setTemplates, setLoaded);
      setActiveKey("message-sent");
      callback();
    })
    .catch((err) => {
      console.error("An error occurred in message send.", err);
      alert(translate(language, "PAGES.MESSAGES.SEND_RESULT.ERROR"));
    });
};

export const loadMessagesAndTemplates = (setMessages: Function, setTemplates: Function, setLoaded: Function) => {
  ApiService.get("messages")
    .then((response) => {
      setMessages(response.data.messages);
      setLoaded((loaded: any) => ({ ...loaded, messages: true }));
    })
    .catch((error) => console.error("An error occurred with messages listing", error));
  ApiService.get("messages/templates")
    .then((response) => {
      setTemplates(response.data.messages_templates);
      setLoaded((loaded: any) => ({ ...loaded, templates: true }));
    })
    .catch((error) => console.error("An error occurred with message templates listing", error));
};

export const deleteTemplate = (
  templateId: number,
  setSelectedTemplate: Function,
  setMessages: Function,
  setTemplates: Function,
  setLoaded: Function,
  language: string
) => {
  ApiService.delete(`messages/templates/${templateId}`)
    .then(() => {
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.SUCCESS_DELETE"));
      setSelectedTemplate(null);
      loadMessagesAndTemplates(setMessages, setTemplates, setLoaded);
    })
    .catch((error) => {
      console.error("Error occurred while deleting the template", error);
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.FAILURE_DELETE"));
    });
};

export const handleAddTemplateSubmit = (
  templateName: string,
  templateContent: string,
  setTemplateName: Function,
  setTemplateContent: Function,
  setIsAddingNewTemplate: Function,
  setSelectedTemplate: Function,
  setShowEditForm: Function,
  loadMessagesAndTemplates: Function,
  setMessages: Function,
  setTemplates: Function,
  setLoaded: Function,
  language: string
) => {
  const payload = {
    name: templateName,
    content: templateContent,
  };

  ApiService.post("messages/templates", payload)
    .then(() => {
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.SUCCESS_ADD"));
      setTemplateName("");
      setTemplateContent("");
      setIsAddingNewTemplate(false);
      setSelectedTemplate(null);
      setShowEditForm(false);
      loadMessagesAndTemplates(setMessages, setTemplates, setLoaded);
    })
    .catch((error) => {
      console.error("Error while adding the template", error);
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.FAILURE_ADD"));
    });
};

export const startAddingNewTemplate = (
  setIsAddingNewTemplate: Function,
  setEditingTemplateId: Function,
  setTemplateName: Function,
  setTemplateContent: Function,
  setShowEditForm: Function,
  setSelectedTemplate: Function,
  language: string
) => {
  setIsAddingNewTemplate(true);
  setEditingTemplateId(null);
  setTemplateName(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.TEMPLATE_INFORMATION.DEFAULT_TITLE"));
  setTemplateContent(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.TEMPLATE_INFORMATION.DEFAULT_CONTENT"));
  setShowEditForm(true);
  setSelectedTemplate(null);
};

export const submitEdit = (
  selectedTemplate: any,
  author: any,
  editingTemplateId: number | null,
  editingTemplateName: string,
  editingTemplateContent: string,
  setSelectedTemplate: Function,
  setShowEditForm: Function,
  loadMessagesAndTemplates: Function,
  setMessages: Function,
  setTemplates: Function,
  setLoaded: Function,
  language: string
) => {
  if (!editingTemplateId) return;

  const payload = {
    name: editingTemplateName,
    content: editingTemplateContent,
  };

  ApiService.patch(`messages/templates/${editingTemplateId}`, payload)
    .then(() => {
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.SUCCESS_UPDATE"));
      setShowEditForm(false);
      const updatedTemplate = {
        ...selectedTemplate,
        name: editingTemplateName,
        content: editingTemplateContent,
        dt_last_updated: new Date().toISOString(),
        author,
      };
      setSelectedTemplate(updatedTemplate);
      loadMessagesAndTemplates(setMessages, setTemplates, setLoaded);
    })
    .catch((error) => {
      console.error("Error while updating the template", error);
      alert(translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.STATUS_NOTIFICATION.FAILURE_UPDATE"));
    });
};

export const handleCancel = (
  setTemplateName: Function,
  setTemplateContent: Function,
  setShowEditForm: Function,
  setIsAddingNewTemplate: Function,
  setEditingTemplateId: Function
) => {
  setTemplateName("");
  setTemplateContent("");
  setShowEditForm(false);
  setIsAddingNewTemplate(false);
  setEditingTemplateId(null);
};

export const selectForEditing = (
  template: TemplateMessage,
  setEditingTemplateId: Function,
  setEditingTemplateName: Function,
  setEditingTemplateContent: Function,
  setShowEditForm: Function,
  setIsAddingNewTemplate: Function
) => {
  setEditingTemplateId(template.id);
  setEditingTemplateName(template.name);
  setEditingTemplateContent(template.content);
  setShowEditForm(true);
  setIsAddingNewTemplate(false);
};

export const handleTemplateSelect = (
  template: TemplateMessage,
  setSelectedTemplate: Function,
  setShowEditForm: Function,
  setEditingTemplateId: Function,
  setEditingTemplateName: Function,
  setEditingTemplateContent: Function,
  setIsAddingNewTemplate: Function
) => {
  setSelectedTemplate(template);
  setShowEditForm(false);
  setEditingTemplateId(template?.id || null);
  setEditingTemplateName(template?.name || "");
  setEditingTemplateContent(template?.content || "");
  setIsAddingNewTemplate(false);
};

export const generateMessagePreview = (
  message: string,
  cohortIds: string[],
  userIds: string[],
  aliases: string[],
  setPreviewMessage: Function,
  callback?: Function
) => {
  const data = {
    message,
    cohorts: cohortIds,
    users: userIds,
    aliases,
  };

  if (
    (cohortIds?.length > 0 || userIds?.length > 0 || aliases?.length > 0) &&
    cheatsheetItems.some((Placeholder) => message.includes(Placeholder))
  ) {
    ApiService.post("messages/preview", data)
      .then((resp) => {
        setPreviewMessage(Object.entries(resp.data.message).map(([key, content]) => ({ key, content })));
        callback?.();
      })
      .catch((error) => {
        console.error("Error in preview message:", error);
      });
  } else {
    setPreviewMessage(message);
    callback?.();
  }
};
