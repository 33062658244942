import React, { useEffect, useMemo, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import Search from "../../../../components/DstIcons/Search";
import Lister from "../../../../components/Lister/Lister";
import { ListItem } from "../../../../components/Lister/Lister.d";
import ListerSearch from "../../../../components/Lister/ListerSearch/ListerSearch";
import Popup from "../../../../components/Popup/Popup";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { RECIPIENT_PAGING_SIZE, fetchMessages, listItemMessages } from "./CohortMessages.function";

const CohortMessages = ({ cohortId, language }: { cohortId: string; language: string }) => {
  const [messages, setMessages] = useState<Array<any>>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [authorFilter] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [recipientsVisibleCount, setRecipientsVisibleCount] = useState(RECIPIENT_PAGING_SIZE);
  const [popupMessage, setPopupMessage] = useState<{
    author: any;
    content: string;
    receivers: any[];
    parameters: Record<string, string | string[]>;
    identified_cohorts: any[];
  }>({
    author: null,
    content: "",
    receivers: [],
    parameters: {},
    identified_cohorts: [],
  });

  useEffect(() => {
    fetchMessages(cohortId, setMessages, language, () => {
      setLoaded(true);
    });
  }, [cohortId, language]);

  const handleOpenLink = (key: string, value: string) => {
    const modifiedKey = key.endsWith("s") ? key.slice(0, -1) : key;

    if (key !== "aliases") {
      return () => window.open(`/${modifiedKey}/${value}`);
    }
    return undefined;
  };

  const openMessagePopup = (messageId: number) => {
    const message = messages.find((message) => message.id === messageId);

    if (message) {
      const { author, content, receivers, parameters, identified_cohorts } = message;
      setPopupMessage({
        author,
        content,
        receivers,
        parameters: JSON.parse(
          parameters.replace(/('(?=(,\s*')))|('(?=:))|((?<=([:,[]\s*))')|((?<={)')|('(?=]|}))/g, '"')
        ),
        identified_cohorts: identified_cohorts || [],
      });
      setShowPopup(true);
    }
  };

  const columns = [
    { header: translate(language, "PAGES.COHORT.MESSAGES.AUTHOR_HEADER"), key: "author_name" },
    { header: translate(language, "PAGES.COHORT.MESSAGES.RECIPIENTS_HEADER"), key: "name" },
    { header: translate(language, "PAGES.COHORT.MESSAGES.CONTENT_HEADER"), key: "content" },
    {
      header: translate(language, "PAGES.COHORT.MESSAGES.CREATION_DATE_HEADER"),
      key: "date",
      render: (item: any) => (
        <div>
          <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
            <span>{formatDateHumanly(language, item["date"])}</span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const filteredItems: ListItem[] = useMemo(() => {
    const filteredByAuthor = listItemMessages(
      language,
      messages.filter((message) => [-1, message.author.id].includes(authorFilter))
    );

    return filteredByAuthor.filter((item) =>
      Object.values(item).join(" ").toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [language, messages, authorFilter, searchQuery]);

  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="me-3 align-content-center mb-0">{translate(language, "PAGES.COHORT.MESSAGES.TITLE")}</h2>
          <ListerSearch setSearchQuery={setSearchQuery} mock={!loaded} />
        </div>
        <DstButton
          variant="light"
          value={translate(language, "PAGES.COHORT.MESSAGES.BUTTON_REFRESH")}
          btnImageBefore={<span className="dst-icon-refresh-cw-04 pe-2 align-text-bottom" />}
          mock={!loaded}
          btnWidth="100px"
          clickFunction={() => {
            setLoaded(true);
            fetchMessages(cohortId, setMessages, language, () => {
              setLoaded(false);
            });
          }}
        />
      </div>
      {loaded && messages.length === 0 ? (
        <div className="mt-3 text-muted">{translate(language, "PAGES.COHORT.MESSAGES.NO_MESSAGE_FOUND")}</div>
      ) : (
        <React.Fragment>
          <Lister
            items={filteredItems}
            columns={columns}
            mock={!loaded}
            showHeader
            additionalButton={(item: any) => (
              <span className="clickable xs-regular" onClick={() => openMessagePopup(item.id)}>
                <Search classes="search-icon m-regular" />
              </span>
            )}
            pagination
          />
        </React.Fragment>
      )}
      {showPopup && (
        <Popup
          show={showPopup}
          onClose={() => {
            setShowPopup(false);
            setRecipientsVisibleCount(20);
          }}
          modalTitle={translate(language, "PAGES.COHORT.MESSAGES.MODAL.TITLE")}
          size="xl"
          fullscreen="lg-down"
          scrollable
        >
          <div>
            <h4>{translate(language, "PAGES.COHORT.MESSAGES.MODAL.AUTHOR")}</h4>
            <DstButton
              btnClass="me-2 mb-3"
              variant="secondary"
              clickFunction={() => window.open(`/user/${popupMessage.author.id}`, "_blank")}
              value={popupMessage.author.email}
              btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
            />
            {popupMessage.receivers && (
              <React.Fragment>
                <h4>{`${translate(language, "PAGES.COHORT.MESSAGES.MODAL.RECIPIENTS")} (${
                  popupMessage.receivers.length
                })`}</h4>
                <div>
                  {popupMessage.identified_cohorts?.map((cohort: any, index: number) => (
                    <DstButton
                      btnClass="me-2"
                      variant="secondary"
                      key={`cohort-${index}`}
                      clickFunction={() => window.open(`/cohort/${cohort.id}`, "_blank")}
                      value={cohort.name}
                      btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
                    />
                  ))}
                  {popupMessage.identified_cohorts.length > 0 && popupMessage.receivers.length > 0 && ", "}
                  {popupMessage.receivers.slice(0, recipientsVisibleCount).map((receiver: any, index: number) => (
                    <DstButton
                      key={`receiver-${index}`}
                      btnClass="me-2 my-2"
                      variant="secondary"
                      value={receiver.email}
                      btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
                      clickFunction={() => window.open(`/user/${receiver.id}`, "_blank")}
                    />
                  ))}
                  {popupMessage.receivers.length > recipientsVisibleCount && (
                    <Button
                      variant="link"
                      onClick={() => setRecipientsVisibleCount((prevCount) => prevCount + 20)}
                      className="p-0"
                    >
                      {translate(language, "PAGES.COHORT.MESSAGES.MODAL.MORE_BUTTON")}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
            {popupMessage.parameters && Object.keys(popupMessage.parameters).length > 0 && (
              <React.Fragment>
                <h4 className="mt-3">{translate(language, "PAGES.COHORT.MESSAGES.MODAL.IN_DETAIL")}</h4>
                <div>
                  {Object.keys(popupMessage.parameters)
                    .filter((key) => ["users", "cohorts", "aliases", "meetings"].includes(key))
                    .map((key) => (
                      <div key={key} className="d-flex">
                        <p className="me-2 d-flex align-items-center">{`${key.charAt(0).toUpperCase()}${key.slice(
                          1
                        )}:`}</p>
                        {(popupMessage.parameters[key] as string).split(",").map((value: string, index: number) => (
                          <DstButton
                            btnClass="me-2 mb-3"
                            variant="secondary"
                            clickFunction={handleOpenLink(key, value)}
                            key={`${key}-${value}-${index}`}
                            value={value}
                            btnImageAfter={<i className="dst-icon-link-external-02 ms-1"></i>}
                          />
                        ))}
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
            <h4>{translate(language, "PAGES.COHORT.MESSAGES.MODAL.CONTENT")}</h4>
            <div className="p-3 megium-gray-background border-rounded">
              <div id="message-popup-content" dangerouslySetInnerHTML={{ __html: popupMessage.content }} />
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default CohortMessages;
