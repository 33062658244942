import { useState } from "react";

import DstButton from "../../../../components/DstButton/DstButton";
import MeetingBlock from "../../../../components/MeetingBlock/MeetingBlock";
import translate from "../../../../services/Translate";

const _meetingTypeNamePrettier = (str: string) => str.replace(/[-_]/gm, " ");

const UserMeetings = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
}): JSX.Element => {
  const [filterType, setFilterType] = useState("");
  const [sortHistoryTime, setSortHistoryTime] = useState<"asc" | "desc">("desc");
  const meetingTypes: Array<string> = ["All"].concat(
    Array.from(new Set(user_data?.user_meetings?.map((meeting: any) => meeting.meeting_type)))
  );

  return (
    <div className="w-100 px-3">
      <div className="mb-3">
        {loaded ? (
          meetingTypes.map((type: string) => (
            <DstButton
              key={type}
              variant="info"
              clickFunction={() => setFilterType(type === "All" ? "" : type)}
              btnClass={`text-capitalize me-2 ${
                filterType === type || (filterType === "" && type === "All") ? "active-filter-btn" : ""
              }`}
              value={_meetingTypeNamePrettier(type)}
            />
          ))
        ) : (
          <div className="d-flex">
            <DstButton mock btnWidth="41px" btnClass="me-2" />
            <DstButton mock btnWidth="90px" />
          </div>
        )}
      </div>
      <h2>{translate(language, "PAGES.USER.MEETING.UPCOMING_TITLE")}</h2>
      {loaded ? (
        user_data.user_meetings.filter(
          (meeting: any) => !!meeting.is_future && ["", meeting.meeting_type].includes(filterType)
        ).length > 0 ? (
          user_data.user_meetings
            .filter((meeting: any) => !!meeting.is_future && ["", meeting.meeting_type].includes(filterType))
            .map((meeting: any, index: number) => (
              <MeetingBlock key={`future-meeting-${index}`} {...meeting} language={language} />
            ))
        ) : (
          <p className="gray-font py-2">{translate(language, "PAGES.USER.MEETING.NO_MEETING_CORRESPONDING")}</p>
        )
      ) : (
        [...Array(3)].map((_elem, index) => (
          <MeetingBlock key={`mock-future-meeting-${index}`} mock={true} language={language} />
        ))
      )}

      <h2>
        {translate(language, "PAGES.USER.MEETING.PAST_TITLE")}
        <DstButton
          btnClass="float-end"
          variant="secondary"
          clickFunction={() => setSortHistoryTime((sort) => (sort === "desc" ? "asc" : "desc"))}
          btnImageBefore={
            <i
              className={`align-text-bottom pe-2 dst-icon-arrow-narrow-${sortHistoryTime === "asc" ? "down" : "up"}`}
            ></i>
          }
          value={translate(language, "PAGES.USER.MEETING.CHRONOLOGICAL_BUTTON_TEXT")}
        />
      </h2>
      {loaded ? (
        user_data.user_meetings.filter(
          (meeting: any) => !meeting.is_future && ["", meeting.meeting_type].includes(filterType)
        ).length > 0 ? (
          user_data.user_meetings
            .filter((meeting: any) => !meeting.is_future && ["", meeting.meeting_type].includes(filterType))
            .sort((a: any, b: any) => b.start_time.getTime() - a.start_time.getTime() * +(sortHistoryTime === "desc"))
            .map((meeting: any, index: number) => (
              <MeetingBlock key={`past-meeting-${index}`} {...meeting} language={language} />
            ))
        ) : (
          <p className="gray-font py-2">{translate(language, "PAGES.USER.MEETING.NO_MEETING_CORRESPONDING")}</p>
        )
      ) : (
        [...Array(3)].map((_elem, index) => (
          <MeetingBlock key={`mock-past-meeting-${index}`} mock={true} language={language} />
        ))
      )}
    </div>
  );
};

export default UserMeetings;
