import React, { useEffect, useMemo, useState } from "react";
import { Placeholder } from "react-bootstrap";

import ApiService from "../../services/ApiService";
import DstButton from "../DstButton/DstButton";
import ChevronsUpDown from "../DstIcons/ChevronsUpDown";
import { LiveListerProps } from "./Lister.d";
import "./Lister.scss";

const _requestSort = (chosenKey: string, setSortOrder: Function, setSortKey: Function) => {
  setSortKey((previouslyChosenKey: string) => {
    if (previouslyChosenKey === chosenKey) {
      setSortOrder((previousOrder: string) => (previousOrder === "asc" ? "desc" : "asc"));
      return previouslyChosenKey;
    } else {
      setSortOrder("desc");
      return chosenKey;
    }
  });
};

const RemoteLister = ({
  additionalButton,
  buttonClass,
  buttonElementColor,
  columns,
  handleRowClick,
  headerClass,
  itemsPerPage = 10,
  pagination = false,
  paginationAlwaysShowFooter = false,
  remote,
  remoteKey,
  remoteTransform,
  showHeader = true,
}: LiveListerProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");
  const [loaded, setLoaded] = useState(false);
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [paginationInfos, setPaginationInfos] = useState({
    current_page: 0,
    current_size: 0,
    total_pages: 0,
    total_records: 0,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [remote, sortKey, sortOrder]);

  useEffect(() => {
    setItemsLoaded(false);
    ApiService.get(
      `${remote}${remote.includes("?") ? "&" : "?"}order_by=${sortKey}&order=${sortOrder}&page=${
        currentPage - 1
      }&limit=${itemsPerPage}`
    )
      .then((resp) => {
        const paginatedReturn = resp.data[remoteKey];
        setItems(paginatedReturn.items);
        setPaginationInfos(paginatedReturn.pagination);
      })
      .catch((err) => {
        console.error(err);
        setItems([]);
      })
      .finally(() => {
        setLoaded(true);
        setItemsLoaded(true);
      });
  }, [currentPage, itemsPerPage, remote, remoteKey, sortKey, sortOrder]);

  const pageButtons = useMemo(() => {
    const maxButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, paginationInfos.total_pages);

    if (endPage === paginationInfos.total_pages) {
      startPage = Math.max(paginationInfos.total_pages - maxButtons + 1, 1);
    }

    return Array.from({ length: Math.min(maxButtons, paginationInfos.total_pages) }, (_, i) => startPage + i);
  }, [currentPage, paginationInfos]);

  if (!loaded) {
    return (
      <React.Fragment>
        <table className="list-container table hover responsive lister-table">
          {showHeader && (
            <thead>
              <tr className="m-bold">
                {columns.map((column) => (
                  <th key={column.key} className="header-item">
                    {column.header} {column.sortable && <ChevronsUpDown />}
                  </th>
                ))}
                {additionalButton && <th></th>}
              </tr>
            </thead>
          )}
          <tbody>
            {[...Array(5)].map((_elem, index) => (
              <Placeholder key={`mock-placeholder-${index}`} animation="glow" as="tr" className="list-row">
                {columns.map((column) => (
                  <td key={`mock-cell-${index}-${column.key}`} className={`row-item ${column.size || "s-regular"}`}>
                    <Placeholder as="span" xs={12} />
                  </td>
                ))}
              </Placeholder>
            ))}
          </tbody>
        </table>
        <div>
          {(paginationInfos.total_pages > 1 || paginationAlwaysShowFooter) && pagination && (
            <div className="d-flex mw-100 justify-content-center align-items-center m-3">
              <DstButton btnClass={`${buttonClass} me-1`} btnWidth="30px" value="" mock />
              <DstButton btnClass={`${buttonClass} me-1`} btnWidth="30px" value="" mock />
              {pageButtons.map((page) => (
                <DstButton btnClass={`${buttonClass} ms-1`} btnWidth="30px" key={page} value="" mock />
              ))}
              <DstButton btnClass={`${buttonClass} ms-2`} btnWidth="30px" value="" mock />
              <DstButton btnClass={`${buttonClass} ms-1`} btnWidth="30px" value="" mock />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <table className="list-container table hover responsive lister-table">
          {showHeader && (
            <thead>
              <tr className="m-bold">
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className={`${headerClass} header-item clickable`}
                    onClick={() => (column.sortable ? _requestSort(column.key, setSortOrder, setSortKey) : null)}
                  >
                    {column.header} {column.sortable && <ChevronsUpDown />}
                  </th>
                ))}
                {additionalButton && <th></th>}
              </tr>
            </thead>
          )}
          <tbody>
            {itemsLoaded
              ? items
                  .map((item: any) => (remoteTransform ? remoteTransform(item) : item))
                  .map((item, index) => (
                    <tr
                      key={index}
                      className={`list-row ${handleRowClick ? "clickable" : ""}`}
                      onClick={() => handleRowClick && handleRowClick(item)}
                    >
                      {columns.map((column) => (
                        <td key={column.key} className={`row-item ${column.size || "s-regular"} ${column.color || ""}`}>
                          {column.render ? column.render(item) : item[column.key]}
                        </td>
                      ))}
                      {additionalButton && <td>{additionalButton(item)}</td>}
                    </tr>
                  ))
              : [...Array(itemsPerPage)].map((_elem, index) => (
                  <Placeholder key={`mock-placeholder-${index}`} animation="glow" as="tr" className="list-row">
                    {columns.map((column) => (
                      <td key={`mock-cell-${index}-${column.key}`} className={`row-item ${column.size || "s-regular"}`}>
                        <Placeholder as="span" xs={12} />
                      </td>
                    ))}
                  </Placeholder>
                ))}
          </tbody>
        </table>
        {(paginationInfos.total_pages > 1 || paginationAlwaysShowFooter) && pagination && (
          <div className="pagination-controls d-flex justify-content-center align-items-center m-3">
            <React.Fragment>
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-left-double me-1`}
                color={buttonElementColor}
                clickFunction={() => setCurrentPage(1)}
                value=""
                disabled={currentPage === 1}
              />
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-left me-1`}
                color={buttonElementColor}
                value=""
                disabled={currentPage < 4}
                clickFunction={() => setCurrentPage(Math.max(currentPage - 5, 1))}
              />
              {pageButtons.map((page) => (
                <DstButton
                  btnClass={`${buttonClass} button-lister ms-1`}
                  color={buttonElementColor}
                  key={page}
                  value={page.toString()}
                  variant={currentPage === page ? "primary" : "secondary"}
                  clickFunction={() => setCurrentPage(page)}
                />
              ))}
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-right ms-2`}
                value=""
                color={buttonElementColor}
                disabled={currentPage > paginationInfos.total_pages - 3}
                clickFunction={() => setCurrentPage(Math.min(currentPage + 5, paginationInfos.total_pages))}
              />
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-right-double ms-1`}
                clickFunction={() => setCurrentPage(paginationInfos.total_pages)}
                value=""
                color={buttonElementColor}
                disabled={currentPage === paginationInfos.total_pages}
              />
              {itemsLoaded ? (
                <p className="ms-3 mb-0 s-regular">
                  <span>
                    {(currentPage - 1) * itemsPerPage} -{" "}
                    {(currentPage - 1) * itemsPerPage + paginationInfos.current_size} / {paginationInfos.total_records}
                  </span>
                </p>
              ) : (
                <Placeholder as="div" className="ms-3 row">
                  <Placeholder as="span" xs={3} />
                  /
                  <Placeholder as="span" xs={3} />
                </Placeholder>
              )}
            </React.Fragment>
          </div>
        )}
      </React.Fragment>
    );
  }
};

export default RemoteLister;
