import React from "react";
import { Tab, Tabs } from "react-bootstrap";

import { DstTabsProps } from "../../models/TabModel";
import "./DstTabs.scss";

const DstTabs = ({
  tabsData,
  variant,
  classes,
  activeKey,
  onSelect,
}: DstTabsProps & { activeKey: string; onSelect: (k: string | null) => void }) => {
  return (
    <div className={`d-block ${classes}`}>
      <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onSelect={onSelect} className={`mb-3 ${variant}`}>
        {tabsData.map((tab) => (
          <Tab eventKey={tab.id} title={tab.title} key={tab.id}>
            {tab.content}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default DstTabs;
