import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const deleteUserModules = (userId: any, moduleId: string, language: string, callback?: Function) => {
  ApiService.delete(`users/${userId}/modules/${moduleId}`)
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error("Error deleting module", error);
      alert(translate(language, "PAGES.USER.OVERVIEW.MODULE.ALERTS.ERROR"));
    });
};

export const unlinkUserModules = (userId: any, moduleId: string, language: string, callback?: Function) => {
  ApiService.delete(`users/${userId}/modules/${moduleId}/link`)
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error("Error deleting module", error);
      alert(translate(language, "PAGES.USER.OVERVIEW.MODULE.ALERTS.ERROR"));
    });
};
