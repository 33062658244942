import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { useSession } from "../../../contexts/SessionContext";
import translate from "../../../services/Translate";
import { getExamStatusStyle } from "../Correction.function";

const ExamStatusDropdown = ({
  onStatusChange,
  initialStatus,
}: {
  onStatusChange: (status: string) => void;
  initialStatus?: string;
}) => {
  const { language } = useSession();
  const [examStatus, setExamStatus] = useState(initialStatus || "—");
  const examStatuses = [
    { key: "success", label: "PAGES.EVAL.EXAM_STATUS.SUCCESS" },
    { key: "repass", label: "PAGES.EVAL.EXAM_STATUS.REPASS" },
    { key: "failed", label: "PAGES.EVAL.EXAM_STATUS.FAIL" },
  ];

  useEffect(() => {
    if (initialStatus) {
      setExamStatus(initialStatus);
    }
  }, [initialStatus]);

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setExamStatus(eventKey);
      onStatusChange(eventKey);
    }
  };
  const currentStatusLabel = examStatuses.find((status) => status.key === examStatus)?.label;
  return (
    <div className="mt-2">
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant={getExamStatusStyle(examStatus)}>
          {translate(language, "PAGES.EVAL.EXAM_STATUS.TITLE")}{" "}
          {currentStatusLabel ? translate(language, currentStatusLabel) : examStatus.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {examStatuses.map((status) => (
            <Dropdown.Item key={status.key} eventKey={status.key}>
              {translate(language, status.label)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default ExamStatusDropdown;
