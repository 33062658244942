import ApiService from "../../services/ApiService";

export const searchFromDate = (chosenDate: string, setChosenDateCount: Function, setChosenDateLoaded: Function) => {
  setChosenDateLoaded(false);
  const date = new Date(chosenDate);
  ApiService.get(`users/active?from=${date.toISOString().split("T")[0].replace(/-/g, "/")}`)
    .then((resp) => setChosenDateCount(resp.data.active_users))
    .catch((err) => console.error(err))
    .finally(() => setChosenDateLoaded(true));
};
