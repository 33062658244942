import { ExamStatus } from "../models/Exam";

export const EXAM_STATUS_ICON = {
  [ExamStatus.SUCCESS]: <i className="dst-icon-check text-success me-2" />,
  [ExamStatus.REPASS]: <i className="dst-icon-x-close text-danger me-2" />,
  [ExamStatus.FAILED]: <i className="dst-icon-x-close text-danger me-2" />,
  [ExamStatus.PRE_CORRECTED]: <i className="dst-icon-cpu-chip-01 orange-font me-2" />,
  default: <i className="dst-icon-hourglass-01 me-2" />,
};

export const getExamStatusIcon = (status: ExamStatus) => {
  return (EXAM_STATUS_ICON as any)[status] || EXAM_STATUS_ICON.default;
};

export const getStatusColor = (status: string): string => {
  let statusColor = "";

  switch (status.toLowerCase()) {
    case "failed":
    case "repass":
      statusColor = "text-danger";
      break;
    case "success":
      statusColor = "text-success";
      break;
    case "correction_pending":
    case "pre_corrected":
      statusColor = "text-warning";
      break;
    case "being_corrected":
    case "pre_correction_in_progress":
      statusColor = "text-primary";
      break;
    case "created":
    case "in_progress":
      statusColor = "text-secondary";
      break;
    default:
      statusColor = "";
  }

  return statusColor;
};

export const normalizeStatus = (status: string): ExamStatus | undefined => {
  return Object.values(ExamStatus).find((s) => s === status.toLowerCase()) as ExamStatus | undefined;
};
