import React from "react";
import { Offcanvas } from "react-bootstrap";

import DstButton from "../../../components/DstButton/DstButton";
import translate from "../../../services/Translate";
import { CheatsheetOffCanvasProps } from "./MessagesCheatsheet.d";

export const cheatsheetItems = [
  "$FULL_NAME",
  "$FIRST_NAME",
  "$LAST_NAME",
  "$COHORT_LEADER_EMAIL",
  "$COHORT_LEADER_FULL_NAME",
  "$CURRENT_SPRINT_END_DATE",
  "$CURRENT_SPRINT_NAME",
];
export const restrictedPlaceholders = [
  "$COHORT_LEADER_EMAIL",
  "$COHORT_LEADER_FULL_NAME",
  "$CURRENT_SPRINT_END_DATE",
  "$CURRENT_SPRINT_NAME",
];

export const CheatsheetOffCanvas = ({ show, onHide, language }: CheatsheetOffCanvasProps) => (
  <Offcanvas show={show} onHide={onHide} scroll={true} backdrop={false} placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>{translate(language, "PAGES.MESSAGES.CHEATSHEET.TITLE")}</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <div className="d-flex flex-column">
        {cheatsheetItems.map((item, index) => (
          <div key={`cheatsheet-item-${index}`} className="mb-3">
            <DstButton
              btnClass="me-2 my-2"
              variant="secondary"
              value={item}
              btnImageAfter={<i className="dst-icon-copy-01 ms-1"></i>}
              clickFunction={() => navigator.clipboard.writeText(item)}
            />
            <div className="text-muted">{translate(language, `PAGES.MESSAGES.CHEATSHEET.${item.slice(1)}`)}</div>
          </div>
        ))}
      </div>
    </Offcanvas.Body>
  </Offcanvas>
);
