import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";

import { useSession } from "../../../contexts/SessionContext";
import translate from "../../../services/Translate";
import { getNestedProperty } from "../../../services/UtilityService";
import { DstTableSearchProps } from "./DstTableSearch.d";

const DstTableSearch = ({ data, searchableColumns, onSearch }: DstTableSearchProps) => {
  const { language } = useSession();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const findColumnRender = (key: string, item: any) => {
    const column = item.columns?.find((col: any) => col.key === key);
    return column?.render || null;
  };

  const getSearchableValue = useCallback((item: any, key: string) => {
    const render = findColumnRender(key, item);
    if (render) {
      const renderedContent = render(item);
      if (typeof renderedContent === "string") {
        return renderedContent.toLowerCase();
      }
      if (typeof renderedContent === "object" && renderedContent?.props?.["data-search-value"]) {
        return renderedContent?.props?.["data-search-value"].toString().toLowerCase();
      }
    }
    return getNestedProperty(item, key)?.toString().toLowerCase();
  }, []);

  const filterItems = useCallback(
    (items: any[], term: string) => {
      if (!term) return items;
      return items.filter((item) =>
        searchableColumns.some((key) => {
          const value = getSearchableValue(item, key);
          return value?.includes(term.toLowerCase());
        })
      );
    },
    [searchableColumns, getSearchableValue]
  );

  const filteredData = useMemo(() => filterItems(data, searchTerm), [data, searchTerm, filterItems]);

  useEffect(() => {
    onSearch(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <Form.Group controlId="search">
      <Form.Control
        type="text"
        placeholder={translate(language, "COMPONENTS.DST_TABLE.SEARCH.PLACEHOLDER_NAME")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </Form.Group>
  );
};

export default DstTableSearch;
