import axios from "axios";
import { ResponseType } from "axios";
import { Socket, io } from "socket.io-client";

import translate from "./Translate";

const _refreshToken = () => {
  const refresh_token = localStorage.getItem("nexus-refresh");

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refresh_token}`,
          },
          withCredentials: true,
        }
      )
      .then((response: any) => {
        const { data } = response;
        localStorage.setItem("nexus-access", data.access_token);
        resolve(data.access_token);
      })
      .catch(async (err: any) => {
        console.error("Error in _refreshToken", err);
        if ([401, 422].includes(err?.response?.status)) {
          reject("Forbidden");
        } else if (err.response === undefined) {
          reject("Connection lost");
        } else {
          reject(err.response);
        }
      });
  });
};

const _localLogout = () => {
  if (localStorage.getItem("nexus-access")) {
    localStorage.removeItem("nexus-access");
    localStorage.removeItem("nexus-refresh");
    alert(translate(localStorage.getItem("nexus-language") || "en-US", "ERROR.RELOG"));
    document.location.href = "/login";
  }
};

class ApiService {
  get(path: string, retry = false): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/${path}`, {
          headers: access_token
            ? {
                Authorization: `Bearer ${access_token}`,
              }
            : {},
        })
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in get ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  await _refreshToken();
                  resolve(this.get(path, true));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                if (err?.response?.data?.error?.message === "User not active.") {
                  _localLogout();
                } else {
                  reject("Forbidden");
                }
              }
            } catch (err) {
              console.error(err);
              reject("Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject("Connection lost");
          } else {
            console.error(err);
            reject(err.response);
          }
        });
    });
  }

  post(
    path: string,
    body: any,
    headers?: any,
    retry = false,
    withCredentials = false,
    detailedAnswer = false
  ): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");
    const request_headers = {
      headers: headers || {},
      withCredentials,
    };

    if (access_token) {
      request_headers.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/${path}`, body, request_headers)
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in post ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  const new_token = await _refreshToken();
                  // Update body with new access_token if refresh and access_token in body
                  if (body.access_token) {
                    body.access_token = new_token;
                  }
                  resolve(this.post(path, body, headers, true, withCredentials));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                reject(detailedAnswer ? err : "Forbidden");
              }
            } catch (err) {
              console.error(err);
              reject(detailedAnswer ? err : "Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject(detailedAnswer ? err : "Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

  postDownload(
    path: string,
    body: any,
    headers?: any,
    retry = false,
    withCredentials = false,
    detailedAnswer = false
  ): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");
    const request_headers = {
      headers: headers || {},
      responseType: "arraybuffer" as ResponseType,
      withCredentials,
    };

    if (access_token) {
      request_headers.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/${path}`, body, request_headers)
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in post ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  const new_token = await _refreshToken();
                  // Update body with new access_token if refresh and access_token in body
                  if (body.access_token) {
                    body.access_token = new_token;
                  }
                  resolve(this.post(path, body, headers, true, withCredentials));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                reject(detailedAnswer ? err : "Forbidden");
              }
            } catch (err) {
              console.error(err);
              reject(detailedAnswer ? err : "Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject(detailedAnswer ? err : "Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

  patch(path: string, body: any, headers?: any, retry = false): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");
    let request_headers = {
      headers: headers || {},
    };

    if (access_token) {
      request_headers.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/${path}`, body, request_headers)
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in patch ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  const new_token = await _refreshToken();
                  // Update body with new access_token if refresh and access_token in body
                  if (body.access_token) {
                    body.access_token = new_token;
                  }
                  resolve(this.patch(path, body, headers, true));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                reject("Forbidden");
              }
            } catch (err) {
              console.error(err);
              reject("Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject("Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

  put(path: string, body: any, headers?: any, retry = false): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");
    let request_headers = {
      headers: headers || {},
    };

    if (access_token) {
      request_headers.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.REACT_APP_API_URL}/${path}`, body, request_headers)
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in put ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  const new_token = await _refreshToken();
                  // Update body with new access_token if refresh and access_token in body
                  if (body.access_token) {
                    body.access_token = new_token;
                  }
                  resolve(this.put(path, body, headers, true));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                reject("Forbidden");
              }
            } catch (err) {
              console.error(err);
              reject("Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject("Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

  delete(path: string, headers?: any, retry = false): Promise<any> {
    const access_token = localStorage.getItem("nexus-access");
    let request_headers = {
      headers: headers || {},
    };

    if (access_token) {
      request_headers.headers["Authorization"] = `Bearer ${access_token}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/${path}`, request_headers)
        .then((data: any) => resolve(data))
        .catch(async (err: any) => {
          console.error(`Error in put ${path}`, err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              if (!retry && access_token) {
                try {
                  await _refreshToken();
                  resolve(this.delete(path, headers, true));
                } catch (err) {
                  console.error(err);
                  _localLogout();
                }
              } else {
                reject("Forbidden");
              }
            } catch (err) {
              console.error(err);
              reject("Unknown error occurred");
            }
          } else if (err.response === undefined) {
            reject("Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

  createSocket(namespace?: string): Socket<any> {
    const access_token = localStorage.getItem("nexus-access");
    const ws = io(
      `${process.env.REACT_APP_API_URL?.replace("http", "ws")}${namespace ? `/${namespace}` : ""}?token=${access_token}`
    );

    return ws;
  }
}

const apiService = new ApiService();

export default apiService;
