import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";

const Home = () => {
  const { language } = useSession();

  return (
    <div className="p-md-5 p-3">
      <h1>{translate(language, "PAGES.HOME.TITLE")}</h1>
      <p>{translate(language, "PAGES.HOME.CONTENT")}</p>
    </div>
  );
};

export default Home;
