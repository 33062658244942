const ORDER = {
  ascending: 1,
  descending: -1,
};

export const sortDate = (firstElement: any, secondElement: any, direction: "ascending" | "descending") => {
  const dateA = new Date(firstElement).getTime();
  const dateB = new Date(secondElement).getTime();
  return (dateA - dateB) * ORDER[direction];
};

export const sortNumber = (firstElement: any, secondElement: any, direction: "ascending" | "descending") => {
  const difference = +firstElement - +secondElement;
  return difference * ORDER[direction];
};

export const sortString = (firstElement: any, secondElement: any, direction: "ascending" | "descending") => {
  const difference = firstElement.localeCompare(secondElement);
  return difference * ORDER[direction];
};
