import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Permission } from "../models/Permissions";
import Session from "../models/Session";
import ApiService from "../services/ApiService";
import translate from "../services/Translate";

export const SessionContext = React.createContext(new Session());

export const SessionProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const navigate = useNavigate();

  const handleLogin = async (email: string, password: string) => {
    try {
      const { data } = await ApiService.post("/auth/login/nexus", { email, password }, null, false, true, true);
      localStorage.setItem("nexus-access", data.return["access_token"]);
      localStorage.setItem("nexus-refresh", data.return["refresh_token"]);
      setValue({ ...value, logged: true });
      const url = new URLSearchParams(window.location.search);
      const origin = url.get("next") || "/";
      navigate(origin);
    } catch (err: any) {
      const language = localStorage.getItem("nexus-language") || "en-US";

      switch (err?.response?.data?.error?.message) {
        case "User not active.":
          const dt_end = err.response.data.error.solution
            .match(/dt_end:([0-9]+)/gm)
            .map((elem: string) => +elem.split(":")[1]);
          const now = ~~(Date.now() / 1000);

          if (now > dt_end) {
            alert(translate(language, "PAGES.LOGIN.EXPIRED_SESSION"));
          } else {
            alert(translate(language, "PAGES.LOGIN.INACTIVE_SESSION"));
          }
          break;
        case "Incorrect username or password.":
          alert(translate(language, "PAGES.LOGIN.INCORRECT_CREDENTIALS"));
          break;
        default:
          console.error(err?.data?.error?.message);
          alert(translate(language, "ERROR.API_ERROR"));
      }
    }
  };

  const handleLogout = async () => {
    try {
      let access_token = localStorage.getItem("nexus-access"),
        refresh_token = localStorage.getItem("nexus-refresh");
      await ApiService.post("auth/logout", { access_token, refresh_token });
      localStorage.removeItem("nexus-access");
      localStorage.removeItem("nexus-refresh");
      setValue({ ...value, logged: false });
      navigate("/login");
    } catch (err: any) {
      alert(err === "Forbidden" ? "Unknown error" : err?.data?.error?.message || "Unknown error");
    }
  };

  const handleSocket = () => {
    const websocket = ApiService.createSocket("notifications");

    websocket.on("connect", () => {
      if (process.env.NODE_ENV === "development") {
        console.info("Websocket connected");
      }
    });
    websocket.on("disconnect", () => {
      if (process.env.NODE_ENV === "development") {
        console.info("Websocket disconnected");
      }
    });
    websocket.on("message", (event: any) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Message received", event);
      }
    });
  };

  const fetchUserData = () => {
    ApiService.get("users/me")
      .then((resp: any) => {
        if (!localStorage.getItem("nexus-language")) {
          localStorage.setItem("nexus-language", resp.data.user.language);
        }
        let userPermissions: Permission[] = [];
        try {
          const parsedUserPermissions = JSON.parse(resp.data.user.role.routes);
          userPermissions = Object.keys(parsedUserPermissions).map((route: string) => ({
            route: route,
            methods: parsedUserPermissions[route],
          }));
        } catch (err) {
          console.error("An error occurred with user permissions malformatted. Considering none permission.", err);
        }
        setValue(
          (value) =>
            new Session({
              ...value,
              first_name: resp.data.user.first_name,
              last_name: resp.data.user.last_name,
              email: resp.data.user.email,
              language: localStorage.getItem("nexus-language") || resp.data.user.language,
              username: `${resp.data.user.first_name} ${resp.data.user.last_name}`,
              image_url: resp.data.user.image_url,
              role: resp.data.user.role.name,
              permissions: userPermissions,
              loaded: true,
              user_id: resp.data.user.id,
            })
        );
      })
      .catch((err: any) => {
        console.error("Error occurred in session context fetch user data", err);
      });
  };

  const switchLanguage = (language: string) => {
    localStorage.setItem("nexus-language", language);
    setValue((value) => new Session({ ...value, language }));
  };

  const [value, setValue] = useState(
    new Session({
      theme: "light",
      language: localStorage.getItem("nexus-language") || "en-US",
      logged: localStorage.getItem("nexus-access") !== null,
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      role: "",
      permissions: [],
      image_url: "",
      loaded: false,
      user_id: null,
      handleLogin,
      handleLogout,
      switchLanguage,
      fetchUserData,
    })
  );

  useEffect(() => {
    if (value.logged) {
      fetchUserData();
      handleSocket();
    }
  }, [value.logged]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export const useSession = () => React.useContext(SessionContext);
