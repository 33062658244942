import React, { useEffect, useState } from "react";
import { Col, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstLiveTable from "../../components/DstTable/DstLiveTable";
import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import { useSession } from "../../contexts/SessionContext";
import { EvaluationAttempt } from "../../models/EvaluationAttempt";
import { EXAM_STATUS_ICON, getExamStatusIcon, getStatusColor, normalizeStatus } from "../../services/ExamStatus";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { format, languages, statuses } from "./EvaluationAttempts.function";
import "./EvaluationAttempts.scss";

const EvaluationAttempts = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(searchParams.get("status")?.split(",") || []);
  const [selectedIntensities, setSelectedIntensities] = useState<string[]>(
    searchParams.get("format")?.split(",") || []
  );
  const [selectedUserLanguages, setSelectedUserLanguages] = useState<string[]>(
    searchParams.get("language")?.split(",") || []
  );
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const statusParam = searchParams.get("status");
    const formatParam = searchParams.get("format");
    const languageParam = searchParams.get("language");
    const searchParam = searchParams.get("search");

    setSelectedStatuses(statusParam?.split(",") || []);
    setSelectedIntensities(formatParam?.split(",") || []);
    setSelectedUserLanguages(languageParam?.split(",") || []);
    setSearchQuery(searchParam || "");
  }, [searchParams]);

  useEffect(() => {
    const params: Record<string, string> = {};

    if (selectedStatuses.length > 0) {
      params.status = selectedStatuses.join(",");
    }
    if (selectedIntensities.length > 0) {
      params.format = selectedIntensities.join(",");
    }
    if (selectedUserLanguages.length > 0) {
      params.language = selectedUserLanguages.join(",");
    }
    if (searchQuery) {
      params.search = searchQuery;
    }

    setSearchParams(params);
  }, [selectedStatuses, selectedIntensities, selectedUserLanguages, searchQuery, setSearchParams]);

  const _generateRemoteUrl = () => {
    const filters = [];
    if (selectedStatuses.length > 0) {
      filters.push(`status=${selectedStatuses.join(",")}`);
    }
    if (selectedIntensities.length > 0) {
      filters.push(`format=${selectedIntensities.join(",")}`);
    }
    if (selectedUserLanguages.length > 0) {
      filters.push(`language=${selectedUserLanguages.join(",")}`);
    }
    if (searchQuery) {
      filters.push(`search=${searchQuery.toLowerCase()}`);
    }

    return `evaluations/attempts${filters.length > 0 ? `?${filters.join("&")}` : ""}`;
  };

  const columns = [
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.EXAM_NAME"),
      key: "evaluation.name",
      sortable: true,
      render: (item: EvaluationAttempt) => {
        let iconClass = "dst-icon-message-question-square";
        switch (item.evaluation.type.toLowerCase()) {
          case "notebook":
            iconClass = "dst-icon-file-code-01";
            break;
          case "upload":
            iconClass = "dst-icon-paperclip";
            break;
          case "html":
            iconClass = "dst-icon-code-02";
            break;
        }
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <i className={`${iconClass} me-2`} />
              {item.evaluation.name || "N/A"}
            </div>
          </div>
        );
      },
    },
    {
      name: (
        <div className="d-flex align-items-center">
          {translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.TITLE")}
          <OverlayTrigger
            key="overlay-trigger-deadline-info"
            placement="bottom"
            overlay={
              <Tooltip aria-label="Extra time">
                {translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.EXPLAIN")}
              </Tooltip>
            }
          >
            <i className="clickable dst-icon-info-circle ms-2" />
          </OverlayTrigger>
        </div>
      ),
      key: "deadline",
      render: (item: EvaluationAttempt) => {
        if (["correction_pending", "being_corrected"].includes(item.status.toLowerCase()) && item.deadline) {
          const [time, date] = item.deadline.split(" ");
          const [day, month, year] = date.split("/").map(Number);
          const [hours, minutes] = time.split(":").map(Number);
          const deadline = new Date(year, month - 1, day, hours, minutes);

          if (!isNaN(deadline.getTime())) {
            return (
              <div
                className={`d-flex align-items-center ${
                  deadline.getTime() <= new Date().getTime() ? "text-danger" : ""
                }`}
              >
                {formatDateHumanly(language, deadline.toISOString())}
              </div>
            );
          } else {
            return translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.INVALID_DATE");
          }
        } else {
          return "N/A";
        }
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.END_DATE.TITLE"),
      key: "dt_ended",
      sortable: true,
      render: (item: EvaluationAttempt) => {
        if (item.dt_ended) {
          const [time, date] = item.dt_ended.split(" ");
          const [day, month, year] = date.split("/").map(Number);
          const [hours, minutes] = time.split(":").map(Number);
          const dtEnded = new Date(year, month - 1, day, hours, minutes);

          if (!isNaN(dtEnded.getTime())) {
            return (
              <div className="d-flex align-items-center">{formatDateHumanly(language, dtEnded.toISOString())}</div>
            );
          } else {
            return translate(language, "PAGES.ATTEMPTS.COLUMNS.END_DATE.INVALID_DATE");
          }
        } else {
          return "N/A";
        }
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.STATUS"),
      key: "status",
      render: (item: EvaluationAttempt) => {
        const normalizedStatus = normalizeStatus(item.status);
        return (
          <div className="d-flex align-items-center">
            {normalizedStatus ? getExamStatusIcon(normalizedStatus) : EXAM_STATUS_ICON.default}
            <span className={getStatusColor(item.status)}>{item.status || "N/A"}</span>
          </div>
        );
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.AI_PREVISION"),
      key: "ai_prevision",
      render: (item: EvaluationAttempt) => item.ai_prevision_score || "N/A",
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.LEARNERS"),
      key: "user.full_name",
      sortable: true,
      render: (item: EvaluationAttempt) => (
        <div className="d-flex align-items-center clickable-cell">
          {item.user.full_name} <i className="dst-icon-link-01 ms-1" />
        </div>
      ),
      onCellClick: (event: React.MouseEvent, item: EvaluationAttempt) =>
        completeNavigate(event, navigate, `/user/${item.user.id}`),
    },
  ];
  return (
    <React.Fragment>
      <h1 className="p-3">{translate(language, "PAGES.ATTEMPTS.TITLE")}</h1>
      <hr />
      <div className="px-3">
        <div className="d-flex mb-3 justify-content-between">
          <div className="d-flex">
            {/* <div className="me-2">
              <MultiFilterDropdown
                tags={programs}
                onSelectTags={(elem) => setSelectedPrograms(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.PROGRAM")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={customerTypes}
                onSelectTags={(elem) => setSelectedCustomerTypes(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.CUSTOMER_SEGMENT")}
                language={language}
              />
            </div> */}
            <div className="me-2">
              <MultiFilterDropdown
                tags={format}
                defaultValue={selectedIntensities}
                onSelectTags={(elem) => setSelectedIntensities(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.FORMAT")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={languages}
                defaultValue={selectedUserLanguages}
                onSelectTags={(elem) => setSelectedUserLanguages(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.LANGUAGE")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={statuses}
                onSelectTags={(elem) => setSelectedStatuses(elem as string[])}
                showSearchBar
                defaultValue={selectedStatuses}
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.STATUS")}
                language={language}
              />
            </div>
            <div className="d-flex align-items-center">
              {(selectedPrograms.length > 0 ||
                selectedStatuses.length > 0 ||
                selectedUserLanguages.length > 0 ||
                selectedCustomerTypes.length > 0 ||
                selectedIntensities.length > 0) && (
                <div
                  className="ms-3 clickable dark-gray-font text-decoration-underline xs-regular"
                  onClick={() => {
                    setSelectedPrograms([]);
                    setSelectedStatuses([]);
                    setSelectedUserLanguages([]);
                    setSelectedCustomerTypes([]);
                    setSelectedIntensities([]);
                  }}
                >
                  {translate(language, "PAGES.ATTEMPTS.ACTIONS.CLEAR_ALL_FILTER")}
                </div>
              )}
            </div>
          </div>
          <DstButton
            variant="light"
            btnImageBefore={<span className="dst-icon-settings-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.MY_FILTER")}
            clickFunction={() => alert("WIP")}
          />
        </div>
        <Row>
          <Col>
            <InputGroup>
              <ListerSearch setSearchQuery={setSearchQuery} timeDelay={500} showSearchIcon />
            </InputGroup>
          </Col>
          <Col>
            <div className="d-flex align-items-center justify-content-end xs-regular mb-3">
              {/* {selectedItems.length > 0 && (
            <div
            className="me-2 clickable dark-gray-font text-decoration-underline"
            onClick={() => setSelectedItems([])}
            >
            {translate(language, "PAGES.ATTEMPTS.ACTIONS.CANCEL_SELECTION")}
            </div>
            )}
            <div className="me-2 electric-blue-font">
            {selectedItems.length} {translate(language, "PAGES.ATTEMPTS.CORRECTION_SELECTED")}
            </div>
            <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-tool-02 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_IN_PROGRESS")}
            clickFunction={() => alert("WIP")}
            />
            <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-flag-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_ON_HOLD")}
            clickFunction={() => alert("WIP")}
            /> */}
              <DstButton
                variant="light"
                btnImageBefore={<span className="dst-icon-download-01 me-1" />}
                value={translate(language, "PAGES.ATTEMPTS.ACTIONS.DOWNLOAD_FILE")}
                clickFunction={() => alert("WIP")}
              />
            </div>
          </Col>
        </Row>

        <DstLiveTable
          remote={_generateRemoteUrl()}
          remoteKey="evaluation_attempts_list"
          columns={columns}
          onRowClick={(event, item: any) =>
            ["CREATED", "IN_PROGRESS"].includes(item.status)
              ? null
              : completeNavigate(event, navigate, `/evaluation-attempt/${item.id}`)
          }
          pagination
          itemsPerPage={20}
          paginationAlwaysShowFooter
        />
      </div>
    </React.Fragment>
  );
};
export default EvaluationAttempts;
