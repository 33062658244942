import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { Col, Form, Row } from "react-bootstrap";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectDropdown from "../../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import translate from "../../../../../services/Translate";
import { cohortSprintModulesType } from "../CohortSprint.d";
import { handleOptionalChange, handleStateChange } from "../CohortSprint.function";

const handleConfirm = (selectedItems: any, index: number, setOptionalStatus: Function) => {
  setOptionalStatus((prevStatus: any[]) => {
    const newStatus = [...prevStatus];
    newStatus[index] = [
      ...newStatus[index],
      ...selectedItems.map((item: any) => ({
        id: item.id,
        name: item.name,
        isOptional: false,
      })),
    ];
    return newStatus;
  });
};

const handleModuleDelete = (sprintIndex: number, moduleIndex: number, setOptionalStatus: Function) => {
  setOptionalStatus((prevStatus: any[]) => {
    const newStatus = [...prevStatus];
    newStatus[sprintIndex] = newStatus[sprintIndex].filter((_: any, index: number) => index !== moduleIndex);
    return newStatus;
  });
};

const handleDragEnd = (result: DropResult, sprintIndex: number, optionalStatus: any[], setOptionalStatus: Function) => {
  if (!result.destination) return;

  const updatedOptionalStatus = [...optionalStatus];
  const [movedLesson] = updatedOptionalStatus[sprintIndex].splice(result.source.index, 1);
  updatedOptionalStatus[sprintIndex].splice(result.destination.index, 0, movedLesson);
  setOptionalStatus(updatedOptionalStatus);
};

export const buildPopupTabs = (
  language: string,
  sprintNames: string[],
  sprintsAndModules: cohortSprintModulesType,
  isSending: boolean,
  setIsSending: Function,
  inputValues: any[],
  setInputValues: Function,
  optionalStatus: any[],
  setOptionalStatus: Function,
  startDates: string[],
  setStartDates: Function,
  endDates: string[],
  setEndDates: Function,
  loadingModules: boolean,
  modules: any[],
  handleSave: Function
) => {
  return sprintNames.map((sprintName, index) => {
    const sprintModules = sprintsAndModules[sprintName] || [];
    const startDate = sprintModules[0]?.dt_start || "";
    const endDate = sprintModules[0]?.dt_end || "";

    return {
      id: `sprint-${index}`,
      title: (
        <React.Fragment>
          <div className="s-bold">{sprintName}</div>
          <div className="xxs-regular smoke-gray-font">
            {startDate} - {endDate}
          </div>
        </React.Fragment>
      ),
      content: (
        <div className="white-background m-3 p-3">
          <Form.Group controlId={`name-${index}`} className="mb-3">
            <Form.Label>{translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.NAME")}</Form.Label>
            <Form.Control
              type="text"
              value={inputValues[index]}
              onChange={(e) => handleStateChange(index, e.target.value, inputValues, setInputValues)}
              aria-label="Name"
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId={`startDate-${index}`} className="mb-3">
                <Form.Label>{translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.START_DATE")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={startDates[index]}
                  onChange={(e) => handleStateChange(index, e.target.value, startDates, setStartDates)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId={`endDate-${index}`} className="mb-3">
                <Form.Label>{translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.END_DATE")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={endDates[index]}
                  onChange={(e) => handleStateChange(index, e.target.value, endDates, setEndDates)}
                />
              </Form.Group>
            </Col>
          </Row>
          <DragDropContext onDragEnd={(result) => handleDragEnd(result, index, optionalStatus, setOptionalStatus)}>
            <Droppable droppableId={`droppable-${index}`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {optionalStatus[index].map((lesson: any, lessonIndex: any) => (
                    <Draggable key={lesson.id.toString()} draggableId={lesson.id.toString()} index={lessonIndex}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mb-3 draggable-row"
                        >
                          <div className="border-bottom d-flex justify-content-between py-2">
                            <div className="d-flex">
                              <i className="dst-icon-align-justify me-3 align-content-center"></i>
                              <div className="d-flex align-items-center">
                                <div className="badge bg-primary me-2 min-3ch">{lesson.id}</div>
                              </div>
                              <div className="me-3">{lesson.name}</div>
                            </div>
                            <div className="text-nowrap align-content-center">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={lesson.isOptional}
                                  onChange={() =>
                                    handleOptionalChange(index, lessonIndex, optionalStatus, setOptionalStatus)
                                  }
                                />
                                <span className="ms-2">
                                  {translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.OPTIONAL")}
                                </span>
                              </label>
                              <i
                                className="dst-icon-trash-03 ms-3"
                                onClick={() => handleModuleDelete(index, lessonIndex, setOptionalStatus)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="border-bottom">
            <MultiSelectDropdown
              label={
                <React.Fragment>
                  <i className="dst-icon-package-plus me-3 align-content-center blue-font"></i>
                  <span className="blue-font">
                    {translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.ADD_MODULE")}
                  </span>
                </React.Fragment>
              }
              badgeItemProperty="name"
              displayItem={(item: any) => (
                <div>
                  <span className="badge bg-primary me-2">{item.id}</span>
                  {item.name}
                </div>
              )}
              items={modules}
              language={language}
              hideCaret={true}
              onConfirm={(selectedItems) => handleConfirm(selectedItems, index, setOptionalStatus)}
              clearOnConfirm={true}
              toggleClass="btn-secondary mb-1"
              mock={loadingModules}
            />
          </div>
          <div className="text-center">
            <DstButton
              value={translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.BUTTON_SAVE")}
              variant="primary"
              btnClass="mt-3"
              loading={isSending}
              disabled={isSending}
              clickFunction={() => {
                setIsSending(true);
                handleSave(index);
              }}
            />
          </div>
        </div>
      ),
    };
  });
};
