import React from "react";
import { useEffect, useState } from "react";
import { Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { Bar, BarChart, Cell, Legend, Pie, PieChart, Tooltip, XAxis } from "recharts";

import DstButton from "../../components/DstButton/DstButton";
import { useSession } from "../../contexts/SessionContext";
import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import languagesArray from "../../services/languages";
import {
  COLOR_PALETTE,
  calculateGlobalNote,
  fetchAnswer,
  fetchQuestions,
  getChartData,
} from "./InsightsSurvey.function";

const InsigthsSurvey = () => {
  const { language } = useSession();
  const [languageSelection, setLanguageSelection] = useState<string>(language);
  const [surveysType, setSurveysType] = useState<any[]>([]);
  const [surveyTypeSelection, setSurveyTypeSelection] = useState<string>("");
  const [surveyQuestions, setSurveyQuestions] = useState<any[]>([]);
  const [surveyQuestionsSpinner, setSurveyQuestionsSpinner] = useState<boolean>(true);
  const [surveyTypeOfQuestion, setSurveyTypeOfQuestion] = useState<string | null>(null);
  const [surveySelection, setSurveySelection] = useState<
    { answer: any[]; label: string; type: string; rate_limit: number }[]
  >([]);
  const [surveyAnswer, setSurveyAnswer] = useState<{ content: { answer: string } }[]>([]);
  const [surveyAnswserSpinner, setSurveyAnswserSpinner] = useState<boolean>(true);
  const [surveyChart, setSurveyChart] = useState<string>("PieChart");

  useEffect(() => {
    ApiService.get("surveys/types").then((response) => {
      setSurveysType(response.data.survey_types);
    });
  }, []);

  const _renderCustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload || !payload.length) return null;

    const { percentage, response } = payload[0].payload;

    return (
      <div className="m-regular p-3 white-background border">
        {response || translate(language, "PAGES.INSIGHTS.SURVEY.NO_RESPONSES")} : {percentage}%
      </div>
    );
  };

  const _renderCustomLegend = (data: any[]) => {
    return (
      <div className="text-center">
        <div className="d-flex justify-content-around">
          {surveyTypeOfQuestion && ["star_rate", "square_rate"].includes(surveyTypeOfQuestion)
            ? data
                .sort((a, b) => a.response - b.response)
                .map((item, index) => (
                  <div key={`legend-item-${index}`} style={{ color: item.color }}>
                    {item.response}
                  </div>
                ))
            : surveySelection.map((item, index) => (
                <div key={`item-${index}`} style={{ color: COLOR_PALETTE[index % COLOR_PALETTE.length] }}>
                  {item.label}
                </div>
              ))}
        </div>
        {surveyAnswer.length > 0 &&
          surveyTypeOfQuestion &&
          ["star_rate", "square_rate"].includes(surveyTypeOfQuestion) && (
            <p className="m-regular mt-3">
              {translate(language, "PAGES.INSIGHTS.SURVEY.GLOBAL_NOTE")} {calculateGlobalNote(surveyAnswer)} /{" "}
              {surveySelection[0]?.rate_limit}
            </p>
          )}
      </div>
    );
  };

  return (
    <div className="p-md-5 p-3">
      <h1 className="mb-3">{translate(language, "PAGES.INSIGHTS.SURVEY.TITLE")}</h1>
      <Row className="mb-3 m-bold">
        <Col xl={4}>
          <Form.Group controlId="languageSelect">
            <Form.Label>{translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_LANGUAGE")}</Form.Label>
            <Form.Control
              as="select"
              className="m-regular w-100"
              value={languageSelection}
              onChange={(e) => {
                setLanguageSelection(e.target.value);
                if (surveyTypeSelection !== null) {
                  fetchQuestions(e.target.value, surveyTypeSelection, setSurveyQuestions, setSurveyQuestionsSpinner);
                }
                setSurveySelection([]);
                setSurveyTypeSelection("");
              }}
            >
              {Object.entries(languagesArray).map(([key, lang]) => (
                <option key={key} value={key}>
                  {lang.LANGUAGE}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xl={4}>
          <Form.Group controlId="surveyTypeSelect">
            <Form.Label>{translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_SURVEY_TYPE")}</Form.Label>
            {surveysType.length === 0 ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <Form.Control
                as="select"
                value={surveyTypeSelection || ""}
                className="m-regular"
                onChange={(e) => {
                  setSurveyQuestionsSpinner(true);
                  setSurveyTypeSelection(e.target.value);
                  fetchQuestions(languageSelection, e.target.value, setSurveyQuestions, setSurveyQuestionsSpinner);
                  setSurveyAnswer([]);
                  setSurveySelection([]);
                }}
              >
                <option value="" disabled>
                  {translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_SURVEY_TYPE_PLACEHOLDER")}
                </option>
                {Array.isArray(surveysType) &&
                  surveysType.map((surveyType: any, index: number) => (
                    <option key={surveyType.value || index} value={surveyType.value}>
                      {surveyType.survey_name}
                    </option>
                  ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col xl={4}>
          {surveyTypeSelection?.length > 0 &&
            (surveyQuestionsSpinner === true ? (
              <Form.Group controlId="surveySelect">
                <Form.Label>{translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_QUESTION")}</Form.Label>
                <div>
                  <Spinner />
                </div>
              </Form.Group>
            ) : surveyQuestions.length > 0 ? (
              <Form.Group controlId="surveySelect">
                <Form.Label>{translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_QUESTION")}</Form.Label>
                <Form.Control
                  as="select"
                  className="m-regular w-100"
                  value={surveySelection[0]?.label || ""}
                  onChange={(e) => {
                    const selectedQuestion = surveyQuestions.find(
                      (survey) =>
                        survey.survey_name === surveyTypeSelection &&
                        survey.language === languageSelection &&
                        survey.text === e.target.value
                    );
                    if (selectedQuestion) {
                      setSurveyAnswserSpinner(true);
                      setSurveyTypeOfQuestion(selectedQuestion.type);
                      setSurveySelection([
                        {
                          answer: selectedQuestion.answer,
                          label: e.target.value,
                          type: selectedQuestion.type,
                          rate_limit: selectedQuestion.rate_limit,
                        },
                      ]);
                      fetchAnswer(selectedQuestion.question_id, setSurveyAnswer, setSurveyAnswserSpinner);
                    }
                  }}
                >
                  <option value="" disabled>
                    {translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_QUESTION_PLACEHOLDER")}
                  </option>
                  {surveyQuestions
                    ?.filter(
                      (survey) => survey.survey_name === surveyTypeSelection && survey.language === languageSelection
                    )
                    .map((survey, index) => (
                      <option key={survey.text || index} value={survey.text} disabled={survey.type === "text"}>
                        {survey.text}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            ) : (
              <Form.Group controlId="surveySelect">
                <Form.Label>{translate(language, "PAGES.INSIGHTS.SURVEY.SELECT_QUESTION")}</Form.Label>
                <p className="s-regular">{translate(language, "PAGES.INSIGHTS.SURVEY.NO_QUESTIONS_AVAILABLE")}</p>
              </Form.Group>
            ))}
        </Col>
      </Row>
      {surveyAnswserSpinner === true && surveySelection.length > 0 ? (
        <div>
          <Spinner />
        </div>
      ) : (
        surveyTypeSelection &&
        surveyQuestions.length > 0 &&
        surveySelection.length > 0 && (
          <Row>
            <Col md={6}>
              <h3 className="l-regular">{translate(language, "PAGES.INSIGHTS.SURVEY.RESULTS")}</h3>
              {surveyAnswer.length > 0 ? (
                <p className="m-regular">
                  {translate(language, "PAGES.INSIGHTS.SURVEY.TOTAL_RESPONSES").replace(
                    "{{COUNT}}",
                    String(surveyAnswer.length)
                  )}
                </p>
              ) : (
                <p className="m-regular">{translate(language, "PAGES.INSIGHTS.SURVEY.NO_RESPONSES")}</p>
              )}
              {surveyAnswer.length > 0 ? (
                <div className="d-flex">
                  {surveyChart === "PieChart" ? (
                    <PieChart width={400} height={300}>
                      <Pie
                        data={getChartData(surveyAnswer, surveyTypeOfQuestion)}
                        cx={200}
                        cy={100}
                        labelLine={false}
                        label={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="count"
                      >
                        {getChartData(surveyAnswer, surveyTypeOfQuestion).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip content={_renderCustomTooltip} />
                      <Legend content={() => _renderCustomLegend(getChartData(surveyAnswer, surveyTypeOfQuestion))} />
                    </PieChart>
                  ) : (
                    <div>
                      <BarChart width={400} height={300} data={getChartData(surveyAnswer, surveyTypeOfQuestion)}>
                        <XAxis dataKey="response" tickLine={false} axisLine={false} hide />
                        <Tooltip content={_renderCustomTooltip} />
                        <Legend content={() => _renderCustomLegend(getChartData(surveyAnswer, surveyTypeOfQuestion))} />
                        <Bar dataKey="count" fill="#8884d8">
                          {getChartData(surveyAnswer, surveyTypeOfQuestion).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  )}
                  <div>
                    <DstButton
                      btnClass=" dst-icon-switch-horizontal-01"
                      variant="secondary"
                      clickFunction={() => setSurveyChart(surveyChart === "PieChart" ? "BarChart" : "PieChart")}
                      value=""
                    />
                  </div>
                </div>
              ) : null}
            </Col>
            {surveyAnswer.length > 0 && (
              <Col md={6}>
                <h3>{translate(language, "PAGES.INSIGHTS.SURVEY.RESPONSES")}</h3>
                <ListGroup className="text-break">
                  {Object.entries(
                    surveyAnswer.reduce((acc: { [key: string]: number }, answer) => {
                      const response =
                        answer.content.answer || translate(language, "PAGES.INSIGHTS.SURVEY.NO_RESPONSES");
                      acc[response] = (acc[response] || 0) + 1;
                      return acc;
                    }, {} as { [key: string]: number })
                  )
                    .sort((a, b) => b[1] - a[1])
                    .map(([response, count], index) => (
                      <ListGroup.Item key={`response-${index}`}>
                        {count > 1 ? (
                          <React.Fragment>
                            {response} <span style={{ color: "var(--color-gray)" }}>({count})</span>
                          </React.Fragment>
                        ) : (
                          response
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            )}
          </Row>
        )
      )}
    </div>
  );
};

export default InsigthsSurvey;
