const Doc = ({
  color = "var(--color-white)",
  bgColor = "#35C1ED",
  classes = "me-2",
}: {
  color?: string;
  bgColor?: string;
  classes?: string;
}) => (
  <svg className={classes} height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.70968C0 1.66088 1.66088 0 3.70968 0H16.2903C18.3391 0 20 1.66088 20 3.70968V16.29C20 18.339 18.339 20 16.29 20H3.71C1.66102 20 0 18.339 0 16.29V3.70968Z"
      fill={bgColor}
    />
    <path d="M4 5H16" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    <path d="M4 10H16" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
    <path d="M4 15H11" stroke={color} strokeWidth="2.5" strokeLinecap="round" />
  </svg>
);

export default Doc;
