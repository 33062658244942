import React, { useEffect, useState } from "react";
import { Col, ListGroup, Placeholder, Row } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import Schema from "../../../../components/DstIcons/Schema";
import translate from "../../../../services/Translate";
import { PartnerInfo } from "./CohortPartnerInfo.d";
import { fetchCohortPartners } from "./CohortPartnerInfo.function";

const CohortPartnerInfo = ({
  cohortId,
  language,
  setIsCohortPartner,
}: {
  cohortId: string;
  language: string;
  setIsCohortPartner: Function;
}) => {
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchCohortPartners(cohortId, setPartnerInfo, setIsCohortPartner, language, () => {
      setLoading(false);
    });
  }, [cohortId, language, setIsCohortPartner]);

  return (
    <div className="mx-3">
      <h2 className="mb-4">{translate(language, "PAGES.COHORT.PARTNER_INFO.TITLE")}</h2>
      {loading ? (
        <React.Fragment>
          <Row className="mb-4">
            {[...Array(3)].map((_, index) => (
              <Col lg key={index} className="rounded shadow-sm mx-3">
                <div className="info-card p-3 s-regular">
                  <Placeholder as="h3" animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <ListGroup>
                    <ListGroup.Item className="border-0">
                      <Placeholder as="span" animation="glow">
                        <Placeholder xs={7} />
                      </Placeholder>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0">
                      <Placeholder as="span" animation="glow">
                        <Placeholder xs={5} />
                      </Placeholder>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0">
                      <Placeholder as="span" animation="glow">
                        <Placeholder xs={8} />
                      </Placeholder>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0">
                      <Placeholder as="span" animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            ))}
          </Row>
        </React.Fragment>
      ) : partnerInfo.length === 0 ? (
        <p className="text-muted">{translate(language, "PAGES.COHORT.PARTNER_INFO.NO_PARTNERS")}</p>
      ) : (
        partnerInfo.map((partner, index) => (
          <Row className="mb-4" key={index}>
            <Col lg className="rounded shadow-sm mx-3">
              <div className="info-card p-3 s-regular">
                <h3 className="blue-font mb-3">
                  <Schema color="var(--color-blue)" /> {translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.TITLE")}
                </h3>
                <ListGroup>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.PARTNER_NAME")}</strong>{" "}
                    {partner.partner.name}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.PARTNER_ROLE")}</strong>{" "}
                    {partner.partner.role || translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.NO_ROLE")}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.SUB_PARTNER_NAME")}</strong>{" "}
                    {partner.sub_partner_name}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.COHORT.COHORT_ID_EXTERNAL")}</strong>{" "}
                    {partner.cohort_id_ext}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col lg className="rounded shadow-sm mx-3 mt-4 mt-lg-0">
              <div className="info-card p-3 s-regular">
                <h3 className="blue-font mb-3">
                  <i className="dst-icon-certificate-01 me-2" />
                  {translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM.TITLE")}
                </h3>
                <ListGroup>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM.NAME")}</strong>{" "}
                    {partner.program?.name || "—"}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM.LANGUAGE")}</strong>{" "}
                    {partner.program?.language || "—"}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM.DESCRIPTION")}</strong>{" "}
                    {partner.program?.description || "—"}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM.POLE_NAME")}</strong>{" "}
                    {partner.program?.pole_name || "—"}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col lg className="rounded shadow-sm mx-3 mt-4 mt-lg-0">
              <div className="info-card p-3 s-regular">
                <h3 className="blue-font mb-3">
                  <i className="dst-icon-user-01 me-2" />
                  {translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM_MANAGER.TITLE")}
                </h3>
                <ListGroup>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM_MANAGER.NAME")}</strong>{" "}
                    {partner.program?.manager?.full_name || "—"}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM_MANAGER.EMAIL")}</strong>{" "}
                    {partner.program?.manager?.email || "—"}
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <strong>{translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM_MANAGER.ID")}</strong>{" "}
                    {partner.program?.manager?.id || "—"}
                  </ListGroup.Item>
                </ListGroup>
                <DstButton
                  btnClass="mt-2 mx-3"
                  value={translate(language, "PAGES.COHORT.PARTNER_INFO.PROGRAM_MANAGER.BUTTON_GO_TO_PROFIL")}
                  btnImageAfter={<i className="dst-icon-link-external-01 ms-1" />}
                  disabled={!partner.program?.manager?.id}
                  clickFunction={() => {
                    if (partner.program?.manager?.id) {
                      window.open(`/user/${partner.program.manager.id}`, "_blank");
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default CohortPartnerInfo;
