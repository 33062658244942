import ApiService from "../../services/ApiService";

export const COLOR_PALETTE = [
  "var(--color-blue)",
  "var(--color-green)",
  "var(--color-dark-red)",
  "var(--color-dark-green)",
  "var(--color-electric-blue)",
  "var(--color-orange)",
  "var(--color-pink)",
  "var(--color-violet)",
  "var(--color-dark-brown)",
];

export const fetchQuestions = (
  languageSelect: string,
  surveyType: string,
  setSurveyQuestions: any,
  setSurveyQuestionsSpinner: any
) => {
  ApiService.get(`surveys/${surveyType}/${languageSelect}/questions`).then((response) => {
    setSurveyQuestions(response.data.survey_questions);
    setSurveyQuestionsSpinner(false);
  });
};

export const fetchAnswer = (surveyQuestionId: string, setSurveyAnswer: any, setSurveyAnswserSpinner: any) => {
  ApiService.get(`surveys/questions/${surveyQuestionId}/answers`).then((response) => {
    const answers = response.data.survey_answers.map((answer: any) => ({
      ...answer,
      content: answer.content,
    }));
    setSurveyAnswer(answers);
    setSurveyAnswserSpinner(false);
  });
};

export const getChartData = (surveyAnswer: any, surveyType: any) => {
  if (!surveyAnswer || surveyAnswer.length === 0) return [];

  const responseCounts: { [key: string]: number } = surveyAnswer.reduce(
    (acc: { [key: string]: number }, answer: any) => {
      const response = answer.content.answer;
      acc[response] = (acc[response] || 0) + 1;
      return acc;
    },
    {}
  );

  const totalResponses = Object.values(responseCounts).reduce((sum, count) => sum + count, 0);

  let chartData = Object.entries(responseCounts).map(([response, count], index) => ({
    response,
    count,
    percentage: ((count / totalResponses) * 100).toFixed(2),
    color: COLOR_PALETTE[index % COLOR_PALETTE.length],
  }));

  chartData = chartData.sort((a, b) => parseFloat(a.response) - parseFloat(b.response));

  if (surveyType === "question") {
    chartData = chartData.slice(0, 10);
  }

  return chartData;
};

export const calculateGlobalNote = (surveyAnswer: any) => {
  if (!surveyAnswer.length) return 0;

  const total = surveyAnswer.reduce((sum: number, answer: any) => sum + parseFloat(answer.content.answer || 0), 0);
  return (total / surveyAnswer.length).toFixed(2);
};
