import { useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectLiveDropdown from "../../../../../components/MultiSelectLiveDropdown/MultiSelectLiveDropdown";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { fetchUsers, updateUsers } from "../CohortUsers.function";
import { CohortUsersPopupProperties } from "./CohortUsersPopup.d";

const CohortUsersPopup = ({
  language,
  openAddUsersModal,
  setOpenAddUsersModal,
  actualUserIds,
  cohortId,
  setLoading,
  setCohortUsers,
}: CohortUsersPopupProperties) => {
  const [usersToAdd, setUsersToAdd] = useState<any[]>([]);

  return (
    <Popup
      show={openAddUsersModal}
      onClose={() => setOpenAddUsersModal(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS.TITLE")}
    >
      <div className="d-flex flex-column">
        <div className="flex-grow-1">
          <MultiSelectLiveDropdown
            category={["users"]}
            label={
              usersToAdd && usersToAdd.length > 0
                ? `${usersToAdd.length} ${translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS.USERS_SELECTED")}`
                : translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS.SELECT_USERS")
            }
            language={language}
            mockClass="rounded-0 rounded-end"
            toggleClass="min-w-100px m-regular"
            onConfirm={(selectedUsers: any[]) => setUsersToAdd(selectedUsers.map((user) => user.value.id))}
            showFilterButton={false}
            clearItemsOnSend={false}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <DstButton
            btnWidth="100px"
            variant="primary"
            value={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_USERS.CONFIRM_BUTTON")}
            clickFunction={() => {
              setLoading(true);
              updateUsers([...actualUserIds, ...usersToAdd], cohortId, language, () => {
                fetchUsers(cohortId, setCohortUsers, language, () => {
                  setLoading(false);
                  setUsersToAdd([]);
                  setOpenAddUsersModal(false);
                });
              });
            }}
            btnClass="me-2"
          />
        </div>
      </div>
    </Popup>
  );
};

export default CohortUsersPopup;
