import React from "react";
import Modal from "react-bootstrap/Modal";

import { PopupProps } from "./Popup.d";
import "./Popup.scss";

const Popup = ({
  children,
  onClose,
  modalTitle,
  size = "lg",
  fullscreen,
  show,
  scrollable = false,
  removePadding = false,
}: PopupProps) => {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onClose}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={fullscreen}
        scrollable={scrollable}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={removePadding ? "p-0" : ""}>{children}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Popup;
