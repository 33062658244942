import React, { useState } from "react";
import { Col, Placeholder, Row, Spinner } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import Pin from "../../../../components/DstIcons/Pin";
import Plus from "../../../../components/DstIcons/Plus";
import NoteContent from "../../../../components/NoteContent/NoteContent";
import NotesList from "../../../../components/NoteList/NoteList";
import { Note } from "../../../../models/Note";
import translate from "../../../../services/Translate";
import { createNote, deleteNote, togglePinNote, updateNote } from "./UserNotes.function";

const UserNotes = ({
  user_data,
  language,
  loaded,
  setUserData,
  user_email,
  activeNote,
  setActiveNote,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
  setUserData: Function;
  user_email: string;
  activeNote: Note | null;
  setActiveNote: Function;
}): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const pinnedNotes = user_data.user_notes?.filter((note: Note) => !!note.is_pinned);
  const unpinnedNotes = user_data.user_notes?.filter((note: Note) => !note.is_pinned);

  const _selectNote = (note: Note) => {
    setActiveNote(note);
    setIsEditing(false);
  };

  const _addNote = (note: Note) => {
    setActiveNote(note);
    setIsEditing(false);
    createNote(user_data.id, user_email, language, setUserData, setActiveNote, setIsEditing);
  };

  const _deleteNote = (id: string) => {
    deleteNote(user_data.id, id, setUserData, () => setActiveNote(null));
  };

  const _saveNote = (updatedNote: Note, is_pinning = false) => {
    if (is_pinning) {
      togglePinNote(user_data.id, updatedNote, setUserData, (new_note: Note) => {
        setActiveNote(new_note);
        setIsEditing(false);
        setIsWaiting(false);
      });
    } else {
      updateNote(user_data.id, user_email, language, updatedNote, setUserData, (new_note: Note) => {
        setActiveNote(new_note);
        setIsEditing(true);
        setIsWaiting(false);
      });
    }
  };

  return loaded ? (
    <Row className="w-100">
      <Col xs={3}>
        <DstButton
          value={translate(language, "PAGES.USER.NOTE.ADD_NOTE")}
          btnImageBefore={<Plus />}
          clickFunction={_addNote}
          btnClass="w-100"
        />
        {!!pinnedNotes.length && (
          <React.Fragment>
            <div className="my-3 darkgray-font s-bold">
              <Pin />
              {translate(language, "PAGES.USER.NOTE.PINNED")}
            </div>
            <NotesList notes={pinnedNotes} selectNote={_selectNote} activeNote={activeNote} deleteNote={_deleteNote} />
          </React.Fragment>
        )}
        <div className="my-3 darkgray-font s-bold">
          {translate(language, "PAGES.USER.NOTE.ALL_NOTE")}
          {isWaiting ? (
            <span className="ms-2 lightgray-font">
              {translate(language, "PAGES.USER.NOTE.SYNCHRONIZING")}{" "}
              <Spinner className="ms-1 spinner-in-text" size="sm" />
            </span>
          ) : (
            ""
          )}
        </div>
        {user_data.user_notes.length ? (
          <NotesList notes={unpinnedNotes} selectNote={_selectNote} activeNote={activeNote} deleteNote={_deleteNote} />
        ) : (
          <p>{translate(language, "PAGES.USER.NOTE.NO_NOTE")}</p>
        )}
      </Col>
      <Col xs={9}>
        {activeNote ? (
          <NoteContent
            note={activeNote}
            deleteNote={_deleteNote}
            saveNote={_saveNote}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setIsWaiting={setIsWaiting}
            language={language}
          />
        ) : (
          <div>{translate(language, "PAGES.USER.NOTE.SELECT_NOTE")}</div>
        )}
      </Col>
    </Row>
  ) : (
    <Row className="w-100">
      <Col xs={3}>
        <DstButton mock btnClass="w-100" />
        <Placeholder as="div" animation="glow">
          <Placeholder xs={6} role="mockup" className="mt-2 mb-4" />
          <Placeholder xs={12} style={{ height: 550 }} />
        </Placeholder>
      </Col>
      <Col xs={9}>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={6} role="mockup" />
        </Placeholder>
      </Col>
    </Row>
  );
};

export default UserNotes;
