import CertifBlock from "../../../../components/CertifBlock/CertifBlock";
import { formatMinutesHourly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";

const UserCertification = ({
  user_data,
  loaded,
  language,
  refreshUserCertifications,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
  refreshUserCertifications: Function;
}): JSX.Element => {
  return (
    <div className="w-100">
      <h2>{translate(language, "PAGES.USER.CERTIFICATION.TITLE")}</h2>
      <div className="scrollable py-3 mb-3" id="certif-scrollable">
        <div className="d-inline-flex flex-nowrap">
          {loaded ? (
            user_data.user_certifs.length > 0 ? (
              user_data.user_certifs.map((certif: any, index: number) => (
                <CertifBlock
                  key={index}
                  attempts={certif.attempt_count}
                  certifDate={certif.dt_created}
                  documentId={certif.document_id}
                  filename={certif.filename}
                  mainText={certif.certification_name}
                  remarkText={certif.duration ? formatMinutesHourly(certif.duration) : ""}
                  topLeftTitle={certif.sprint_name}
                  userFullName={`${user_data.first_name} ${user_data.last_name}`}
                  refreshUserCertifications={refreshUserCertifications}
                />
              ))
            ) : (
              <p>{translate(language, "PAGES.USER.CERTIFICATION.NO_CERTIFICATION")}</p>
            )
          ) : (
            [...Array(5)].map((_, index: number) => <CertifBlock mock={true} key={`mock-certif-block-${index}`} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCertification;
