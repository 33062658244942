import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { getAgeHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import Edit from "../DstIcons/Edit";
import Pin from "../DstIcons/Pin";
import "./NoteContent.scss";

const NoteContent = ({ note, saveNote, isEditing, setIsEditing, setIsWaiting }: any, language: string) => {
  const [editMode, setEditMode] = useState(isEditing);
  const [noteTitle, setNoteTitle] = useState(note.title);
  const [noteContent, setNoteContent] = useState(note.value);
  const noteContentContainerRef = useRef<HTMLDivElement>(null);
  const delayToSave = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setNoteTitle(note.title);
    setNoteContent(note.value);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        noteContentContainerRef.current &&
        !noteContentContainerRef.current.contains(event.target as Node) &&
        editMode
      ) {
        setEditMode(false);
        setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [note, editMode, setIsEditing]);

  const _handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWaiting(true);
    if (delayToSave.current) {
      clearTimeout(delayToSave.current);
    }
    const newTitle = e.target.value;
    if (newTitle.length > 255) {
      alert(translate(language, "PAGES.USER.NOTE.TITLE_TOO_LONG"));
      return;
    }
    setNoteTitle(newTitle);
    delayToSave.current = setTimeout(() => {
      autoSave();
    }, 2000);
  };

  const _handleContentChange = (newContent: string, first: any) => {
    setIsWaiting(true);
    if (delayToSave.current) {
      clearTimeout(delayToSave.current);
    }
    setNoteContent(newContent);
    delayToSave.current = setTimeout(() => {
      autoSave(undefined, newContent);
    }, 2000);
  };

  const autoSave = (newTitle?: string, newContent?: string) => {
    const body = {
      id: note.id,
    };
    if (newTitle || noteTitle) {
      (body as any).title = newTitle || noteTitle;
    }
    if (newContent || noteContent) {
      (body as any).value = newContent || noteContent;
    }
    saveNote(body);
  };

  const handlePinClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    saveNote({ ...note, is_pinned: !note.is_pinned }, true);
  };

  return (
    <div ref={noteContentContainerRef} className="text-break">
      {editMode ? (
        <>
          <div className="d-flex">
            <input
              type="text"
              value={noteTitle}
              placeholder={translate(language, "PAGES.USER.NOTE.WRITE_A_TITLE")}
              onChange={_handleTitleChange}
              className="title-note"
            />
          </div>
          <ReactQuill defaultValue={note.value} onChange={_handleContentChange} className="mt-3" />
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between ">
            <div className="d-flex align-items-center justify-content-between">
              {note.title ? (
                <h3 className="me-2 charcoal-gray-font mb-0">{note.title}</h3>
              ) : (
                <h3 className="placeholder-title me-2 charcoal-gray-font mb-0" onClick={() => setEditMode(true)}>
                  {translate(language, "PAGES.USER.NOTE.WRITE_A_TITLE")}
                </h3>
              )}
              <div
                className="xs-regular smoke-gray-font me-1 text-nowrap"
                title={new Date(note.dt_created).toLocaleString()}
              >
                {getAgeHumanly(language, note.dt_created)}
              </div>
              <div className="xs-regular smoke-gray-font me-2 text-nowrap" title={note.author}>
                by {note.author_name || translate(language, "PAGES.USER.NOTE.UNKNOWN_USER")}
              </div>
              <div onClick={handlePinClick} className="pin-icon clickable">
                {note.is_pinned ? (
                  <Pin classes="me-1" color="var(--color-yellow)" bgColor="var(--color-yellow)" />
                ) : (
                  <Pin classes="me-1" />
                )}
              </div>
            </div>
            <div
              onClick={() => setEditMode(true)}
              className="d-flex align-items-center dark-gray-font clickable s-regular text-nowrap"
            >
              <Edit color="var(--color-dark-gray)" />
              <div>{translate(language, "PAGES.USER.NOTE.EDIT")}</div>
            </div>
          </div>
          <div
            className="charcoal-gray-font s-regular mt-3 text-break content-note"
            dangerouslySetInnerHTML={{ __html: note.value }}
            onClick={() => setEditMode(true)}
          />
        </>
      )}
    </div>
  );
};

export default NoteContent;
