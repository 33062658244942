export const statuses = [
  "SUCCESS",
  "REPASS",
  "FAILED",
  "CORRECTION_PENDING",
  "BEING_CORRECTED",
  "PRE_CORRECTION_IN_PROGRESS",
  "PRE_CORRECTED",
  "IN_PROGRESS",
  "CREATED",
];

export const languages = ["fr-FR", "en-US"];

export const format = ["HTML", "NOTEBOOK", "UPLOAD"];
