import { FormEvent, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import LanguageSelector from "../../components/language-selector/LanguageSelector";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import "./Login.scss";

const login = (event: FormEvent, username: string, password: string, handleLogin: Function) => {
  event.preventDefault();
  handleLogin(username, password);
};

const Login = () => {
  const { language, handleLogin } = useSession();
  const username = useRef() as React.MutableRefObject<HTMLInputElement>;
  const password = useRef() as React.MutableRefObject<HTMLInputElement>;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("nexus-access-token")) {
      const url = new URLSearchParams(location.search);
      const origin = url.get("next") || "/";
      navigate(origin);
    }
  });

  return (
    <section id="login-page">
      <div id="login-section" className="rounded p-5">
        <div className="justify-content-center text-center">
          <img src="assets/favicons/android-chrome-144x144.png" alt="DataScientest" height={70} className="mb-4" />
          <h1>{translate(language, "PAGES.LOGIN.WELCOME")}</h1>
        </div>
        <form
          className="text-center"
          onSubmit={(event) => login(event, username.current.value, password.current.value, handleLogin)}
        >
          <input
            className="w-100 mt-3 p-2"
            id="username_input"
            autoCapitalize="off"
            autoCorrect="off"
            placeholder={translate(language, "PAGES.LOGIN.USERNAME_PLACEHOLDER")}
            type="text"
            name="username"
            autoFocus
            ref={username}
          />
          <input
            className="w-100 mt-3 p-2"
            id="password_input"
            name="password"
            placeholder={translate(language, "PAGES.LOGIN.PASSWORD_PLACEHOLDER")}
            type="password"
            ref={password}
          />
          <DstButton
            btnClass="mt-3"
            btnSize="lg"
            type="submit"
            value={translate(language, "PAGES.LOGIN.BUTTON_TITLE")}
            variant="outline-dark"
          />
        </form>
        <LanguageSelector className="fs-5 mt-4 d-flex justify-content-center" logoStyle="w-30" />
      </div>
      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </section>
  );
};

export default Login;
