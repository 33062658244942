import React, { CSSProperties } from "react";

import UnauthorizedComponent from "../components/UnauthorizedComponent/UnauthorizedComponent";
import { useSession } from "../contexts/SessionContext";
import { Permission } from "../models/Permissions";

const _checkPermission = (permission: Permission, userPermission?: Permission[]): boolean => {
  if (!userPermission) return false;
  let returning = false;

  userPermission.forEach((routePermission: Permission) => {
    if (returning) {
      return;
    }
    if (routePermission.route === "/**" && routePermission.methods.includes("*")) {
      returning = true;
    }
    if (routePermission.route === permission.route) {
      returning = routePermission.methods.some((method) => permission.methods.includes(method));
    }
  });
  return returning;
};

export const validateUserPermission = (
  neededPermission: Permission[] | Permission,
  userPermissions: Permission[] | undefined
): boolean => [neededPermission].flat().some((permission: Permission) => _checkPermission(permission, userPermissions));

export const PermissionComponent = ({
  children,
  neededPermission,
  showFallBack = false,
  fallBackCustomClass,
  fallBackCustomStyle,
}: {
  children: JSX.Element[] | JSX.Element;
  neededPermission: Permission[] | Permission;
  showFallBack?: boolean;
  fallBackCustomClass?: Array<string>;
  fallBackCustomStyle?: CSSProperties;
}) => {
  const { logged, permissions, language } = useSession();

  neededPermission = [neededPermission].flat();
  // User is not authenticated or does not have the correct permission
  if (!logged || (neededPermission.length && !validateUserPermission(neededPermission, permissions))) {
    return showFallBack ? (
      <UnauthorizedComponent
        language={language}
        customClassName={fallBackCustomClass}
        customStyle={fallBackCustomStyle}
      />
    ) : (
      <React.Fragment></React.Fragment>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
