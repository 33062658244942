import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, Placeholder } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import ThreeDots from "../../../../components/DstIcons/ThreeDots";
import { useSession } from "../../../../contexts/SessionContext";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { Note, NoteComponentProps } from "./CohortNote.d";
import { createNote, deleteNote, fetchNote, pinNote, unpinNote, updateNote } from "./CohortNote.function";
import "./CohortNote.scss";

const NoteComponent = ({ cohortId, language }: NoteComponentProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const { email } = useSession();
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [showPinnedOnly, setShowPinnedOnly] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchNote(cohortId, setNotes, language, () => {
      setLoading(false);
    });
  }, [cohortId, language]);

  useEffect(() => {
    if (editingNote && inputRef.current) {
      const input = inputRef.current;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [editingNote, notes]);

  const handlePinNote = (noteId: string) => {
    const note = notes.find((note) => note.id === noteId);
    if (note) {
      if (note.is_pinned) {
        unpinNote(cohortId, noteId, language, () => {
          fetchNote(cohortId, setNotes, language);
        });
      } else {
        pinNote(cohortId, noteId, language, () => {
          fetchNote(cohortId, setNotes, language);
        });
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsAdding(true);
    event.preventDefault();
    const form = event.currentTarget;
    const newNote = form.notesInput.value;
    if (newNote) {
      if (editingNote) {
        updateNote(cohortId, email!, editingNote.id, newNote, language, () => {
          setEditingNote(null);
          setIsAdding(false);
          fetchNote(cohortId, setNotes, language);
          form.reset();
        });
      } else {
        createNote(cohortId, email!, newNote, false, language, () => {
          setIsAdding(false);
          fetchNote(cohortId, setNotes, language);
          form.reset();
        });
      }
    }
  };

  const handleDeleteNote = (noteId: string) => {
    deleteNote(cohortId, noteId, language, () => {
      fetchNote(cohortId, setNotes, language);
    });
  };

  const filteredNotes = showPinnedOnly ? notes.filter((note) => note.is_pinned) : notes;

  if (loading) {
    return (
      <div id="note-component" className="mx-3 border">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="my-2 p-3">{translate(language, "PAGES.COHORT.NOTES.TITLE")}</h2>
          <DstButton mock btnClass="mx-3" btnWidth="150px" variant="secondary" />
        </div>
        <div className="border-top p-3">
          <div id="note-display" className="bg-light mt-2 p-3 d-flex flex-column mb-2">
            <div>
              <Placeholder as="div" animation="glow">
                <Placeholder xs={12} role="mockup" className="w-100 mb-3" style={{ height: "50px" }} />
                <Placeholder xs={12} role="mockup" className="w-100 mb-3" style={{ height: "50px" }} />
                <Placeholder xs={12} role="mockup" className="w-100 mb-3" style={{ height: "50px" }} />
              </Placeholder>
            </div>
            <Form className="d-flex mt-auto">
              <Placeholder as="div" animation="glow" className="w-100 me-2">
                <Placeholder xs={12} role="mockup" style={{ height: "32px" }} />
              </Placeholder>
              <DstButton mock btnWidth="100px" variant="primary" />
            </Form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="note-component" className="mx-3 border">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="my-2 p-3">{translate(language, "PAGES.COHORT.NOTES.TITLE")}</h2>
        <DstButton
          btnClass="mx-3"
          variant="secondary"
          value={
            showPinnedOnly
              ? translate(language, "PAGES.COHORT.NOTES.BUTTONS.SHOW_ALL")
              : translate(language, "PAGES.COHORT.NOTES.BUTTONS.SHOW_PINNED")
          }
          clickFunction={() => setShowPinnedOnly(!showPinnedOnly)}
        />
      </div>
      <div className="border-top p-3">
        <div className="p-3 tinted-blue-background d-flex justify-content-between align-items-center">
          <p className="mb-0 xs-regular">{translate(language, "PAGES.COHORT.NOTES.WARNING_MESSAGE")}</p>
        </div>
        <div id="note-display" className="bg-light mt-2 p-3 d-flex flex-column mb-2">
          <div className="scrollable" ref={scrollContainerRef} style={{ paddingBottom: "70px" }}>
            {filteredNotes.map((note, index) => (
              <div
                key={index}
                className={`px-2 d-flex ${
                  note.author === email ? "flex-column align-items-end" : "flex-column align-items-start"
                }`}
              >
                <div className="d-flex align-items-center">
                  {note.author === email ? (
                    <Dropdown className="me-2">
                      <Dropdown.Toggle as="div" variant="secondary" className="clickable">
                        <ThreeDots
                          classes=""
                          height="0.3em"
                          color="rgba(var(--color-black-rgba), 0.2)"
                          orientation="vertical"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="s-regular">
                        <Dropdown.Item onClick={() => setEditingNote(note)}>
                          {translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_EDIT")}
                          <i className="dst-icon-edit-05 mx-2" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDeleteNote(note.id)}>
                          {translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_DELETE")}
                          <i className="dst-icon-trash-01 mx-2" />
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handlePinNote(note.id)}>
                          {note.is_pinned
                            ? translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_UNPIN")
                            : translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_PIN")}
                          {note.is_pinned ? (
                            <i className="dst-icon-bookmark-check mx-2" />
                          ) : (
                            <i className="dst-icon-bookmark mx-2" />
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                  <div
                    className={`w-fit ${
                      note.author === email ? "blue-background white-font" : "gray-background"
                    } p-2 rounded ${note.is_pinned ? "note-pinned" : ""}`}
                  >
                    <p className="my-2">{note.value}</p>
                  </div>
                  {note.author !== email ? (
                    <Dropdown className="ms-2">
                      <Dropdown.Toggle as="div" variant="secondary" className="clickable">
                        <ThreeDots
                          classes=""
                          height="0.3em"
                          color="rgba(var(--color-black-rgba), 0.2)"
                          orientation="vertical"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="s-regular">
                        <Dropdown.Item onClick={() => handlePinNote(note.id)}>
                          {note.is_pinned
                            ? translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_UNPIN")
                            : translate(language, "PAGES.COHORT.NOTES.MENU.BUTTON_PIN")}
                          {note.is_pinned ? (
                            <i className="dst-icon-bookmark-check mx-2" />
                          ) : (
                            <i className="dst-icon-bookmark mx-2" />
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </div>
                <p className="xs-bold">
                  {translate(language, "PAGES.COHORT.NOTES.SENT_BY")} {note.author}{" "}
                  {translate(language, "PAGES.COHORT.NOTES.ON_DATE")} {formatDateHumanly(language, note.dt_created)}
                </p>
              </div>
            ))}
          </div>
          <Form onSubmit={handleSubmit} className="d-flex mt-3 mt-auto">
            <Form.Control
              type="text"
              placeholder={translate(language, "PAGES.COHORT.NOTES.INPUT_PLACEHOLDER")}
              name="notesInput"
              ref={inputRef}
              className="me-2"
              defaultValue={editingNote?.value ?? ""}
            />
            <DstButton
              type="submit"
              variant="primary"
              disabled={isAdding}
              loading={isAdding}
              value={
                editingNote
                  ? translate(language, "PAGES.COHORT.NOTES.BUTTONS.UPDATE")
                  : translate(language, "PAGES.COHORT.NOTES.BUTTONS.SUBMIT")
              }
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NoteComponent;
