import { getMeilisearchResults } from "@meilisearch/autocomplete-client";
import { SearchClient } from "@meilisearch/autocomplete-client/dist/types/types/SearchClient";

import { MultiSelectLiveDropdownPropsSelectItems, categoryAvailable } from "./MultiSelectLiveDropdown.d";

export const categoryProperty = {
  users: ["first_name", "last_name"],
  cohorts: ["name"],
};

const _displayItem = (category: string, item: any, html: any) => {
  switch (category) {
    case "users":
      return html`<div class="s-regular"><b>${item.id}</b> ${item.first_name} ${item.last_name}</div>`;
    case "cohorts":
      return html`<div class="s-regular"><b>${item.id}</b> ${item.name}</div>`;
    default:
      return html`<div class="s-regular"><b>${item.id}</b></div>`;
  }
};

export const getMeilisearchSources = (
  searchClient: SearchClient,
  category: categoryAvailable | Array<categoryAvailable>,
  query: string,
  onSelect: Function,
  selectedItems?: Array<MultiSelectLiveDropdownPropsSelectItems>,
  filterCategory?: categoryAvailable | null
) =>
  [category]
    .flat()
    .filter((category) => !filterCategory || category === filterCategory)
    .map((category: categoryAvailable) => ({
      sourceId: category,
      getItems() {
        return getMeilisearchResults({
          searchClient,
          queries: [
            {
              indexName: category,
              query,
              params: {
                hitsPerPage: 10000,
              },
            },
          ],
          transformResponse: (response) =>
            response.hits.map((hits) =>
              hits.filter(
                (hit: any) =>
                  !selectedItems
                    ?.filter((item) => item.category === category)
                    ?.map((item) => item.value.id)
                    ?.includes(hit.id)
              )
            ),
        });
      },
      templates: {
        header({ html }: any) {
          return html`
            <span class="aa-SourceHeaderTitle"
              >${category.slice(0, 1).toUpperCase()}${category.slice(1).toLowerCase()}</span
            >
            <span class="aa-SourceHeaderLine"></span>
          `;
        },
        item({ item, html }: any) {
          return _displayItem(category, item, html);
        },
      },
      onSelect({ item, event }: { item: any; event: Event }) {
        event.preventDefault();
        onSelect(item, category);
      },
    }));
