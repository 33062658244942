export const getStatus = (status: string): string => {
  switch (status?.toLowerCase()) {
    case "in progress":
    case "correction pending":
    case "being corrected":
      return "status-orange";
    case "corrected":
    case "success":
      return "status-green";
    case "repass":
    case "failed":
      return "status-red";
    case "created":
      return "status-blue";
    default:
      return "";
  }
};
