import React from "react";

import Popup from "../../../components/Popup/Popup";
import translate from "../../../services/Translate";

export const routePopup = (
  language: string,
  showPopups: { [key: string]: number | string } | null,
  closeRoutePopup: Function,
  permissions: { [route: string]: any }
) => (
  <Popup
    show={!!showPopups && !!showPopups?.route}
    onClose={() => closeRoutePopup()}
    modalTitle={String(showPopups?.route) ?? translate(language, "PAGES.PERMISSIONS.POPUP.ROUTE.NO_TITLE")}
  >
    {showPopups?.route &&
      permissions[showPopups.route]?.map((routeMethod: any, index: number) => (
        <React.Fragment>
          {index > 0 && <hr />}
          <div key={`route-method-${index}`}>
            <p>
              <strong>{routeMethod.method}</strong>
            </p>
            <p>
              {routeMethod?.documentation?.description
                ? routeMethod.documentation.description
                : translate(language, "PAGES.PERMISSIONS.POPUP.ROUTE.NO_DESCRIPTION")}
            </p>
            <p>
              <strong>{translate(language, "PAGES.PERMISSIONS.POPUP.ROUTE.TAGS_LABEL")}</strong>{" "}
              {routeMethod?.documentation?.tags
                ? routeMethod.documentation.tags.join(", ")
                : translate(language, "PAGES.PERMISSIONS.POPUP.ROUTE.NO_TAG")}
            </p>
          </div>
        </React.Fragment>
      ))}
  </Popup>
);

export const rolePopup = (
  language: string,
  showPopups: { [key: string]: number | string } | null,
  closeRolePopup: Function,
  role: any
) => (
  <Popup
    show={!!showPopups && !!showPopups?.role && showPopups?.role === String(role.id)}
    onClose={() => closeRolePopup()}
    modalTitle={`${translate(language, "PAGES.PERMISSIONS.POPUP.ROLE.ROLE_LABEL")} ${role.name}`}
  >
    <div>
      <p>
        <strong>{translate(language, "PAGES.PERMISSIONS.POPUP.ROLE.DESCRIPTION_LABEL")}</strong> {role.description}
      </p>
      <p>
        <strong>{translate(language, "PAGES.PERMISSIONS.POPUP.ROLE.ROUTES_LABEL")}</strong>
      </p>
      <ul>
        {Object.entries(role.routes).map(([route, methods]) => (
          <li key={route}>
            <strong>{route}</strong>: {(methods as string[]).join(", ")}
          </li>
        ))}
      </ul>
    </div>
  </Popup>
);
