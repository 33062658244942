import ApiService from "../../services/ApiService";

export const loginAs = (user_id: string) => () => {
  ApiService.get(`auth/login-as/${user_id}`)
    .then((response) => window.open(response.data.url, "_blank"))
    .catch((error) => console.error("loginAs", error));
};

export const logout = (user_id: string) => () => {
  ApiService.post(`admin/auth/logout-user?user_id=${user_id}`, {})
    .then((_) => alert("User has been logged out."))
    .catch((error) => console.error("logout", error));
};

export const displayDate = (value: string) => {
  try {
    return new Date(value).toISOString().slice(0, 16);
  } catch (_) {
    return String(value);
  }
};

export const fetchEditionElements = async (setCohorts: Function, setFlags: Function, showLoaded: Function) => {
  try {
    await Promise.all([ApiService.get("cohorts"), ApiService.get("flags")]).then((responses) => {
      const cohorts = responses[0].data.cohorts;
      const flags = responses[1].data.flags;
      setCohorts(cohorts);
      setFlags(flags);
      showLoaded();
    });
  } catch (err) {
    console.error("An error occurred for fetching all flags", err);
  }
};

export const updateUser = async (
  userData: any,
  editedUserData: any,
  setEditionLoading: Function,
  refreshUserBlock: Function,
  author_id?: number | null
) => {
  let editions: any = {};
  setEditionLoading(true);

  Object.keys(userData).map((key: string) => {
    if (JSON.stringify(userData[key]) !== JSON.stringify(editedUserData[key])) {
      switch (key) {
        case "user_cohorts":
          editions.cohorts = {
            cohort_ids: [...editedUserData[key]].map((element) => element.id),
          };
          break;
        case "user_flags":
          editions.flags = {
            flag_ids: [...editedUserData[key]].map((element) => element.id),
            author_id,
          };
          break;
        default:
          editions.user = {
            ...editions.user,
            [key]: ["dt_start", "dt_end"].includes(key) ? ~~(editedUserData[key] / 1000) : editedUserData[key],
          };
          break;
      }
      return true;
    }
    return false;
  });

  Promise.all(
    Object.keys(editions).map((key_to_edit: string) => {
      switch (key_to_edit) {
        case "user":
          return ApiService.patch(`users/${userData.id}`, editions.user);
        case "cohorts":
          return ApiService.put(`users/${userData.id}/cohorts`, editions.cohorts);
        case "flags":
          return ApiService.put(`users/${userData.id}/flags`, editions.flags);
        default:
          break;
      }
      return true;
    })
  )
    .then(
      (_) => {
        refreshUserBlock();
      },
      (err) => {
        alert("An error occurred on editing user, more details in console. Contact Developer Team");
        console.error(err);
      }
    )
    .finally(() => {
      setEditionLoading(false);
    });
};
