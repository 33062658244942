import React from "react";

const Search = ({
  color = "var(--color-light-silver)",
  classes = "me-2",
  orientation = "right",
}: {
  color?: string;
  classes?: string;
  orientation?: string;
}) => {
  const transformValue = orientation === "left" ? "scale(-1, 1)" : "";
  return (
    <svg
      className={classes}
      height="1em"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: transformValue }}
    >
      <path
        d="M5.71899 10C7.92813 10 9.71899 8.20914 9.71899 6C9.71899 3.79086 7.92813 2 5.71899 2C3.50986 2 1.71899 3.79086 1.71899 6C1.71899 8.20914 3.50986 10 5.71899 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7191 11.0001L8.56909 8.8501"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Search;
