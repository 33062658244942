import { Permission } from "./Permissions";

const _mockFunction = () => {
  throw new Error("Function not implemented.");
};

class Session {
  theme?: string | null;
  language = "en";
  logged?: boolean;
  username?: string | null;
  first_name?: string;
  last_name?: string;
  email?: string;
  image_url?: string | null;
  role?: string | null;
  permissions?: Permission[];
  loaded = false;
  user_id?: number | null;
  handleLogin: Function;
  handleLogout: Function;
  switchLanguage: Function;
  fetchUserData: Function;

  constructor(
    args: Session = {
      language: "en",
      handleLogin: _mockFunction,
      handleLogout: _mockFunction,
      switchLanguage: _mockFunction,
      fetchUserData: _mockFunction,
      loaded: false,
    }
  ) {
    const {
      theme,
      language,
      logged,
      username,
      image_url,
      first_name,
      last_name,
      email,
      loaded,
      user_id,
      role,
      permissions,
    } = args;
    this.theme = theme;
    this.language = language || "en";
    this.logged = logged;
    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.loaded = loaded;
    this.image_url = image_url;
    this.role = role;
    this.permissions = permissions;
    this.user_id = user_id;
    this.handleLogin = args.handleLogin || _mockFunction;
    this.handleLogout = args.handleLogout || _mockFunction;
    this.switchLanguage = args.switchLanguage || _mockFunction;
    this.fetchUserData = args.fetchUserData || _mockFunction;
  }
}

export default Session;
