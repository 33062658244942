export enum ExamStatus {
  BEING_CORRECTED = "being_corrected",
  CREATED = "created",
  CORRECTION_PENDING = "correction_pending",
  FAILED = "failed",
  IN_PROGRESS = "in_progress",
  PRE_CORRECTED = "pre_corrected",
  PRE_CORRECTION_IN_PROGRESS = "pre_correction_in_progress",
  REPASS = "repass",
  SUCCESS = "success",
}
