import { CohortIntensity } from "../../models/CohortIntensity";
import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const fetchCohortsLeaders = (language: string, setCohortsLeaders: Function, callback?: Function) => {
  ApiService.get("cohorts/leaders?order_by=full_name")
    .then((resp) => {
      setCohortsLeaders(resp.data.cohort_leaders);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORTS.ERRORS.LOADING_LEADERS"));
    });
};

export const fetchCohortsManagers = (language: string, setCohortsManagers: Function, callback?: Function) => {
  ApiService.get("cohorts/managers?order_by=full_name")
    .then((resp) => {
      setCohortsManagers(resp.data.cohort_managers);
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORTS.ERRORS.LOADING_MANAGERS"));
    });
};

export const getIntensityColor = (intensity: CohortIntensity) => {
  switch (intensity) {
    case CohortIntensity.continuous:
      return { bg: "warning", text: "dark" };
    case CohortIntensity.bootcamp:
      return { bg: "primary" };
    case CohortIntensity.tracks:
      return { bg: "danger" };
    default:
      return { bg: "secondary" };
  }
};

export const generateRemoteUrl = (
  searchQuery: string,
  selectedCohortLeaderFilter: any[],
  selectedProgramManagerFilter: any[],
  selectedIntensityFilter: any[],
  detailedSearch: boolean
) => {
  const filters = [];

  if (searchQuery) {
    filters.push(`search=${searchQuery.toLowerCase()}`);
  }
  if (selectedCohortLeaderFilter.length > 0) {
    filters.push(`leaders=${selectedCohortLeaderFilter.join(",")}`);
  }
  if (selectedProgramManagerFilter.length > 0) {
    filters.push(`managers=${selectedProgramManagerFilter.join(",")}`);
  }
  if (selectedIntensityFilter.length > 0) {
    filters.push(`intensity=${selectedIntensityFilter.join(",")}`);
  }
  filters.push("fetch_users_count=true");

  if (detailedSearch === true) {
    filters.push("detailed_search=true");
  }
  return `cohorts${filters.length > 0 ? `?${filters.join("&")}` : ""}`;
};
