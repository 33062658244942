import { BaseItem } from "@algolia/autocomplete-shared/dist/esm/core/AutocompleteApi";
import { getMeilisearchResults } from "@meilisearch/autocomplete-client";
import { SearchClient } from "algoliasearch";

export const saveResultInHistory = (itemUrl: string, item: BaseItem) => {
  const indexName = `${itemUrl.split("/")[1]}s`;
  let history = JSON.parse(localStorage.getItem("nexus-recent-search") || "{}");

  if (history[indexName]?.map((historyItem: BaseItem) => historyItem.id).includes(item.id)) {
    history[indexName] = history[indexName].filter((historyItem: BaseItem) => historyItem.id !== item.id);
  }
  history[indexName] = [item, ...(history[indexName] || [])].slice(0, 5);
  localStorage.setItem("nexus-recent-search", JSON.stringify(history));
};

const _source_template_header = (indexName: string, suggestion = false) => ({
  header({ html }: any) {
    return html`
      <span class="aa-SourceHeaderTitle dst-header-search-source-header">
        ${suggestion ? html`<i class="dst-icon-clock-rewind"></i> Searched ` : ""}${indexName
          .slice(0, 1)
          .toUpperCase()}${indexName.slice(1).toLowerCase()}
      </span>
      <span class="aa-SourceHeaderLine"></span>
    `;
  },
});

const _source_template = (searchClient: SearchClient, query: string, indexName: string, suggestion: boolean) => ({
  sourceId: indexName,
  getItems() {
    if (suggestion) {
      return JSON.parse(localStorage.getItem("nexus-recent-search") || "{}")?.[indexName] || [];
    }
    return getMeilisearchResults({
      searchClient,
      queries: [
        {
          indexName: indexName,
          query,
          params: {
            hitsPerPage: 10000,
          },
        },
      ],
    });
  },
  getItemUrl({ item }: any) {
    return `/user/${item.id}`;
  },
  templates: {
    ..._source_template_header(indexName, suggestion),
    item({ item, components, html }: any) {
      return html`<a
        href="/user/${item.id}"
        class="aa-ItemLink"
        onClick="${() => saveResultInHistory(`/user/${item.id}`, item)}"
      >
        <div class="xs-regular">
          <span class="badge bg-primary me-2">${item.id}</span>
          <span class="pe-1 d-inline-block">
            ${components.Highlight({
              hit: item,
              attribute: "first_name",
              tagName: "b",
            })}
          </span>
          <span class="d-inline-block">
            ${components.Highlight({
              hit: item,
              attribute: "last_name",
              tagName: "b",
            })}
          </span>
        </div>
      </a>`;
    },
  },
});

export const getMeilisearchSources = (searchClient: any, query: string, suggestion = false) => [
  { ..._source_template(searchClient, query, "users", suggestion) },
  {
    ..._source_template(searchClient, query, "cohorts", suggestion),
    getItemUrl({ item }: any) {
      return `/cohort/${item.id}`;
    },
    templates: {
      ..._source_template_header("cohorts", suggestion),
      item({ item, components, html }: any) {
        return html`<a
          href="/cohort/${item.id}"
          class="aa-ItemLink"
          onClick="${() => saveResultInHistory(`/cohort/${item.id}`, item)}"
        >
          <div class="xs-regular">
            <span class="badge bg-primary me-2">${item.id}</span>
            <span class="pe-1 d-inline-block">
              ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "b",
              })}
            </span>
          </div>
        </a>`;
      },
    },
  },
  {
    ..._source_template(searchClient, query, "modules", suggestion),
    getItemUrl({ item }: any) {
      return `/module/${item.id}`;
    },
    templates: {
      ..._source_template_header("modules", suggestion),
      item({ item, components, html }: any) {
        return html`<a
          href="/module/${item.id}"
          class="aa-ItemLink"
          onClick="${() => saveResultInHistory(`/module/${item.id}`, item)}"
        >
          <div class="xs-regular">
            <span class="badge bg-primary me-2">${item.id}</span>
            <span class="pe-1 d-inline-block">
              ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "b",
              })}
            </span>
          </div>
        </a>`;
      },
    },
  },
  {
    ..._source_template(searchClient, query, "exercises", suggestion),
    getItemUrl({ item }: any) {
      return `/exercise/${item.id}`;
    },
    templates: {
      ..._source_template_header("exercises", suggestion),
      item({ item, components, html }: any) {
        return html`<a
          href="/exercise/${item.id}"
          class="aa-ItemLink"
          onClick="${() => saveResultInHistory(`/exercise/${item.id}`, item)}"
        >
          <div class="xs-regular">
            <span class="badge bg-primary me-2">${item.id}</span>
            <span class="pe-1 d-inline-block">
              ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "b",
              })}
            </span>
          </div>
        </a>`;
      },
    },
  },
  {
    ..._source_template(searchClient, query, "evaluations", suggestion),
    getItemUrl({ item }: any) {
      return `/evaluation/${item.id}`;
    },
    templates: {
      ..._source_template_header("evaluations", suggestion),
      item({ item, components, html }: any) {
        return html`<a
          href="/evaluation/${item.id}"
          class="aa-ItemLink"
          onClick="${() => saveResultInHistory(`/evaluation/${item.id}`, item)}"
        >
          <div class="xs-regular">
            <span class="badge bg-primary me-2">${item.id}</span>
            <span class="pe-1 d-inline-block">
              ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "b",
              })}
            </span>
          </div>
        </a>`;
      },
    },
  },
];
