import React, { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import Search from "../../../../components/DstIcons/Search";
import Lister from "../../../../components/Lister/Lister";
import ListerSearch from "../../../../components/Lister/ListerSearch/ListerSearch";
import Popup from "../../../../components/Popup/Popup";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";

const UserMessages = ({
  user_data,
  loaded,
  language,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const openMessagePopup = (messageId: string) => {
    const message = user_data.user_messages?.find((m: any) => m.id === messageId);
    if (message) {
      setSelectedMessage(message);
      setShowPopup(true);
    } else {
      console.error("Message not found for ID:", messageId);
    }
  };

  const filteredMessages =
    user_data.user_messages?.filter(
      (message: any) =>
        message.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.content.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const columns = [
    {
      key: "author",
      header: translate(language, "PAGES.USER.MESSAGE.AUTHOR"),
    },
    {
      key: "date",
      header: translate(language, "PAGES.USER.MESSAGE.SENDING_DATE"),
      render: (item: any) => (
        <div>
          <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
            <span>{formatDateHumanly(language, item["date"])}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      key: "status",
      header: translate(language, "PAGES.USER.MESSAGE.STATUS"),
      render: (item: any) => (
        <span className={`p-1 rounded ${!!item.dt_seen ? "status-green" : "status-red"}`}>
          {translate(language, `PAGES.USER.MESSAGE.${!item.dt_seen ? "NOT_" : ""}READ`)}
        </span>
      ),
    },
    {
      key: "content",
      header: translate(language, "PAGES.USER.MESSAGE.CONTENT"),
      render: (item: any) => <div dangerouslySetInnerHTML={{ __html: item.content }} />,
    },
  ];

  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <div className="w-fit">
            <ListerSearch setSearchQuery={setSearchQuery} />
          </div>
          <Lister
            items={filteredMessages.map((message: any) => ({
              ...message,
            }))}
            columns={columns}
            additionalButton={(item: any) => (
              <span className="clickable" onClick={() => openMessagePopup(item.id)}>
                <Search />
              </span>
            )}
            pagination={true}
            mock={!loaded}
          />
          {showPopup && (
            <Popup
              show={showPopup}
              onClose={() => setShowPopup(false)}
              modalTitle={translate(language, "PAGES.USER.MESSAGE.POPUP_TITLE")}
              size="xl"
              fullscreen="lg-down"
              scrollable={true}
            >
              <div className="m-regular">
                <Row className="mb-2">
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.AUTHOR")}</h3>
                    <DstButton
                      variant="link"
                      btnClass="m-bold p-0"
                      clickFunction={() => window.open(`/user/${selectedMessage.author_id}`, "_blank")}
                      value={selectedMessage.author}
                    />
                  </Col>
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.EMAIL")}</h3>
                    <p>{selectedMessage.email}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.SENDING_DATE")}</h3>
                    <p>{formatDateHumanly(language, new Date(selectedMessage.date).toISOString())}</p>
                  </Col>
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.STATUS")}</h3>
                    <p>
                      {selectedMessage.dt_seen ? (
                        <span className="badge rounded-pill text-bg-success m-regular">
                          {translate(language, "PAGES.USER.MESSAGE.READ")} -{" "}
                          {new Date(selectedMessage.dt_seen).toLocaleString()}
                        </span>
                      ) : (
                        <span className="badge rounded-pill text-bg-danger m-regular">
                          {translate(language, "PAGES.USER.MESSAGE.NOT_READ")}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <h3>{translate(language, "PAGES.USER.MESSAGE.CONTENT")}</h3>
                <div className="p-3 lightgray-background border-rounded">
                  <div dangerouslySetInnerHTML={{ __html: selectedMessage.fullContent }} />
                </div>
              </div>
            </Popup>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListerSearch mock setSearchQuery={setSearchQuery} />
          <Lister
            columns={[
              { header: translate(language, "PAGES.USER.MESSAGE.AUTHOR"), key: "author" },
              { header: translate(language, "PAGES.USER.MESSAGE.SENDING_DATE"), key: "date" },
              { header: translate(language, "PAGES.USER.MESSAGE.STATUS"), key: "status" },
              { header: translate(language, "PAGES.USER.MESSAGE.CONTENT"), key: "content" },
            ]}
            pagination
            mock={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UserMessages;
