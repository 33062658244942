import React, { useEffect, useState } from "react";

import { ItemData } from "../../models/Selector";
import translate from "../../services/Translate";
import DstButton from "../DstButton/DstButton";
import Chevron from "../DstIcons/Chevron";
import "./Selector.scss";

const Selector = ({
  items,
  onItemSelected,
  enableDropDown = true,
  language,
}: {
  items: ItemData[];
  onItemSelected?: (item: ItemData) => void;
  enableDropDown?: boolean;
  language: string;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (items.length > 0 && selectedIndex !== null) {
      onItemSelected?.(items[selectedIndex]);
    }
    // eslint-disable-next-line
  }, [items]);

  const updateIndex = (newIndex: number) => {
    setSelectedIndex(newIndex);
    onItemSelected?.(items[newIndex]);
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      updateIndex(selectedIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedIndex < items.length - 1) {
      updateIndex(selectedIndex + 1);
    }
  };

  const selectedItem = items[selectedIndex];

  return (
    <div className="w-fit">
      <DstButton
        value=""
        aria-label="Previous Cohort"
        btnImageAfter={<Chevron orientation="left" color="var(--color-white)" classes="" height="21px" />}
        btnSize="sm"
        clickFunction={handlePrevious}
        disabled={selectedIndex <= 0}
        btnClass="btn-selector-left"
      />

      {enableDropDown ? (
        <select
          className="p-1 select-selector text-center xs-regular"
          value={selectedItem ? selectedIndex : ""}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            updateIndex(parseInt(event.target.value, 10));
          }}
        >
          {items.map((item, index) => (
            <option key={index} value={index}>
              {item.name}
            </option>
          ))}
        </select>
      ) : (
        <span className="p-2 select-selector text-center xs-regular">
          {selectedItem ? selectedItem.name : translate(language, "COMPONENTS.USER_ACTIVITY.SELECTOR.NO_SELECTION")}
        </span>
      )}
      <DstButton
        value=""
        aria-label="Next Cohort"
        btnImageAfter={<Chevron orientation="right" color="var(--color-white)" classes="" height="21px" />}
        btnSize="sm"
        clickFunction={handleNext}
        disabled={selectedIndex >= items.length - 1}
        btnClass="btn-selector-right"
      />

      {selectedItem && selectedItem.content}
    </div>
  );
};

export default Selector;
