import { Card, Placeholder } from "react-bootstrap";

import { DstStatGraphProps, GraphProps } from "./DstStatGraph.d";
import { prepareChartData } from "./DstStatGraph.function";
import "./DstStatGraph.scss";

/**
 * `DstStatGraph` displays a responsive bar chart with configurable data keys and colors.
 *
 * @component
 * @property {number} barHeight - The height of the bars in pixels.
 * @property {number} barHeightMarginDiff - The margin difference between the bar and the label.
 * @property {Object[]} data - Array of objects representing the data points for the chart.
 * @property {number | string} graphWidth - The width of the graph element.
 * @property {boolean} mock - If true, the component will render a placeholder.
 *
 * @example
 * const data = [
 *  { label: '100- First steps', value: 25 },
 *  { label: 'DA101- Python for Data Analysis', value: 25 },
 *  { label: '105- Data Quality', value: 25 },
 *  { label: '104- Exploratory Statistics', value: 25 },
 *  { label: '111- DataViz with Matplotlib', value: 25 },
 * ];
 *
 * <DstStatGraph
 *  data={data}
 * />
 */

const DstStatGraph = ({
  barHeight = 40,
  barHeightMarginDiff = 0,
  data,
  graphWidth = "100%",
  mock,
}: DstStatGraphProps) => {
  const chartData = prepareChartData(data);

  if (mock) {
    return (
      <div>
        <Placeholder as={Card} animation="glow" style={{ width: graphWidth, heigth: barHeight }}>
          <Placeholder xs={12} class="overflow-visible" />
        </Placeholder>
      </div>
    );
  } else {
    return (
      <div
        className="dst-stat-graph d-flex justify-content-center align-items-center text-center mw-100"
        style={{ width: graphWidth }}
      >
        {chartData &&
          chartData.map(({ key, fill, label, percentage }: GraphProps, _index: number) => (
            <div
              key={key}
              className="bar-stat-graph d-flex justify-content-center align-items-center"
              style={{
                width: `${percentage}%`,
                height: barHeight,
                backgroundColor: fill,
                position: "relative",
              }}
            >
              <span className="hover-label">{label}</span>
              <div className="hover-label-percentage">{percentage.toFixed()}%</div>
              {percentage > 5 && <div className="percentage-text white-font">{percentage.toFixed()}%</div>}
            </div>
          ))}
      </div>
    );
  }
};

export default DstStatGraph;
