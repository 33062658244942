import React, { useMemo, useState } from "react";
import { Placeholder } from "react-bootstrap";

import DstButton from "../DstButton/DstButton";
import ChevronsUpDown from "../DstIcons/ChevronsUpDown";
import { ListItem, ListerProps } from "./Lister.d";
import { sortDate, sortNumber, sortString } from "./Lister.function";
import "./Lister.scss";

const _requestSort = (
  key: keyof ListItem,
  setSortConfig: React.Dispatch<
    React.SetStateAction<{
      key: keyof ListItem;
      sortType: string | undefined;
      direction: "ascending" | "descending";
    }>
  >,
  sortType: string | undefined
) => {
  setSortConfig((sortConfig) => {
    const direction = sortConfig.key === key && sortConfig.direction === "ascending" ? "descending" : "ascending";
    return { key, sortType, direction };
  });
};

const Lister = ({
  additionalButton,
  buttonClass,
  buttonElementColor,
  columns,
  handleRowClick,
  items = [],
  itemsPerPage = 10,
  mock = false,
  pagination = false,
  paginationAlwaysShowFooter = false,
  showHeader = true,
}: ListerProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ListItem;
    sortType: string | undefined;
    direction: "ascending" | "descending";
  }>({
    key: "",
    sortType: undefined,
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      switch (sortConfig.sortType) {
        case "date":
          return sortDate(a[sortConfig.key], b[sortConfig.key], sortConfig.direction);
        case "number":
          return sortNumber(a[sortConfig.key], b[sortConfig.key], sortConfig.direction);
        case "string":
          return sortString(a[sortConfig.key] ?? "", b[sortConfig.key] ?? "", sortConfig.direction);
        default:
          if (sortConfig.key === "date") {
            return sortDate(a[sortConfig.key], b[sortConfig.key], sortConfig.direction);
          } else if (!isNaN(Number(a[sortConfig.key] ?? undefined)) && !isNaN(Number(b[sortConfig.key] ?? undefined))) {
            return sortNumber(a[sortConfig.key], b[sortConfig.key], sortConfig.direction);
          } else {
            return sortString(a[sortConfig.key] ?? "", b[sortConfig.key] ?? "", sortConfig.direction);
          }
      }
    });
  }, [items, sortConfig]);

  const displayedItems = useMemo(() => {
    if (!pagination) {
      return sortedItems;
    }
    const start = (currentPage - 1) * itemsPerPage;
    return sortedItems.slice(start, start + itemsPerPage);
  }, [sortedItems, currentPage, itemsPerPage, pagination]);

  const itemsTotalLength = useMemo(() => items.length, [items]);
  const itemsActualPageLength = displayedItems.length + (currentPage - 1) * itemsPerPage;

  const totalPages = useMemo(() => Math.ceil(items.length / itemsPerPage), [items, itemsPerPage]);

  const pageButtons = useMemo(() => {
    const maxButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, totalPages);

    if (endPage === totalPages) {
      startPage = Math.max(totalPages - maxButtons + 1, 1);
    }

    return Array.from({ length: Math.min(maxButtons, totalPages) }, (_, i) => startPage + i);
  }, [currentPage, totalPages]);

  if (mock === true) {
    return (
      <React.Fragment>
        <table className="list-container table hover responsive lister-table">
          {showHeader && (
            <thead>
              <tr className="m-bold">
                {columns.map((column) => (
                  <th key={column.key} className="header-item">
                    {column.header} <ChevronsUpDown />
                  </th>
                ))}
                {additionalButton && <th></th>}
              </tr>
            </thead>
          )}
          <tbody>
            {[...Array(5)].map((_elem, index) => (
              <Placeholder key={`mock-placeholder-${index}`} animation="glow" as="tr" className="list-row">
                {columns.map((column) => (
                  <td key={`mock-cell-${index}-${column.key}`} className={`row-item ${column.size || "s-regular"}`}>
                    <Placeholder as="span" xs={12} />
                  </td>
                ))}
              </Placeholder>
            ))}
          </tbody>
        </table>
        <div>
          {(totalPages > 1 || paginationAlwaysShowFooter) && pagination && (
            <div className="d-flex mw-100 justify-content-center align-items-center m-3">
              <DstButton btnClass={`${buttonClass} me-1`} btnWidth="30px" value="" mock />
              <DstButton btnClass={`${buttonClass} me-1`} btnWidth="30px" value="" mock />
              {pageButtons.map((page) => (
                <DstButton btnClass={`${buttonClass} ms-1`} btnWidth="30px" key={page} value="" mock />
              ))}
              <DstButton btnClass={`${buttonClass} ms-2`} btnWidth="30px" value="" mock />
              <DstButton btnClass={`${buttonClass} ms-1`} btnWidth="30px" value="" mock />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <table className="list-container table hover responsive lister-table">
          {showHeader && (
            <thead>
              <tr className="m-bold">
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="header-item clickable"
                    onClick={() => _requestSort(column.key, setSortConfig, column?.sortType)}
                  >
                    {column.header} <ChevronsUpDown />
                  </th>
                ))}
                {additionalButton && <th></th>}
              </tr>
            </thead>
          )}
          <tbody>
            {displayedItems.map((item, index) => (
              <tr
                key={index}
                className={`list-row ${handleRowClick ? "clickable" : ""}`}
                onClick={() => handleRowClick && handleRowClick(item)}
              >
                {columns.map((column) => (
                  <td key={column.key} className={`row-item ${column.size || "s-regular"} ${column.color || ""}`}>
                    {column.render ? column.render(item) : item[column.key]}
                  </td>
                ))}
                {additionalButton && <td>{additionalButton(item)}</td>}
              </tr>
            ))}
          </tbody>
        </table>
        {(totalPages > 1 || paginationAlwaysShowFooter) && pagination && (
          <div className="pagination-controls d-flex justify-content-center align-items-center m-3">
            <React.Fragment>
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-left-double me-1`}
                color={buttonElementColor}
                clickFunction={() => setCurrentPage(1)}
                value=""
                disabled={currentPage === 1}
              />
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-left me-1`}
                color={buttonElementColor}
                value=""
                disabled={currentPage < 4}
                clickFunction={() => setCurrentPage(Math.max(currentPage - 5, 1))}
              />
              {pageButtons.map((page) => (
                <DstButton
                  btnClass={`${buttonClass} button-lister ms-1`}
                  color={buttonElementColor}
                  key={page}
                  value={page.toString()}
                  variant={currentPage === page ? "primary" : "secondary"}
                  clickFunction={() => setCurrentPage(page)}
                />
              ))}
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-right ms-2`}
                value=""
                color={buttonElementColor}
                disabled={currentPage > totalPages - 3}
                clickFunction={() => setCurrentPage(Math.min(currentPage + 5, totalPages))}
              />
              <DstButton
                btnClass={`${buttonClass} button-lister dst-icon-chevron-right-double ms-1`}
                clickFunction={() => setCurrentPage(totalPages)}
                value=""
                color={buttonElementColor}
                disabled={currentPage === totalPages}
              />
              <span className="ms-3">
                {itemsActualPageLength} / {itemsTotalLength}
              </span>
            </React.Fragment>
          </div>
        )}
      </React.Fragment>
    );
  }
};

export default Lister;
